import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { MediumModal, ModalHeader } from '../Modals';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { Input, MenuItem, Select, TextField } from '@material-ui/core';
import { InputAdornment } from '@mui/material';
import { verifyFields } from '../../util/functions';
import { addPayment } from '../../api/invoices';
import { ModalBody } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: "90%",
    },
    addIcon: {
        margin: "1%"
    },
    button: {
        margin: theme.spacing(2),
        minWidth: "25%",
        height: "100%"
      },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  }));

export const NewPaymentForm = (props) => {

    const classes = useStyles();

    const [errors, setErrors] = useState([]);
    const [invoiceId, setInvoiceId] = useState(-1);
    const [paymentType, setPaymentType] = useState(null);
    const [referenceNumber, setReferenceNumber] = useState(null);
    const [factoringPercent, setFactoringPercent] = useState(0.0);
    const [amount, setAmount] = useState(0.0);

    const submit = () => {
        let payload = {invoice_id: invoiceId, payment_type: paymentType, reference_number: referenceNumber, factoring_fee: factoringPercent, amount: amount}

        let fields = ["invoice_id", "payment_type", "reference_number", "amount"];

        if (paymentType == "factored") {
            fields.push("factoring_fee")
        }

        let errors = verifyFields(fields, payload)

        console.log(payload);

        if (errors.length == 0) {

            addPayment(payload, (result) => {
                console.log(result);
                props.onClose(result);
            });

        } else {
            console.log(errors)
            setErrors(errors);
        }
    }

    React.useEffect(() => {
        setInvoiceId(props.invoiceId);
        setPaymentType("");
        setReferenceNumber("");
        setFactoringPercent(0.0);
        setAmount(0.0);
    }, [props.visible, props.invocie])

    return (
        <MediumModal visible={props.visible} closeCallback={props.onClose} zIndex={"100"}>
            <ModalHeader>Add Payment</ModalHeader>
            <ModalBody>
                <FormControl className={classes.formControl}>
                    <InputLabel id="payment-select-label">Payment Type</InputLabel>
                    <Select
                    labelId='payment-select-label'
                    error={errors.includes("payment_type")}
                    id="payment-select"
                    value={paymentType ? paymentType : ''}
                    onChange={(e) => {setPaymentType(e.target.value)}}
                    >   
                        <MenuItem value={"customer_paid"}>Paid By Customer</MenuItem>
                        <MenuItem value={"factored"}>Factored</MenuItem>
                        <MenuItem value={"ach"}>ACH</MenuItem>
                        <MenuItem value={"check"}>Check</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="ref-select-label">Reference Number</InputLabel>
                    <Input
                    type="text"
                    labelId='ref-select-label'
                    error={errors.includes("reference_number")}
                    id="ref_num"
                    value={referenceNumber}
                    onChange={(e) => {setReferenceNumber(e.target.value)}}
                    variant="outlined"
                    />
                </FormControl>
                { paymentType == "factored" ? 
                <FormControl className={classes.formControl}>
                    <InputLabel id="fee-select-label">Factoring Fee (%)</InputLabel>
                        <Input
                        error={errors.includes("factoring_fee")}
                        disabled={paymentType != "factored"}
                        id="factoring_fee"
                        type="number"
                        value={factoringPercent * 100}
                        onChange={(e) => {setFactoringPercent(e.target.value / 100.0)}}
                        variant="filled"
                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                        />
                </FormControl> : <></>
                }
                <FormControl className={classes.formControl}>
                    <InputLabel id="amount-select-label">Amount</InputLabel>
                        <Input
                        error={errors.includes("amount")}
                        id="total"
                        type="currency"
                        value={amount}
                        onChange={(e) => {setAmount(e.target.value)}}
                        variant="filled"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                </FormControl>
                <Button className={classes.button} variant="contained" onClick={() => submit()}>Save</Button>
            </ModalBody>
        </MediumModal>
    );
}

export default NewPaymentForm;