import { createSlice } from '@reduxjs/toolkit'
// Slice
const slice = createSlice({
  name: 'busy',
  initialState: {
    waitingFor: [],
  },
  reducers: {
    addIdentifier: (state, action) => {
      console.log(action.payload);
      let list = [...state.waitingFor];
      list.push(action.payload.identifier);
      state.waitingFor = list;
    },
    removeIdentifier: (state, action) =>  {
      console.log(action.payload);
      let list = state.waitingFor.filter((a) => a.id != action.payload.id);
      console.log(list);
      state.waitingFor = list;
    },
  },
});
export default slice.reducer
// Actions
const { addIdentifier, removeIdentifier } = slice.actions

export const displayProgressOverlay = (identifier) => async dispatch => {
  try {
    setTimeout((identifier) => dismissProgressOverlay, 5000);
    dispatch(addIdentifier({identifier}));
  } catch (e) {
    return console.error(e.message);
  }
}
export const dismissProgressOverlay = (identifier) => async dispatch => {
  try {
    return dispatch(removeIdentifier(identifier))
  } catch (e) {
    return console.error(e.message);
  }
}