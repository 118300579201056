import { Button } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { getAllChassis } from '../api/chassis';
import { getAllDrivers } from '../api/drivers';
import { getAllTrucks } from '../api/trucks';
import { ComplianceRenewalForm } from '../components/forms/ComplianceForm';
import { getWarningColor } from '../components/forms/Options';
import { ComplianceBadge } from '../components/styled/Badges';
import { Table, TR, TH, TD } from '../components/styled/Tables';
import { dismissProgressOverlay, displayProgressOverlay } from '../store/busy';
import { titlize } from '../util/functions';
import SecuredView from './SecuredView';


class Compliance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            waitingFor: [],
            trucks: [],
            chassis: [],
            drivers: [],
            openItems: [],
            renewalObj: {},
            renewalFormOpen: false
        };

        this.onRenewalFormClose = this.onRenewalFormClose.bind(this);
    }

    showProgressOverlay(key) {
        this.props.displayProgressOverlay({id: key});
    }

    dismissProgressOverlay(key) {
        this.props.dismissProgressOverlay({id: key});
    }

    componentDidMount() {

        this.showProgressOverlay('trucks');
        this.showProgressOverlay('chassis');
        this.showProgressOverlay('drivers');
        getAllTrucks((results) => {
            this.setState({trucks: results});
            this.dismissProgressOverlay('trucks');
        }, null, ["compliance_items"]);

        getAllChassis((results) => {
            this.setState({chassis: results.filter((i) => i.is_active)});
            this.dismissProgressOverlay('chassis');
        }, null, ["compliance_items"]);

        getAllDrivers((results) => {
            this.setState({drivers: results});
            this.dismissProgressOverlay('drivers');
        }, null, ["compliance_items"]);
    }

    addToOpenItems(identifier) {
        let openItems = [...this.state.openItems];
        openItems.push(identifier);
        this.setState({openItems: openItems});
    }

    removeFromOpenItems(identifier) {
        let openItems = this.state.openItems.filter((id) => id != identifier);
        this.setState({openItems: openItems});
    }


    getTruckRows(items, type) {
        let rows = [];

        items.map((item, index) => {

            let identifier = `${type}-${item.id}`;
            let isOpen = this.state.openItems.includes(identifier)

            let upcomingRenewals = item.compliance_items.filter((c) => c.days_left <= 30 && c.days_left > 15);
            let criticalUpcomingRenewals = item.compliance_items.filter((c) => c.days_left <= 15);

            let label = "";

            switch(type) {
                case "trucks":
                    label = `Truck ${item.number} - ${item.plate}`;
                    break;
                case "chasses":
                    label = `Chassis ${item.number} - ${item.plate}`;
                    break;
                case "drivers":
                    label = `${item.first_name} - ${item.last_name}`;
                    break;
            }

            rows.push(
                <TR>
                    <TH>
                        <span style={{float: "left", margin: "0 5%"}}>{`${index + 1}. `}{label}</span>
                        {upcomingRenewals.length > 0 ? <ComplianceBadge style={{color: "black",backgroundColor: "yellow"}}>{upcomingRenewals.length}</ComplianceBadge> : <></>}
                        {criticalUpcomingRenewals.length > 0 ? <ComplianceBadge>{criticalUpcomingRenewals.length}</ComplianceBadge> : <></>}
                        <Button variant="outlined" onClick={() => isOpen ? this.removeFromOpenItems(identifier) : this.addToOpenItems(identifier)}>{isOpen ? "Hide" : "Show"}</Button>
                    </TH>
                    <TH>Type</TH>
                    <TH>Expires On</TH>
                    <TH>Renewed On</TH>
                    <TH>Time Left</TH>
                    <TH>Renew</TH>
                </TR>
            );

            item.compliance_items.sort((a,b) => a.days_left - b.days_left).map((c) => {
                console.log(c);
                rows.push(
                    <TR style={{backgroundColor: getWarningColor(c.days_left), display: isOpen ? "table-row" : "none"}}>
                        <TD></TD>
                        <TD>{titlize(c.item_type)}</TD>
                        <TD>{new Date(c.expires_on).toDateString()}</TD>
                        <TD>{c.renewed_on ? new Date(c.renewed_on).toDateString() : "Never"}</TD>
                        <TD>{c.days_left} Days Left</TD>
                        <TD><Button variant="outlined" onClick={() => this.openRenewalForm({id: c.id, name: label, type: titlize(c.item_type)})}>Renew</Button></TD>
                    </TR>
                );
            });
        });

        return rows;
    }

    openRenewalForm(obj) {
        this.setState({renewalObj: obj, renewalFormOpen: true});
    }

    onRenewalFormClose() {
        this.setState({renewalObj: {}, renewalFormOpen: false});
        this.componentDidMount();
    }

    render () {
        return <SecuredView accessLevels={["super_user", "hr_user"]}>
        <Table>
            <TR>
                <TH colSpan={6} style={{textAlign: "center", fontSize: "2rem", background: "black", color: "white"}}>
                    Trucks
                </TH>
            </TR>
            {this.getTruckRows(this.state.trucks, "trucks")}
            <TR>
                <TH colSpan={6} style={{textAlign: "center", fontSize: "2rem", background: "black", color: "white"}}>
                    Chassis
                </TH>
            </TR>
            {this.getTruckRows(this.state.chassis, "chasses")}
            <TR>
                <TH colSpan={6} style={{textAlign: "center", fontSize: "2rem", background: "black", color: "white"}}>
                    Drivers
                </TH>
            </TR>
            {this.getTruckRows(this.state.drivers, "drivers")}
        </Table>
        <ComplianceRenewalForm visible={this.state.renewalFormOpen} type={this.state.renewalObj.type} itemId={this.state.renewalObj.id} objName={this.state.renewalObj.name} onClose={this.onRenewalFormClose}/>
        </SecuredView>
    }
}
function mapStateToProps(state){
    return{
      // alerts: state.alerts.alerts,
      // currentUser: state.user.user,
      // waitingFor: state.busy.waitingFor
    }
  }
  
  function mapDispatchToProps(dispatch){
    return {
      // addAlert: alert => dispatch(addAlert(alert)),
      // removeAlert: alert => dispatch(removeAlert(alert)),
      // setUser: user => dispatch(setUser(user)),
      dismissProgressOverlay: identifier => dispatch(dismissProgressOverlay(identifier)),
      displayProgressOverlay: identifier => dispatch(displayProgressOverlay(identifier)),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Compliance);