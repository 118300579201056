const token = document.querySelector("[name=csrf-token]").content;

export var getAllChassis = (onSuccess, onError, include) => {
    let incString = "?include="
    if (include) {
      include.map((item) => {
        incString = incString.concat(`,${item}`);
      });
    }
    return fetch(`/api/v1/chassis${incString}`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token },
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var createChassis = (chassis, onSuccess, onError) => {
    return fetch(`/api/v1/chassis`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(chassis)
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var updateChassis = (chassis, onSuccess, onError) => {
    return fetch(`/api/v1/chassis`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      method: "PUT",
      body: JSON.stringify(chassis)
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var getYardLogs = (onSuccess, onError, include) => {
    let incString = "?include="
    if (include) {
      include.map((item) => {
        incString = incString.concat(`${item},`);
      });
    }
    return fetch(`/api/v1/yard-logs${incString}`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token },
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (decoded.status_code == 401) {
          window.location.href = decoded.redirect;d
          onSuccess([]);
        }
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var getChassisReport = (id, startDate, onSuccess, onError) => {
    return fetch(`/api/v1/chassis-report/${id}?start_date=${startDate}`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token },
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var getAllChassisReport = (start, end, onSuccess, onError) => {
    return fetch(`/api/v1/chassis-report?start=${start}&end=${end}`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token },
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };