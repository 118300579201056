import React, { useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  TrafficLayer,
  Circle,
  InfoBox,
} from "@react-google-maps/api";
import { checkForPairedObjects, clusterObjectLocations } from "../util/geo";
import {
  InfoBoxContainer,
  InfoBoxRow,
  InfoBoxSubTitle,
  InfoBoxTitle,
} from "./styled/MapElements";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 40.733778,
  lng: -74.17264,
};

const MultiMapView = (props) => {
  const { isLoaded } = useJsApiLoader({
    libraries: ["places"],
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCLTsEEDF3WurlqNWzoP1zytfiSftn2n84",
  });

  const [map, setMap] = React.useState(null);
  const [truckInfoList, setTruckInfoList] = React.useState([]);
  const [chassisList, setChassisList] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState([]);
  const [geoFenceInfo, setGeofenceInfo] = useState({});
  const [infoBoxInfo, setInfoBoxInfo] = useState();

  const onLoad = React.useCallback(
    function callback(map) {
      let b = new window.google.maps.LatLngBounds(center);

      console.log(markers);
      markers.map((marker) => {
        b.extend(marker.position);
      });

      setBounds(b);
      map.fitBounds(b);
      setMap(map);
    },
    [props.truckInfoList, props.chassisList]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  React.useEffect(() => {
    // if (isLoaded == null || isLoaded == false) {
    //   return;
    // }
    console.log(props.truckInfoList);
    setTruckInfoList(props.truckInfoList);
    setChassisList(props.chassisList);
    setLocations(props.geoFenceLocations);

    let allMarkers = [];
    let inGeofencesTrucks = props.truckInfoList.filter((t) => t.truck_snapshot.geo_fence);
    let outOfGeofencesTrucks = props.truckInfoList.filter(
      (t) => t.truck_snapshot.location == null || t.truck_snapshot.geo_fence == null
    );
    let inGeofencesChassis = props.chassisList.filter(
      (c) =>
        c.chassis_snapshot.location != null &&
        c.chassis_snapshot.geo_fence != null
    );
    let outOfGeofencesChassis = props.chassisList.filter(
      (c) =>
        c.chassis_snapshot.location == null ||
        c.chassis_snapshot.geo_fence == null
    );
    console.log(inGeofencesTrucks);
    console.log(outOfGeofencesTrucks);
    console.log(inGeofencesChassis);
    console.log(outOfGeofencesChassis);
    const [truckClusters, indTrucks] =
      clusterObjectLocations(outOfGeofencesTrucks);
    const [chassisClusters, indChasses] = clusterObjectLocations(
      outOfGeofencesChassis
    );
    const [paired, independantTrucks, independantChasses] =
      checkForPairedObjects(indTrucks, indChasses);

    let geoFenceInfo = {};

    // Handle Geo Fences
    inGeofencesTrucks.map((truck) => {
      let geoFence = truck.truck_snapshot.geo_fence;
      if (geoFenceInfo[geoFence.id] == null) {
        geoFenceInfo[geoFence.id] = { trucks: [], chasses: [] };
      }

      geoFenceInfo[geoFence.id].trucks.push(truck.number);
    });

    inGeofencesChassis.map((chassis) => {
      let geoFence = chassis.chassis_snapshot.geo_fence;
      if (geoFenceInfo[geoFence.id] == null) {
        geoFenceInfo[geoFence.id] = { trucks: [], chasses: [] };
      }

      geoFenceInfo[geoFence.id].chasses.push(chassis.number);
    });

    console.log(geoFenceInfo);

    setGeofenceInfo(geoFenceInfo);

    if (props.truckInfoList) {
      let cm = truckClusters.map((c) => {
        console.log(c);
        return (
          <Marker
            key={c.numbers.join("-")}
            label={{
              text: `Trucks: ${c.numbers.join(",")}`,
              color: "#000000",
              fontSize: "24px",
              fontWeight: "bold",
            }}
            icon={{
              labelOrigin: new google.maps.Point(40, 90),
              // anchor: new google.maps.Point(0, 0),
              url: "/assets/truck.svg",
              scaledSize: new google.maps.Size(100, 100),
            }}
            position={c.mapLocation}
            visible={true}
          />
        );
      });

      let im = independantTrucks.map((i) => {
        console.log(i);
        return (
          <Marker
            key={i.number}
            label={{
              text: `${i.number}`,
              color: "#000000",
              fontSize: "22px",
              fontWeight: "bold",
            }}
            icon={{
              labelOrigin: new google.maps.Point(40, 57),
              // anchor: new google.maps.Point(0, 0),
              url: "/assets/truck.svg",
              scaledSize: new google.maps.Size(100, 100),
            }}
            position={i.mapLocation}
            visible={true}
          />
        );
      });

      allMarkers = allMarkers.concat(cm);
      allMarkers = allMarkers.concat(im);
    }

    let pairs = paired.map((i) => {
      return (
        <Marker
          key={`${i.truck.number}-${i.chassis.number}`}
          label={{
            text: `${i.truck.number} & ${i.chassis.number}`,
            color: "#000000",
            fontSize: "22px",
            fontWeight: "bold",
          }}
          icon={{
            labelOrigin: new google.maps.Point(130, 95),
            // anchor: new google.maps.Point(0, 0),
            url: "/assets/truck_and_chassis.svg",
            scaledSize: new google.maps.Size(200, 200),
          }}
          position={i.truck.mapLocation}
          visible={true}
        />
      );
    });

    allMarkers = allMarkers.concat(pairs);

    if (props.chassisList) {
      let cmc = chassisClusters.map((c) => {
        console.log(c);
        return (
          <Marker
            key={c.numbers.join("-")}
            label={{
              text: `Chasses: ${c.numbers.join(",")}`,
              color: "#000000",
              fontSize: "24px",
              fontWeight: "bold",
            }}
            icon={{
              labelOrigin: new google.maps.Point(60, 20),
              url: "/assets/chassis.svg",
              // anchor: new google.maps.Point(50, 50),
              scaledSize: new google.maps.Size(130, 130),
            }}
            position={c.mapLocation}
            visible={true}
          />
        );
      });

      let imc = independantChasses.map((i) => {
        console.log(i);
        return (
          <Marker
            key={i.number}
            label={{
              text: `${i.number}`,
              color: "#000000",
              fontSize: "20px",
              fontWeight: "bold",
            }}
            icon={{
              labelOrigin: new google.maps.Point(60, 60),
              url: "/assets/chassis.svg",
              // anchor: new google.maps.Point(50, 50),
              scaledSize: new google.maps.Size(130, 130),
            }}
            position={i.mapLocation}
            visible={true}
          />
        );
      });

      allMarkers = allMarkers.concat(cmc);
      allMarkers = allMarkers.concat(imc);
    }

    if (allMarkers.length > 0) {
      let b = bounds;
      allMarkers.map((marker) => {
        b.extend(marker.props.position);
      });

      setMarkers(allMarkers);
      setBounds(b);
      map.fitBounds(b);
      setMap(map);
    }
  }, [props.truckInfoList, props.chassisList, props.geoFenceLocations]);

  const getGeofences = (locations, geoFenceInfo) => {
    const options = {
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      zIndex: 1,
    };

    if (locations) {
      console.log(`GeoFences: ${locations.length}`);
      return locations.map((l) => {
        console.log(l.location_type);
        console.log(l.geo_fence);

        switch (l.location_type) {
          case "yard":
            options.fillColor = "#84fc03";
            break;
          case "terminal":
            options.fillColor = "#fcad03";
            break;
          case "warehouse":
            options.fillColor = "#0356fc";
            break;
        }

        if (geoFenceInfo[l.geo_fence.id]) {
          return (
            <Marker
              key={l.geo_fence.id}
              label={{
                text: `${l.name}\n Trucks: ${
                  geoFenceInfo[l.geo_fence.id].trucks.length
                }\n Chasses: ${geoFenceInfo[l.geo_fence.id].chasses.length}`,
                color: "#000000",
                fontSize: "16px",
                fontWeight: "bold",
                className: "marker-label",
              }}
              position={{
                lat: l.geo_fence.center_lat,
                lng: l.geo_fence.center_lng,
              }}
              visible={true}
              icon={{
                labelOrigin: new google.maps.Point(60, 60),
                url: `/assets/fence_${l.location_type}.svg`,
                // anchor: new google.maps.Point(50, 50),
                scaledSize: new google.maps.Size(130, 130),
              }}
              onClick={() => {
                setInfoBoxInfo({
                  location: {
                    lat: l.geo_fence.center_lat,
                    lng: l.geo_fence.center_lng,
                  },
                  content: (
                    <InfoBoxContainer>
                      <InfoBoxTitle>{l.name}</InfoBoxTitle>
                      <InfoBoxSubTitle>Trucks</InfoBoxSubTitle>
                      <InfoBoxRow>
                        {geoFenceInfo[l.geo_fence.id].trucks.join(", ")}
                      </InfoBoxRow>
                      <InfoBoxSubTitle>Chasses</InfoBoxSubTitle>
                      <InfoBoxRow>
                        {geoFenceInfo[l.geo_fence.id].chasses.join(", ")}
                      </InfoBoxRow>
                    </InfoBoxContainer>
                  ),
                });
              }}
            />
          );
        } else {
          options.radius = l.geo_fence.radius;
          return [
            <Circle
              center={{
                lat: l.geo_fence.center_lat,
                lng: l.geo_fence.center_lng,
              }}
              // required
              options={{ ...options }}
            />,
            <Marker
              key={`${l.geo_fence.id}-label`}
              label={{
                text: l.name,
                color: "#000000",
                fontSize: "16px",
                fontWeight: "bold",
                className: "marker-label",
              }}
              position={{
                lat: l.geo_fence.center_lat,
                lng: l.geo_fence.center_lng,
              }}
              visible={true}
              icon={{
                // labelOrigin: new google.maps.Point(0, 100),
                url: `/assets/fence_${l.location_type}.svg`,
                // anchor: new google.maps.Point(50, 50),
                scaledSize: new google.maps.Size(0, 0),
              }}
            />,
          ];
        }
      });
    }
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={truckInfoList.length > 0 ? truckInfoList[0].mapLocation : center}
      // zoom={truckInfoList.length > 0 ? truckInfoList[0].location.zoom : 10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      // fitBounds={bounds}
    >
      <TrafficLayer autoUpdate />
      {getGeofences(locations, geoFenceInfo)}
      {markers}
      {infoBoxInfo ? (
        <InfoBox
          options={{
            pane: "mapPane",
            pixelOffset: new window.google.maps.Size(-150, 0),
            boxStyle: {
              width: "300px",
            },
            closeBoxURL: ``,
          }}
          position={infoBoxInfo.location}
        >
          {infoBoxInfo.content}
        </InfoBox>
      ) : (
        <></>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MultiMapView;
