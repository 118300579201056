import React from 'react';
import styled from 'styled-components';

export const TrackingBanner = styled.div`
    width: 90%;
    display: inline-block;
    text-align: center;
    font-size: 4rem;
    font-weight: bold;
    margin: 5%
`
export const TrackingInfo = styled.div`
    width: 90%;
    display: inline-block;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 0 5%
`

export const TrackingMovesContainer = styled.table`
    width: 90%;
    margin: 5%
`

export const TrackingMove = styled.tr`
    padding: 5% 0;
`

export const TrackingMoveInfoHeadingNarrow = styled.th`
    width: 5%;
    font-size: 3rem;
    font-weight: bold;
    padding: 3% 1%;
`

export const TrackingMoveInfoHeading = styled.th`
    width: 30%;
    font-size: 3rem;
    font-weight: bold;
    padding: 3% 1%;
`
export const TrackingMoveInfoNarrow = styled.td`
    width: 5%;
    font-size: 2rem;
    font-weight: bold;
    padding: 3% 1%;
`

export const TrackingMoveInfo = styled.td`
    width: 30%;
    font-size: 2rem;
    font-weight: bold;
    padding: 3% 1%;
`

export const StatusDot = styled.div`
    background: ${(props) => props.color ? props.color : 'grey'};
    float: left;
    border-radius: 50%;
    width: 70%;
    padding-bottom: 70%;
`