import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/user";
import { Bar, Logo, LogoutButton, Title, UserInfoContainer, UserName } from "./components";

export default (props) => {
    const currentUser = useSelector(state => state.currentUser);
    const dispatch = useDispatch();

    const onLogout = () => {
        let result = confirm("Are you sure you want to log out?");
        if (result) {
            dispatch(logout());
            window.location.href = "/";
        }
    }
    return <>
    { props.currentUser ?
    <Bar>
      <Logo src={props.currentUser.tenant.settings.logo} />
      <Title>Truck This</Title>
      <UserInfoContainer>
        <UserName>
            Welcome{" "}
            {props.currentUser ? props.currentUser.username : ""}
        </UserName>
        <LogoutButton onClick={() => onLogout()}>Logout</LogoutButton>
      </UserInfoContainer>
      </Bar>
      : <></>
    }</>;
}