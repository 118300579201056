import { Button, Input, InputAdornment, Select } from '@material-ui/core';
import { Add, Edit, RemoveCircle, Save } from '@material-ui/icons';
import { Note } from '@mui/icons-material';
import React, { useState } from 'react'
import { copyTable, titlize } from '../util/functions';
import { invoiceHeaderInlineStyle, invoiceLineItemsInlineStyle, invoiceTableInlineStyle } from './Components';
import { getLineItemOptions } from './forms/Options';
import { LargeModal, ModalHeader } from './Modals';

export const QuoteTool = (props) => {
    const [fuelSurcharge, setFuelSurcharge] = useState(0.35);
    const [invoice, setInvoice] = useState({total: 0.0, line_items: []});
    const [isEdit, setIsEdit] = useState(true);
    const [visible, setVisible] = useState(false);

    React.useEffect(() => {
        setVisible(props.visible);
    }, [props.visible])

    const onLineItemAdded = () => {
        console.log("New Line");
        let inv = {...invoice};
        let li = {id: `new-${new Date()}`, line_item_type: "not_set", amount: 0.0, quantity: 1, discount: null};
        inv.line_items.push(li);
        setInvoice(inv);
    }

    const onLineItemRemoved = (id) => {
        console.log("New Line");
        let lineItems = invoice.line_items.filter((i) => i.id != id);
        let inv = {...invoice, line_items: lineItems};
        setInvoice(inv);
    }

    const onLineItemUpdated = (lineItemId, field, value, lineHaulAmount, newFuelSurcharge) => {
        console.log(`ID: ${lineItemId} Field: ${field} Val: ${value}`);
        let found = invoice.line_items.filter((li) => {
            if (li.id == lineItemId) {
                if (field == "line_item_type" && value == "fuel_surcharge") {
                    if (lineHaulAmount == null) {
                        alert("Please Add Line Haul before Fuel Surcharge!");
                        return
                    }
                    if (newFuelSurcharge == null) {
                        newFuelSurcharge = fuelSurcharge;
                    }
                    li['notes'] = `%${newFuelSurcharge * 100} of Line Haul`
                    li['amount'] = newFuelSurcharge * lineHaulAmount;
                    console.log(li);
                } else if (field == "line_item_type") {
                    switch(value) {
                        case "chassis_rental":
                            li['notes'] = "(PER DAY)";
                            li['amount'] = 40;
                            break;
                        case "storage":
                            li['notes'] = "(PER DAY)";
                            li['amount'] = 40;
                            break;
                        case "pre_pull":
                            li['notes'] = "(IF APPLICABLE)";
                            li['amount'] = 125;
                            break;
                    }
                }

                li[field] = value;
                li.amount = li.amount * 1.0;
            }
            return li;
        });
        
        let newTotal = 0.0;
        let inv = {...invoice};

        found.map((li) => {
            newTotal += li.discount ? (li.amount * li.quantity) - (li.amount * li.quantity * li.discount) : li.amount * li.quantity;
        });

        inv.total = newTotal.toFixed(2);
        setInvoice({...inv, line_items: found})
    }

    const getContent = (currentInvoice, isEditing) => {
            if (!isEditing) {
                    return  <div style={{float: "left", width: "98%", margin: "1%"}}>
                        <div style={{width: "98%", margin: "1%"}}>
                            <Button onClick={() => copyTable(`invoice-table-quote-tool`)} startIcon={<Note />}>Copy Line Items</Button>
                            <Button onClick={() => setIsEdit(true)} startIcon={<Edit />}>Edit</Button>
                        </div>
                        <table id={`invoice-table-quote-tool`} style={invoiceTableInlineStyle}>
                        <tr><th colSpan={"3"} style={{...invoiceHeaderInlineStyle, textAlign: "center", fontSize: "2rem", borderRadius: "10px 10px 0 0"}}>New Drayage Quote</th></tr>
                        <tr><th style={invoiceHeaderInlineStyle}>Line</th><th style={invoiceHeaderInlineStyle}>Notes</th><th style={invoiceHeaderInlineStyle}>Amount</th></tr>
                            {
                                currentInvoice.line_items.map((li) => {
                                    return <tr>
                                        <td style={invoiceLineItemsInlineStyle}>{titlize(li.line_item_type)}</td>
                                        <td style={invoiceLineItemsInlineStyle}>{li.notes}</td>
                                        <td style={{...invoiceLineItemsInlineStyle, textAlign: "center"}}>${li.discount ? `${((li.amount * li.quantity) - (li.amount * li.quantity * li.discount)).toFixed(2)} (-%${li.discount * 100})` : (li.amount * li.quantity).toFixed(2)}</td>
                                    </tr>
                                })
                            }
                            <tr><th colSpan={"3"} style={{...invoiceHeaderInlineStyle, textAlign: "center", fontSize: "2rem", borderRadius: "10px 10px 0 0"}}>Port Fees (IF APPLICABLE)</th></tr>
                        <tr><th colSpan={"2"} style={invoiceHeaderInlineStyle}>Port</th><th style={invoiceHeaderInlineStyle}>Amount</th></tr>
                        <tr>
                            <td colSpan={"2"} style={invoiceLineItemsInlineStyle}>GCT</td>
                            <td style={{...invoiceLineItemsInlineStyle, textAlign: "center"}}>$150</td>
                        </tr>
                        <tr>
                            <td colSpan={"2"} style={invoiceLineItemsInlineStyle}>NYCT (PER TRIP)</td>
                            <td style={{...invoiceLineItemsInlineStyle, textAlign: "center"}}>$250</td>
                        </tr>
                        </table>
                    </div>
            } else {
                let lineHaulCharge = 0.0;
                let newTotal = 0.0;
                return  <div style={{float: "left", width: "98%", margin: "1%"}}>
                        <table id={`invoice-table-quote`} style={invoiceTableInlineStyle}>
                        <tr>
                            <th colSpan={"4"} style={{...invoiceHeaderInlineStyle, textAlign: "center", fontSize: "2rem", borderRadius: "10px 0 0 0"}}>New Drayage Quote</th>
                            <th style={invoiceHeaderInlineStyle}></th>
                            <th style={invoiceHeaderInlineStyle}><Button style={{color: "white"}} onClick={() => onLineItemAdded()} startIcon={<Add />}>Add Line</Button></th>
                            <th style={{...invoiceHeaderInlineStyle, borderRadius: "0 10px 0 0"}}><Button style={{color: "white"}} onClick={() => setIsEdit(false)} startIcon={<Save />}>Save</Button></th>
                        </tr>
                        <tr><th style={invoiceHeaderInlineStyle}>Line</th><th style={invoiceHeaderInlineStyle}>Notes</th><th style={invoiceHeaderInlineStyle}>Amount</th><th style={invoiceHeaderInlineStyle}>QTY</th><th style={invoiceHeaderInlineStyle}>Discount</th><th style={invoiceHeaderInlineStyle}>Sum</th><th style={invoiceHeaderInlineStyle}></th></tr>
                            {
                                currentInvoice.line_items.map((li) => {
                                    let isFuelSurcharge = li.line_item_type == "fuel_surcharge";
                                    if (li.line_item_type == "line_haul") {
                                        lineHaulCharge = (li.amount * li.quantity) - (li.amount * li.quantity * li.discount);
                                    }
                                    newTotal += li.discount ? (li.amount * li.quantity) - (li.amount * li.quantity * li.discount) : li.amount * li.quantity;
                                    return <tr>
                                        <td style={invoiceLineItemsInlineStyle}>
                                            <Select
                                                value={li.line_item_type}
                                                onChange={(e) => {onLineItemUpdated(li.id, 'line_item_type', e.target.value, lineHaulCharge)}}
                                                >
                                                {getLineItemOptions()}
                                            </Select>
                                        </td>
                                        <td style={{...invoiceLineItemsInlineStyle, textAlign: "left"}}>
                                            <Input disabled={isFuelSurcharge} type='text' value={li.notes} onChange={(e) => {onLineItemUpdated(li.id, 'notes', e.target.value)}}/>
                                        </td>
                                        { isFuelSurcharge ?
                                            <td style={{...invoiceLineItemsInlineStyle, textAlign: "center"}}>Fuel SC %<Input type="number" value={fuelSurcharge * 100} onChange={(e) => {setFuelSurcharge(e.target.value / 100.0), onLineItemUpdated(li.id, 'line_item_type', "fuel_surcharge", lineHaulCharge, e.target.value / 100.0)}}/></td>
                                            :
                                            <td style={{...invoiceLineItemsInlineStyle, textAlign: "center"}}>
                                                <Input disabled={isFuelSurcharge} type='number' value={li.amount} onChange={(e) => {onLineItemUpdated(li.id, 'amount', e.target.value)}} startAdornment={<InputAdornment position="start">$</InputAdornment>}/>
                                            </td>
                                        }
                                        <td style={{...invoiceLineItemsInlineStyle, textAlign: "center"}}>
                                            <Input disabled={isFuelSurcharge} type='number' value={li.quantity} onChange={(e) => {onLineItemUpdated(li.id, 'quantity', e.target.value)}} startAdornment={<InputAdornment position="start">#</InputAdornment>}/>
                                        </td>
                                        <td style={{...invoiceLineItemsInlineStyle, textAlign: "center"}}>
                                            <Input disabled={isFuelSurcharge} type='number' value={li.discount} onChange={(e) => {onLineItemUpdated(li.id, 'discount', e.target.value)}} startAdornment={<InputAdornment position="start">%</InputAdornment>}/>
                                        </td>
                                        <td style={{...invoiceLineItemsInlineStyle, textAlign: "center"}}>{li.discount ? `${((li.amount * li.quantity) - (li.amount * li.quantity * li.discount)).toFixed(2)} (-%${li.discount * 100})` : (li.amount * li.quantity).toFixed(2)}</td>
                                        <td style={{...invoiceLineItemsInlineStyle, textAlign: "center"}}><Button startIcon={<RemoveCircle />} onClick={()=>{onLineItemRemoved(li.id)}}>Remove</Button></td>
                                    </tr>
                                })
                            }
                            <tr style={{backgroundColor: "lightgrey"}}><td  style={{textAlign: "left"}}>Total</td><td></td><td></td><td></td><td></td><td style={{textAlign: "center"}}>${newTotal.toFixed(2)}</td><td></td></tr>
                        </table>
                    </div>
            }
    }

    return <LargeModal visible={visible} closeCallback={props.onClose}>
        <ModalHeader style={{width: "unset"}}>
            Drayage Quote
        </ModalHeader>
       {getContent(invoice, isEdit)}
    </LargeModal>
}