const token = document.querySelector("[name=csrf-token]").content;

export var getAllDrivers = (onSuccess, onError, include) => {
    let incString = "?include="
    if (include) {
      include.map((item) => {
        incString = incString.concat(`,${item}`);
      });
    }
    return fetch(`/api/v1/drivers${incString}`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token },
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (decoded.status_code == 401) {
          window.location.href = decoded.redirect;
          onSuccess([]);
        }
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var getAllDriversWithFees = (onSuccess, onError) => {
    return fetch(`/api/v1/drivers/fees`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token },
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (decoded.status_code == 401) {
          window.location.href = decoded.redirect;
          onSuccess([]);
        }
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var createDriver = (driver, onSuccess, onError) => {
    return fetch(`/api/v1/drivers`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      body: JSON.stringify(driver),
      method: "POST"
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (decoded.status_code == 401) {
          window.location.href = decoded.redirect;
          onSuccess([]);
        }
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var getDriversSchedulesBetween = (driverId, start, end, onSuccess, onError) => {
    return fetch(`/api/v1/drivers/${driverId}/schedules?start=${start}&end=${end}`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token },
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var getDriversSettlementBetween = (driverId, start, end, onSuccess, onError) => {
    return fetch(`/api/v1/drivers/${driverId}/settlement?start=${start}&end=${end}`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token },
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var createSettlement = (settlement, onSuccess, onError) => {
    return fetch(`/api/v1/drivers/settlement`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      body: JSON.stringify(settlement),
      method: "POST"
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var updateSettlement = (settlement, onSuccess, onError) => {
    return fetch(`/api/v1/drivers/settlement`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      body: JSON.stringify(settlement),
      method: "PUT"
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  