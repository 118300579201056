import React, { Fragment } from "react";
import { currentUser } from "../api/user";
import consumer from "../channels/consumer";
import "react-notifications/lib/notifications.css";
import { HRAccess } from "../util/constants";
import { Alert, Button } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { addAlert, createAlert, removeAlert } from "../store/alerts";
import { setUser } from "../store/user";
import { ProgressOverlay } from "../components/Components";
import { QuoteTool } from "../components/Tools";
import Header from "../components/header/Header";
import ChatComponent from "../components/chat/ChatComponent";

class SecuredView extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.alerts);
    console.log(this.props.currentUser);
    this.state = {
      currentUser: this.props.currentUser,
      notification: null,
      waitingFor: [],
      openTool: null,
    };
    this.handleMessage = this.handleMessage.bind(this);
    this.openTool = this.openTool.bind(this);
    this.closeTool = this.closeTool.bind(this);
  }

  componentDidMount() {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }

    currentUser((currentUser) => {
      console.log(currentUser);

      if (currentUser == null || currentUser.error != null) {
        alert("You are logged out!");
        window.location.href = "/";
        throw new Error("not authorised");
      } else {
        let platform_license = currentUser.tenant.active_licenses.find(
          (license) => license.type == "platform_license" && license.is_active
        );
        if (!platform_license) {
          alert("License Expired!");
          window.location.href = "/";
        }

        if (this.props.accessLevels.includes(currentUser.access_level)) {
          console.log("authorised");
          // this.setState({ currentUser: currentUser});
        } else {
          console.log("not authorised");
          alert("You are not authorised to view this page!");
          window.location.href = "/";
          throw new Error("not authorised");
        }

        this.props.setUser(currentUser);
      }

      var arr_of_identifiers = consumer.subscriptions.subscriptions.map((s) => {
        return s.identifier;
      });
      var is_subscribed = false;
      for (const identifier of arr_of_identifiers) {
        console.log(identifier);
        if (identifier.includes("NotificationChannel")) {
          is_subscribed = true;
          break;
        }
      }
      console.log(is_subscribed);
      if (is_subscribed == false) {
        this.consumer = consumer.subscriptions.create("NotificationChannel", {
          received: this.handleMessage,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.setState({ currentUser: this.props.currentUser });
    }
  }

  handleMessage(data) {
    console.log(data);
    if (
      this.state.currentUser != null &&
      this.state.currentUser.id != data.user_id
    ) {
      this.showNotification(data);
    } else if (
      this.state.currentUser != null &&
      this.state.currentUser.id == data.user_id
    ) {
      data.id = new Date().getTime();
      if (data.type != "error") {
        data.body = "Saved Successfully";
        data.severity = "success";
      }
      console.log(data);
      this.props.addAlert(data);
    }
  }

  openTool(toolName) {
    this.setState({ openTool: toolName });
  }

  closeTool() {
    this.setState({ openTool: null });
  }

  showNotification(notification) {
    let image = "job-64.png";
    switch (notification.type) {
      case "job":
        image = "job-64.png";
        break;
      case "container":
        image = "box-50.png";
        break;
      case "schedule":
        image = "highway-64.png";
        break;
      case "compliance":
        image = "legal-64.png";
        break;
      case "location":
        image = "highway-64.png";
        break;
      case "chat":
        image = "chat-64.png";
        break;
    }

    var options = {
      body: notification.body,
      icon: "/assets/" + image,
      dir: "ltr",
    };
    if (
      notification.type == "compliance" &&
      HRAccess.includes(this.state.currentUser.access_level)
    ) {
      var notification = new Notification("Truck This ", options);
    } else {
      var notification = new Notification("Truck This ", options);
    }
  }

  requiredAccessLevels() {
    this.props.accessLevels;
  }

  reloadData(page, perPage, options) {}

  render() {
    return (
      <Fragment>
        <ProgressOverlay visible={this.props.waitingFor.length > 0} />
        {!this.props.fullScreen ? (
          <>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 999,
              }}
            >
              {this.props.alerts.map((a) => {
                let alert = (
                  <Alert
                    onClose={() => this.props.removeAlert(a)}
                    severity={a.severity}
                  >
                    {a.body}
                  </Alert>
                );

                setTimeout(() => this.props.removeAlert(a), 10000);

                return alert;
              })}
            </div>
            <Header
              currentUser={this.state.currentUser}
              openTool={this.openTool}
              onNotification={this.showNotification}
            />
          </>
        ) : (
          <></>
        )}
        {this.state.currentUser ? this.props.children : <></>}
        <QuoteTool
          onClose={this.closeTool}
          visible={this.state.openTool == "quoting_tool"}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts.alerts,
    currentUser: state.user.user,
    waitingFor: state.busy.waitingFor,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addAlert: (alert) => dispatch(addAlert(alert)),
    removeAlert: (alert) => dispatch(removeAlert(alert)),
    setUser: (user) => dispatch(setUser(user)),
    // dismissPogressOverlay: identifier => dispatch(dismissProgressOverlay(identifier)),
    // displayProgressOverlay: identifier => dispatch(displayProgressOverlay(identifier)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecuredView);
