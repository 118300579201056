const token = document.querySelector("[name=csrf-token]").content;

export var customerAll = (onSuccess, onError) => {
  return fetch(`/api/v1/customers`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var customerCreate = (customer, onSuccess, onError) => {
  return fetch(`/api/v1/customers`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(customer)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var customerUpdate = (customer, onSuccess, onError) => {
  return fetch(`/api/v1/customers`, {
    method: "PUT",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(customer)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var customerContactsAll = (onSuccess, onError) => {
  return fetch(`/api/v1/contacts`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token,}
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var customerContactCreate = (customerContact, onSuccess, onError) => {
  console.log(customerContact);
  return fetch(`/api/v1/customers/contact`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(customerContact)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var customerContactUpdate = (customerContact, onSuccess, onError) => {
  console.log(customerContact);
  return fetch(`/api/v1/contact`, {
    method: "PUT",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(customerContact)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};