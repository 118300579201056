import React from 'react';
import styled from 'styled-components';

export const Table = styled.table`
color: black;
background: #8080802e;
width: 90%;
margin: 5%;
box-shadow: 8px 5px 5px 0px #aaa;
border-radius: 1rem;
overflow: hidden;
& tr:nth-child(odd) {background: #cccccc;}
`;

export const TR = styled.tr`
border-bottom: 0.5px solid black
`;

export const TH = styled.th`
background: darkgrey;
font-size: 1rem;
font-weight: bold;
padding: 1%;
`;

export const TD = styled.td`
width: ${(props) => props.width};
font-weight: ${(props) => props.fontWeight};
padding: 1%;
`;