const token = document.querySelector("[name=csrf-token]").content;

export var login = (loginParams, onSuccess, onError) => {
  return fetch(`/api/v1/auth`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(loginParams),
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
  })
    .then((res) => {
      if(res.status != 200) {
        throw new Error("Login Error!");
      }
      return res.json();
    })
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    }, err => {
      if (onError != null) onError(err);
    });
};

export var currentUser = (onSuccess, onError) => {
  return fetch(`/api/v1/current_user`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var logout = () => {
  return fetch(`/api/v1/auth`, {
    method: "DELETE",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
  })
    .then((res) => res.json())
    .then((decoded) => console.log(decoded));
};
