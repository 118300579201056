import React from 'react';
import styled from 'styled-components';

export const ComplianceBadge = styled.div`
    float: left;
    font-weight: bold;
    color: white;
    background: red;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
`