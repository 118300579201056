import React, { Fragment } from "react";
import styled from "styled-components";
import { all, getActiveWork, getLoadById } from "../api/active_work";
import { setStatus } from "../api/jobs";
import Container from "../components/Container";
import LaunchIcon from "@mui/icons-material/Launch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NewOrderForm from "../components/forms/NewOrderForm";
import Select from "@material-ui/core/Select";
import { JobStatusOptions, getStatusColor } from "../components/forms/Options";
import { titlize } from "../util/functions";
import NewContainerForm from "../components/forms/NewContainerForm";
import LogViews from "../components/LogViews";
import { Button, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import SecuredView from "./SecuredView";
import { getAllLocations } from "../api/locations";
import SortBar from "../components/SortBar";
import { connect } from "react-redux";
import { displayProgressOverlay, dismissProgressOverlay } from "../store/busy";
import { SearchBarThird } from "../components/styled/Elements";
import DispatchList from "../components/DispatchList";
import { get } from "jquery";
import { getConversations } from "../api/chat";

const Table = styled.table`
  color: black;
  background: #8080802e;
  width: 90%;
  margin: 5%;
  box-shadow: 8px 5px 5px 0px #aaa;
  border-radius: 1rem;
  overflow: hidden;
`;

const TR = styled.tr`
  border-bottom: 0.5px solid black;
`;

const TH = styled.th`
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1%;
`;

const TD = styled.td`
  width: ${(props) => props.width};
  font-weight: ${(props) => props.fontWeight};
  padding: 1%;
`;

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
      activeWork: [],
      activeWorkFiltered: [],
      loadsFiltered: [],
      currentJob: null,
      currentJobContainer: null,
      currentContainer: null,
      addJobFormVisible: false,
      dispatchListVisible: false,
      logViewVisible: false,
      terminalOptions: [],
      filter: "is_active",
      terminal: null,
      copyList: [],
      showLoadsOnly: false,
      sortBy: "load",
      sortOrder: "asc",
      waitingFor: [],
      search: null,
      pageData: {
        currPage: 1,
        maxPage: 1,
        perPage: 10,
      },
    };

    this.modalCloseCallback = this.modalCloseCallback.bind(this);
    this.openAddJobForm = this.openAddJobForm.bind(this);
    this.closeLogView = this.closeLogView.bind(this);
    this.closeDispatchListView = this.closeDispatchListView.bind(this);
    this.searchCallback = this.searchCallback.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.search = this.search.bind(this);
    this.onNewPage = this.onNewPage.bind(this);
    this.dismissPogressOverlay = this.dismissPogressOverlay.bind(this);
    this.showProgressOverlay = this.showProgressOverlay.bind(this);
    this.onPage = this.onPage.bind(this);
  }

  onPage(page, perPage) {
    console.log(page);
    console.log(perPage);
    this.reloadData(page, perPage, {});
  }

  showProgressOverlay(key) {
    this.props.displayProgressOverlay({ id: key });
  }

  dismissPogressOverlay(key) {
    this.props.dismissPogressOverlay({ id: key });
  }

  sortByLoad(list) {
    return list.sort((a, b) => {
      if (a.job_containers == null) {
        return 0;
      }

      if (b.job_containers == null) {
        return 0;
      }

      if (a.job_containers.length == 0) {
        return 1;
      }

      if (b.job_containers.length == 0) {
        return -1;
      }

      return a.job_containers[0].load_number - b.job_containers[0].load_number;
    });
  }

  componentDidMount() {
    this.showProgressOverlay("activeWork");
    // this.showProgressOverlay("locations");

    if (location.search != null && location.search != "") {
      const queryParams = new URLSearchParams(location.search);
      const id = queryParams.get("load_id");
      const filter = queryParams.get("filter");

      getLoadById(id, (result) => {
        if (result) {
          this.setState({
            search: location.search,
            currentJob: result,
            currentJobContainer: result.job_containers.filter(
              (jc) => jc.id == id
            )[0],
            activeWork: [result],
            activeWorkFiltered: [result],
            pageData: { ...this.state.pageData, currPage: 1, maxPage: 1 },
          });
        } else {
          alert("Load Not Found!");
        }
        this.dismissPogressOverlay("activeWork");
      });
    } else {
      getActiveWork(
        this.state.pageData,
        { filter: this.state.filter },
        (result) => {
          this.dismissPogressOverlay("activeWork");
          let newJobs = result.data;
          newJobs = this.sortByLoad(newJobs);
          this.setState({
            activeWork: newJobs,
            activeWorkFiltered: newJobs,
            pageData: {
              ...this.state.pageData,
              currPage: result.page,
              maxPage: result.max_page,
            },
          });
          this.filter("is_active", null, null, newJobs, false);
        }
      );
    }

    getAllLocations((results) => {
      // this.dismissPogressOverlay("locations");
      let terminals = results.filter((l) => l.location_type == "terminal");
      this.setState({
        terminalOptions: terminals,
        terminal: terminals.length > 0 ? terminals[0].name : null,
      });
    });
  }

  handleMessage(message) {
    super.handleMessage(message);
    // this.reload();
  }

  async reload(filter) {
    this.showProgressOverlay("activeWork");
    getActiveWork(this.state.pageData, { filter: filter }, (result) => {
      let newJobs = result.data;
      this.setState({
        activeWork: newJobs,
        filter: filter,
        pageData: {
          ...this.state.pageData,
          currPage: result.page,
          maxPage: result.max_page,
        },
      });
      this.filter(filter, null, null, newJobs, false);
      this.dismissPogressOverlay("activeWork");
    });
  }

  async search(search) {
    this.showProgressOverlay("activeWork");
    getActiveWork(
      { currPage: 1, perPage: 10 },
      { search: search },
      (result) => {
        let newJobs = result.data;
        this.setState({
          activeWork: newJobs,
          filter: "all",
          pageData: {
            ...this.state.pageData,
            currPage: result.page,
            maxPage: result.max_page,
          },
        });
        this.filter("all", null, this.state.terminal, newJobs, false);
        this.dismissPogressOverlay("activeWork");
      }
    );
  }

  onNewPage(newPage, newPerPage) {
    this.showProgressOverlay("activeWork");
    getActiveWork(
      { ...this.state.pageData, currPage: newPage, perPage: newPerPage },
      { filter: this.state.filter },
      (result) => {
        let newJobs = result.data;
        this.setState({
          activeWork: newJobs,
          pageData: {
            ...this.state.pageData,
            currPage: result.page,
            maxPage: result.max_page,
          },
        });
        this.filter(
          this.state.filter,
          null,
          this.state.terminal,
          newJobs,
          false
        );
        this.dismissPogressOverlay("activeWork");
      }
    );
  }

  filter(type, searchMatches, terminal, dataList, reload) {
    if (reload) {
      this.reload(type);
    } else {
      let filtered = this.filterByType(type, dataList);
      if (terminal != null) {
        filtered = filtered.filter(
          (job) =>
            job.job_containers.filter(
              (jc) =>
                jc.pickup_location.name == terminal ||
                jc.return_location.name == terminal
            ).length > 0
        );
      }

      let finalList = searchMatches
        ? this.filterBySearch(filtered, searchMatches)
        : filtered;

      let loads = [];
      let list = [];
      if (this.state.filter == "is_active") {
        finalList = finalList.sort((a, b) => {
          if (a.status == "pending" && b.status == "accepted") {
            return -1;
          } else if (b.status == "pending" && a.status == "accepted") {
            return 1;
          } else {
            return 0;
          }
        });
      }
      finalList.map((job) => {
        if (job.job_container_snapshots == null) {
          return [];
        }

        job.job_container_snapshots.map((jc) => {
          let job_info = { ...job };
          job_info.job_container_snapshots = [];

          jc.job_info = job_info;
          loads.push(jc);
          list.push(jc.container_number);
        });
      });

      this.setState({
        activeWorkFiltered: finalList,
        filter: type,
        copyList: list,
        terminal: terminal,
        loadsFiltered: loads,
      });
    }
  }

  filterBySearch(filteredList, matchList) {
    return filteredList.filter((job) => {
      return matchList.filter((match) => match.id == job.id).length > 0;
    });
  }

  sort(sortBy, order) {
    let listToSort = [];
    console.log(this.state.activeWorkFiltered);
    this.state.activeWorkFiltered.map((j) => {
      console.log(j);
      if (j.job_container_snapshots) {
        j.job_container_snapshots.map((jc) => {
          listToSort.push(jc);
        });
      }
    });

    console.log(listToSort);
    let sorted = [];
    if (sortBy == "load") {
      if (order == "asc") {
        sorted = listToSort.sort((a, b) => a.load_number - b.load_number);
      } else {
        sorted = listToSort.sort((a, b) => b.load_number - a.load_number);
      }
    } else if (sortBy == "status") {
      if (order == "asc") {
        sorted = listToSort.sort((a, b) => {
          if (a.job_status == "pending" && b.job_status == "accepted") {
            return -1;
          } else if (b.job_status == "pending" && a.job_status == "accepted") {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        sorted = listToSort.sort((a, b) => {
          if (a.job_status == "pending" && b.job_status == "accepted") {
            return 1;
          } else if (b.job_status == "pending" && a.job_status == "accepted") {
            return -1;
          } else {
            return 0;
          }
        });
      }
    } else {
      if (order == "asc") {
        sorted = listToSort.sort(
          (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
        );
      } else {
        sorted = listToSort.sort(
          (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
        );
      }
    }

    console.log(sorted);

    this.setState({ sortBy: sortBy, sortOrder: order, loadsFiltered: sorted });
  }

  filterByType(type, list) {
    if (type == "all") return list;

    let filterList = list ? list : this.state.activeWork;

    if (filterList == null) return [];

    console.log(filterList);

    return filterList.filter((job) => {
      let include = false;
      if (type == "is_active") {
        include = job.status == "accepted" || job.status == "pending";
      } else if (type == "billing") {
        include =
          job.status == "billing_ready" ||
          job.status == "invoiced" ||
          job.status == "final_charges_requested";
      } else {
        include = job.status == type;
      }
      return include;
    });
  }

  updateJobStatus(event, jobId) {
    let newActiveWork = this.state.activeWork;

    let job;

    newActiveWork.forEach((j) => {
      if (j.id == jobId) {
        j.status = event.target.value;
        job = j;
      }
    });

    setStatus(job, (result) => {
      this.setState({ activeWork: newActiveWork });
    });
  }

  getRows(jobs) {
    return jobs.map((job) => {
      return (
        <TR>
          <TD width="100px" fontWeight="bold">
            {job.ref_number.toUpperCase()}
          </TD>
          <TD>{job.customer.name}</TD>
          <TD style={{ textAlign: "center" }}>
            <span
              style={{
                padding: "5%",
                borderRadius: "2rem",
                backgroundColor: getStatusColor(job.status),
              }}
            >
              <Select
                disableUnderline
                labelId={"status_select_" + job.id}
                id={"status_select_" + job.id}
                value={job.status}
                onChange={(e) => this.updateJobStatus(e, job.id)}
              >
                {JobStatusOptions()}
              </Select>
            </span>
          </TD>
          <TD>
            <ol>{this.getContainers(job, job.job_container_snapshots)}</ol>
          </TD>
          <TD>
            <Button onClick={() => this.openEditJobForm(job)}>Edit</Button>
            <Button onClick={() => this.openLogView(job)}>Logs</Button>
          </TD>
        </TR>
      );
    });
  }

  getLoads(loads) {
    let rows = [];
    let scheduledRows = [];

    loads.map((jc) => {
      let job = jc.job_info;
      let delivery_schedules = [];
      // let delivery_schedules = jc.schedules.filter(
      //   (s) => s.is_delivery_location
      // );
      let row = (
        <TR style={{ backgroundColor: getStatusColor(job.status) }}>
          <TD width="100px" fontWeight="bold">
            {jc.load_number}
          </TD>
          <TD>{job.customer.name}</TD>
          <TD style={{ textAlign: "center" }}>{titlize(job.status)}</TD>
          <TD>{titlize(job.job_type)}</TD>
          <TD>
            {jc.container
              ? `${jc.container.number}-${titlize(jc.container.container_type)}`
              : "---"}
          </TD>
          <TD>{jc.state ? titlize(jc.state.container_raw_status) : "---"}</TD>
          <TD>{jc.state ? titlize(jc.state.container_status) : "---"}</TD>
          <TD>
            {new Date(jc.last_free_day).toLocaleString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </TD>
          <TD>
            {new Date(jc.eta).toLocaleString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </TD>
          <TD>{jc.container ? titlize(jc.container.ship_line) : "---"}</TD>
          <TD>{jc.pickup_location ? jc.pickup_location.name : "---"}</TD>
          <TD>{jc.return_location ? jc.return_location.name : "---"}</TD>
          <TD>
            {delivery_schedules.length > 0
              ? `${delivery_schedules[0].drop_location.city},${delivery_schedules[0].drop_location.state}`
              : "---"}
          </TD>
          <TD>
            {delivery_schedules.length > 0 &&
            delivery_schedules[0].appointment_time != null
              ? new Date(delivery_schedules[0].appointment_time).toLocaleString(
                  "en-us",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  }
                )
              : "---"}
          </TD>
          <TD>
            <Button
              variant="outlined"
              onClick={() => {
                this.setState({ currentJob: job, currentJobContainer: jc });
              }}
            >
              View
            </Button>
          </TD>
          <TD style={{ minWidth: "100px" }}>
            <Button onClick={() => this.openLogView(job)}>Logs</Button>
          </TD>
        </TR>
      );

      if (
        delivery_schedules.length == 0 ||
        (delivery_schedules.length > 0 &&
          delivery_schedules[0].appointment_time == null)
      ) {
        rows.push(row);
      } else {
        scheduledRows.push(row);
      }
    });

    return [...rows, ...scheduledRows];
  }

  getContainers(job, jobContainerSnapshots) {
    let containers = [];

    if (jobContainerSnapshots != null) {
      containers = jobContainerSnapshots.map((jobContainer) => {
        return (
          <li style={{ margin: "2% 0" }}>
            {"Load #" + jobContainer.load_number} -{" "}
            {"Cont#" + jobContainer.container_number} -{" "}
            {titlize(jobContainer.container_type)} -{" "}
            {
              <span
                style={{
                  padding: "1%",
                  borderRadius: "2rem",
                  backgroundColor: getStatusColor(
                    jobContainer.container_raw_status
                      ? jobContainer.container_raw_status
                      : ""
                  ),
                }}
              >
                {titlize(
                  jobContainer.container_status
                    ? jobContainer.container_status
                    : "?"
                )}
              </span>
            }
            {jobContainer.container_in_demurrage ? (
              <span
                style={{
                  padding: "1%",
                  borderRadius: "2rem",
                  backgroundColor: "red",
                }}
              >
                {" - "}Demmurage
              </span>
            ) : (
              " "
            )}{" "}
            -{" "}
            <Button
              variant="outlined"
              startIcon={<LaunchIcon />}
              onClick={() => {
                this.setState({
                  currentJobContainer: jobContainer,
                  currentJob: job,
                });
              }}
            >
              View Details
            </Button>
          </li>
        );
      });
    }

    containers.push(
      <div>
        <AddCircleIcon
          sx={{ fontSize: 20 }}
          onClick={() => this.openAddContainerForm(job)}
        />
        <span>Add Container</span>
      </div>
    );

    return containers;
  }

  getContainerSchedules(jobContainer, job) {
    if (jobContainer == null) return [];

    var schedules = jobContainer.schedules.filter(
      (schedule) => schedule.container_id == jobContainer.container.id
    );
    return schedules;
  }

  modalCloseCallback(key) {
    this.setState({
      addJobFormVisible: false,
      currentJobContainer: null,
      currentJob: null,
      addContainerFormVisible: false,
    });

    if (key) {
      this.dismissPogressOverlay(key);
    }

    this.reload(this.state.filter);
  }

  openEditJobForm(job) {
    console.log("Current User:");
    console.log(this.props.currentUser);
    this.setState({
      currentJob: job,
      addJobFormVisible: true,
    });
  }

  openAddJobForm() {
    this.setState({
      addJobFormVisible: true,
    });
  }

  openAddContainerForm(job) {
    this.setState({
      currentJob: job,
      addContainerFormVisible: true,
    });
  }

  closeLogView() {
    this.setState({ currentJob: null, logViewVisible: false });
  }

  openLogView(job) {
    this.setState({ currentJob: job, logViewVisible: true });
  }

  closeDispatchListView() {
    this.setState({ dispatchListVisible: false });
  }

  getSearchOptions(jobs, type) {
    let options = [];
    jobs.map((job) => {
      let include = false;
      if (type == "all") {
        include = true;
      } else if (type == "is_active") {
        include = job.status == "accepted" || job.status == "pending";
      } else if (type == "billing") {
        include =
          job.status == "billing_ready" ||
          job.status == "invoiced" ||
          job.status == "final_charges_requested";
      } else {
        include = job.status == type;
      }

      if (include) {
        if (job.job_containers != null && job.job_containers.length > 0) {
          job.job_containers.forEach((jc) => {
            options.push({
              id: job.id,
              label: `Job: ${job.ref_number} Load# ${jc.load_number} Container# ${jc.container.number}`,
            });
          });
        } else {
          options.push({
            id: job.id,
            label: `Job: ${job.ref_number} Load# n/a Container# n/a`,
          });
        }
      }
    });
    return options;
  }

  searchCallback(items) {
    console.log(items);
    if (items != null && items.length > 0) {
      this.filter(this.state.filter, items, null, this.state.activeWork, false);
    } else {
      console.log("Nothing Found");
      this.filter(this.state.filter, null, null, this.state.activeWork, false);
    }
  }

  requiredAccessLevels() {
    return ["super_user", "standard_user", "billing_user", "hr_user"];
  }

  getTerminalOptions(terminals) {
    let options = [<MenuItem value={null}>All Terminals</MenuItem>];
    terminals.map((t) => {
      options.push(<MenuItem value={t.name}>{t.name}</MenuItem>);
    });
    return options;
  }

  render() {
    return (
      <SecuredView
        accessLevels={[
          "super_user",
          "standard_user",
          "billing_user",
          "hr_user",
        ]}
      >
        <NewOrderForm
          job={this.state.currentJob}
          visible={this.state.addJobFormVisible}
          onLoading={this.showProgressOverlay}
          onClose={this.modalCloseCallback}
        />
        <NewContainerForm
          visible={this.state.addContainerFormVisible}
          onClose={this.modalCloseCallback}
          onLoading={this.showProgressOverlay}
          job={this.state.currentJob}
        />
        <SortBar
          currentPage={this.state.pageData.currPage}
          onPage={this.state.showLoadsOnly ? null : this.onNewPage}
          maxPages={this.state.pageData.maxPage}
          initialFilter={"is_active"}
          filterOptions={[
            "is_active",
            "accepted",
            "pending",
            "billing",
            "billing_ready",
            "final_charges_requested",
            "invoiced",
            "canceled",
            "closed",
          ]}
          onFilter={(filterValue) =>
            this.filter(filterValue, null, null, null, true)
          }
          searchLabel={
            "Search Jobs and Loads by Load #, Ref # Container # or Customer Name"
          }
          searchItems={this.getSearchOptions(
            this.state.activeWork,
            this.state.filter
          )}
          onSearchFound={this.searchCallback}
          onSearchNotFound={this.search}
        >
          <SearchBarThird>
            <Button
              variant="outlined"
              onClick={() => {
                this.setState({ dispatchListVisible: true });
              }}
            >
              View Dispacthes
            </Button>
          </SearchBarThird>
          <SearchBarThird>
            <span>Show Loads Only</span>
            <Checkbox
              value={this.state.showLoadsOnly}
              onChange={(e) => {
                this.setState({ showLoadsOnly: e.target.checked });
                this.filter(
                  this.state.filter,
                  null,
                  null,
                  this.state.activeWork,
                  false
                );
              }}
            />
          </SearchBarThird>
        </SortBar>
        <div
          style={{
            margin: "0 5%",
            display: this.state.showLoadsOnly ? "block" : "none",
          }}
        >
          <FormControl
            style={{ minWidth: "200px", margin: "0 5%", padding: "1%" }}
          >
            <InputLabel id="customer-select-label">Select Terminal</InputLabel>
            <Select
              style={{ margin: "0 1%" }}
              value={this.state.terminal}
              onChange={(e) => {
                this.filter(this.state.filter, null, e.target.value);
              }}
            >
              {this.getTerminalOptions(this.state.terminalOptions)}
            </Select>
          </FormControl>
          <FormControl style={{ minWidth: "100px", margin: "0 5%" }}>
            <InputLabel id="customer-select-label">Sort By</InputLabel>
            <Select
              value={this.state.sortBy}
              onChange={(e) => {
                this.sort(e.target.value, this.state.sortOrder);
              }}
            >
              <MenuItem value={"load"} selected={true}>
                Load #
              </MenuItem>
              <MenuItem value={"status"} selected={true}>
                Job Status
              </MenuItem>
              <MenuItem value={"last_free_day"} selected={true}>
                LFD
              </MenuItem>
              <MenuItem value={"eta"}>ETA</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ minWidth: "100px", margin: "0 5%" }}>
            <InputLabel id="customer-select-label">Order</InputLabel>
            <Select
              value={this.state.sortOrder}
              onChange={(e) => {
                this.sort(this.state.sortBy, e.target.value);
              }}
            >
              <MenuItem value={"asc"} selected={true}>
                Ascending
              </MenuItem>
              <MenuItem value={"desc"}>Descending</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={() => {
              this.state.copyList.length > 0
                ? navigator.clipboard.writeText(this.state.copyList.join("\n"))
                : null;
            }}
          >
            {this.state.copyList.length > 0
              ? `Copy ${this.state.copyList.length} Container Numbers`
              : "Nothing to Copy"}
          </Button>
        </div>
        <Table>
          {!this.state.showLoadsOnly ? (
            <TR>
              <TH>Ref #</TH>
              <TH>Customer</TH>
              <TH>Status</TH>
              <TH>Loads</TH>
              <TH>
                <AddCircleIcon
                  sx={{ fontSize: 30 }}
                  onClick={() => this.openAddJobForm()}
                />
              </TH>
            </TR>
          ) : (
            <TR>
              <TH>Load #</TH>
              <TH>Customer</TH>
              <TH>Job Status</TH>
              <TH>Type</TH>
              <TH>Container</TH>
              <TH>Container Status</TH>
              <TH>Container Location</TH>
              <TH>LFD</TH>
              <TH>ETA</TH>
              <TH>SSL</TH>
              <TH>Pickup</TH>
              <TH>Return</TH>
              <TH>Delivery Location</TH>
              <TH>Scheduled For</TH>
              <TH style={{ minWidth: "100px" }}>Link</TH>
              <TH>Logs</TH>
            </TR>
          )}
          {!this.state.showLoadsOnly
            ? this.getRows(this.state.activeWorkFiltered)
            : this.getLoads(this.state.loadsFiltered)}
        </Table>
        <DispatchList
          visible={this.state.dispatchListVisible}
          onClose={this.closeDispatchListView}
        />
        <Container
          currentUser={this.state.currentUser}
          job={this.state.currentJob}
          jobContainer={this.state.currentJobContainer}
          // schedules={this.getContainerSchedules(
          //   this.state.currentJobContainer,
          //   this.state.currentJob
          // )}
          onLoadingComplete={this.dismissPogressOverlay}
          onLoading={this.showProgressOverlay}
          onClose={this.modalCloseCallback}
        />
        {this.state.currentJob != null ? (
          <LogViews
            jobRef={this.state.currentJob.ref_number}
            logs={
              this.state.currentJob.logs != null
                ? this.state.currentJob.logs
                : []
            }
            visible={this.state.logViewVisible}
            onClose={this.closeLogView}
          />
        ) : null}
      </SecuredView>
    );
  }
}

function mapStateToProps(state) {
  return {
    // alerts: state.alerts.alerts,
    // currentUser: state.user.user,
    // waitingFor: state.busy.waitingFor
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // addAlert: alert => dispatch(addAlert(alert)),
    // removeAlert: alert => dispatch(removeAlert(alert)),
    // setUser: user => dispatch(setUser(user)),
    dismissPogressOverlay: (identifier) =>
      dispatch(dismissProgressOverlay(identifier)),
    displayProgressOverlay: (identifier) =>
      dispatch(displayProgressOverlay(identifier)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
