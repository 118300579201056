const token = document.querySelector("[name=csrf-token]").content;

export var createNote = (note, onSuccess, onError) => {
    return fetch(`/api/v1/notes`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      method: 'POST',
      body: JSON.stringify(note)
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var updateNote = (note, onSuccess, onError) => {
    return fetch(`/api/v1/notes`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      method: 'PUT',
      body: JSON.stringify(note)
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var deleteNote = (note, onSuccess, onError) => {
    return fetch(`/api/v1/notes`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      method: 'DELETE',
      body: JSON.stringify(note)
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };