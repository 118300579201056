export const findMotiveMatch = (motiveTrucks, number) => {
    let trucks =  motiveTrucks.filter((t) => 
        parseInt(t.vehicle.number) == number
    );
    if (trucks.length > 0) {
        return trucks[0].vehicle;
    } else {
        return null;
    }
}

export const getTrucksWithLocations = (trucks) => {
    let results = []
    
    trucks.map((truck) => {
        if (truck.truck_snapshot && truck.truck_snapshot.location != null) {
            truck.mapLocation = {zoom: 15, lat: parseFloat(truck.truck_snapshot.location.latitude), lng: parseFloat(truck.truck_snapshot.location.longitude)}
            if (!isNaN(truck.mapLocation.lat) && !isNaN(truck.mapLocation.lng)) {
                results.push(truck);
            }
        }
    });

    return results
}