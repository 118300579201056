import React, { useState } from "react";
import { LargeModal, MediumModal, ModalHeader } from "./Modals";
import { getSchedulesBetween } from "../api/jobs";
import { ListContainer, ScheduleItem } from "./styled/Schedules";
import { getStatusColor } from "./forms/Options";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Stack, TextField } from "@mui/material";
import moment from "moment";
import { titlize } from "../util/functions";
export default (props) => {
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState(moment());
  const [schedules, setSchedules] = useState([]);
  const [localSchedules, setLocalSchedules] = useState([]);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (props.visible) {
      getSchedules(date);
    }

    setVisible(props.visible);
  }, [props.visible]);

  const getSchedules = (day) => {
    setLoading(true);
    let options = {
      start: day.clone().startOf("day").unix(),
      end: day.clone().endOf("day").unix(),
    };
    getSchedulesBetween(options, (results) => {
      let deliveries = results.filter((s) => s.is_delivery_location);
      let local = results.filter((s) => !s.is_delivery_location);
      console.log(deliveries);
      console.log(local);
      setSchedules(deliveries);
      setLocalSchedules(local);
      setLoading(false);
    });
  };

  const getScheduleRows = (schedules) => {
    let index = 1;

    let sorted = schedules
      .sort(function (a, b) {
        return new Date(a.appointment_time) - new Date(b.appointment_time);
      })
      .filter((s) => s != null);

    return sorted.map((schedule) => {
      let row = (
        <ScheduleItem>
          <tr
            style={{
              backgroundColor: getStatusColor(schedule.status),
              fontWeight: "bold",
            }}
          >
            <td rowSpan={3} style={{ fontWeight: "bold" }}>
              {index}
            </td>
            <td colSpan={2}>{`#${schedule.job_container.load_number} ${
              schedule.pickup_location.name
            } -> ${
              schedule.is_delivery_location
                ? `${schedule.drop_location.city},${schedule.drop_location.state}`
                : schedule.drop_location.name
            }`}</td>
          </tr>
          <tr style={{ backgroundColor: getStatusColor(schedule.status) }}>
            <td>
              {new Date(schedule.appointment_time).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
              })}
            </td>
            <td>{titlize(schedule.status)}</td>
          </tr>
          <tr style={{ backgroundColor: getStatusColor(schedule.status) }}>
            <td>
              {schedule.driver
                ? `${schedule.driver.first_name} ${schedule.driver.last_name}`
                : "No Driver"}
            </td>
            <td>{`Trk: ${schedule.truck ? schedule.truck.number : "--"} Ch:${
              schedule.chassis ? schedule.chassis.number : "--"
            }`}</td>
          </tr>
        </ScheduleItem>
      );
      index++;
      return row;
    });
  };

  const getScheduleUI = (schedules, is_deliveries) => {
    return (
      <ListContainer>
        <span style={{ fontSize: "2rem", fontWeight: "bold" }}>
          {is_deliveries ? "Deliveries" : "Local Moves"}
        </span>
        <table width={"100%"} style={{ borderCollapse: "collapse" }}>
          <tbody>{getScheduleRows(schedules)}</tbody>
        </table>
      </ListContainer>
    );
  };

  return (
    <MediumModal
      visible={visible}
      loading={loading ? "schedules" : null}
      closeCallback={props.onClose}
    >
      <ModalHeader>
        <span style={{ float: "left" }}>Schedules</span>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              label="View Day"
              inputFormat="MM/DD/YYYY"
              value={date}
              onChange={(v) => {
                console.log(v.toLocaleString());
                setDate(v);
                getSchedules(v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    svg: { color: "#fff" },
                    input: { color: "#fff" },
                    marginLeft: "5%",
                    float: "left",
                    width: "30%",
                    color: "white",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                      "&.Mui-focused": {
                        color: "white",
                      },
                    },
                  }}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </ModalHeader>
      {schedules.length > 0 ? getScheduleUI(schedules, true) : "No Deliveries"}
      {localSchedules.length > 0
        ? getScheduleUI(localSchedules, false)
        : "No Local Moves"}
    </MediumModal>
  );
};
