import React from 'react'
import { Button, FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import { useState } from "react";
import { ModalHeader, SmallModal } from "../Modals";
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: "90%",
    },
    addIcon: {
        margin: "1%"
    },
    button: {
        float: 'right',
        margin: theme.spacing(2),
        minWidth: "25%",
        height: "100%"
      },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  }));


export default (props) => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const classes = useStyles();

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return(
        <SmallModal visible={true} zIndex={"100"}>
            <ModalHeader>Login</ModalHeader>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Email</InputLabel>
                <Input type={"text"} value={email} onChange={(e) => {setEmail(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <Input
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => {setPassword(e.target.value)}}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            <Button className={classes.button} variant="contained" onClick={() => props.onSubmit(email, password)}>Login</Button>
        </SmallModal>
    )
}