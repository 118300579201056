import styled from 'styled-components';

export const InfoBoxContainer = styled.div`
    display: inline-block;
    width: 100%;
    background: white;
    border-radius: 2rem;
    box-shadow: 8px 5px 5px 0px #aaa;
`

export const InfoBoxTitle = styled.div`
    display: inline-block;
    float: left;
    width: 95%;
    margin: 2.5%;
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
`

export const InfoBoxSubTitle = styled.div`
    display: inline-block;
    float: left;
    width: 95%;
    margin: 2.5%;
    font-size: 1.5rem;
    text-align: center;
`

export const InfoBoxRow = styled.div`
    display: inline-block;
    float: left;
    width: 90%;
    margin: 1% 5%;
    font-size: 1.25rem;
`