import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { MediumModal, ModalHeader, SmallModal } from '../Modals';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { MenuItem, Select, TextField } from '@material-ui/core';
import { createNote, updateNote } from '../../api/notes';
import { titlize } from '../../util/functions';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: "90%",
    },
    addIcon: {
        margin: "1%"
    },
    button: {
        margin: theme.spacing(2),
        minWidth: "25%",
        height: "100%"
      },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  }));

export const NewNoteForm = (props) => {

    const classes = useStyles();
    const [type, setType] = useState();
    const [entry, setEntry] = useState("");
    const [options, setOptions] = useState([]);

    const submit = () => {
        let payload = {id: props.id, type: props.type, sub_type: type, entry: entry}

        if (props.note) {
          payload = props.note;
          payload.sub_type = type;
          payload.entry = entry;

          updateNote(payload, (result) => {
            props.onClose(result);
          });

        } else {
          console.log(payload);

          createNote(payload, (result) => {
              props.onClose(result);
          });
        }
    }

    const setNoteType = (newType) => {
      let options = [];
      let selected = "general";

      switch(newType) {
        case "schedule":
          selected = "dispatch_note";
          options = [
              <MenuItem value="dispatch_note">Dispatch Note</MenuItem>,
              <MenuItem value="trip_note">POD Note</MenuItem>
          ];
          break
        case "customer":
          selected = "general";
          options = [
              <MenuItem value="general">General Note</MenuItem>,
              <MenuItem value="special_contacts">Special Contacts</MenuItem>
          ];
          break
        case "customer_billing":
          selected = "general";
          options = [
              <MenuItem value="general">General Note</MenuItem>,
              <MenuItem value="lane_info">Lane Info</MenuItem>,
              <MenuItem value="contacts">Billing Contacts</MenuItem>
          ];
          break
        case "job":
          selected = "general";
          options = [
              <MenuItem value="general">General Note</MenuItem>,
              <MenuItem value="special_contacts">Special Contacts</MenuItem>
          ];
          break
        case "job_billing":
          selected = "general";
          options = [
              <MenuItem value="general">General Note</MenuItem>,
              <MenuItem value="billing_exception">Billing Exception</MenuItem>
          ];
          break
      }
      setType(selected);
      setOptions(options);
    }

    React.useEffect(() => {

      if (props.note) {
        setType(props.note.sub_type);
        setEntry(props.note.entry);
        setNoteType(props.note.note_type);
      } else {
        setNoteType(props.type);
      }
        
    }, [props.visible, props.id, props.type, props.note])

    return (
        <MediumModal visible={props.visible} closeCallback={props.onClose} zIndex={"100"}>
            <ModalHeader>Add {titlize(props.type)} Note 
            <FormControl style = {{float: "right", margin: "0 5%"}}>
              <InputLabel style={{color: "white"}}>Select Note Type</InputLabel>
              <Select
              style={{color: "white", border: "1px solid white", width: "200px", height: "60px"}}
              value = {type ? type : props.type}
              onChange={(e) => setType(e.target.value)}
              variant="outlined"
              sx={{ 
                color: "white",
                "&:before": {
                  borderColor: "white"
                },
                '.MuiSvgIcon-root ': {
                  fill: "white !important",
                },
                '.MuiOutlinedInput-root': {
                  border: "1px solid white"
                }
              }}
              >
                {options}
              </Select>
            </FormControl>
            </ModalHeader>
            <FormControl className={classes.formControl}>
                <TextField
                id="filled-multiline-flexible"
                label="Note"
                multiline
                rows={10}
                value={entry}
                onChange={(e) => {setEntry(e.target.value)}}
                variant="filled"
                />
            </FormControl>
            <Button className={classes.button} variant="contained" onClick={() => submit()}>Save</Button>
        </MediumModal>
    );
}

export default NewNoteForm;