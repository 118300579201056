import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LargeModal, ModalHeader, SmallModal } from "../Modals";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { customerAll, customerCreate } from "../../api/customers";
import Button from "@material-ui/core/Button";
import { create, updateJob } from "../../api/jobs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Input } from "@material-ui/core";
import { NewCustomerForm } from "./NewCustomerForm";
import NewCustomerContactForm from "./NewCustomerContactForm";
import { verifyFields } from "../../util/functions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: "90%",
  },
  addIcon: {
    margin: "1%",
  },
  button: {
    margin: theme.spacing(2),
    minWidth: "25%",
    height: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

export const NewOrderForm = (props) => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  // Job Params
  const [job, setJob] = useState();
  const [refNumber, setRefNumber] = useState("");
  const [customerId, setCustomerId] = useState(0);
  const [contactId, setContactId] = useState(0);
  const [type, setType] = useState("");
  const classes = useStyles();

  // Customers
  const [customers, setCustomers] = useState([]);
  const [customerFormOpen, setCustomerFormOpen] = useState(false);
  const [customerContactFormOpen, setCustomerContactFormOpen] = useState(false);

  // Current User
  const [currentUser, setCurrentUser] = useState(props.currentUser);

  React.useEffect(() => {
    setCurrentUser(props.currentUser);
    if (props.visible) {
      customerAll((result) => {
        reset();
        setCustomers(result);
        setJob(props.job);
        console.log(props.job);
        if (props.job) {
          setRefNumber(props.job.ref_number);
          setCustomerId(props.job.customer_id);
          setContactId(props.job.contact_id);
          setType(props.job.job_type);
        }
      });
    }
  }, [props.visible, props.job, props.currentUser]);

  const updateCustomer = (event) => {
    setCustomerId(event.target.value);
  };

  const updateContact = (event) => {
    setContactId(event.target.value);
  };

  const updateType = (event) => {
    setType(event.target.value);
  };

  const updateRefNumber = (event) => {
    setRefNumber(event.target.value);
  };

  const getCustomerOptions = (customers) => {
    let options = customers.map((c) => {
      return <MenuItem value={c.id}>{c.name}</MenuItem>;
    });

    return options;
  };

  const getCustomerContactOptions = (customerId) => {
    console.log(customerId);
    let customer = customers.filter((c) => c.id == customerId);
    console.log(customer);
    if (customer != null && customer.length > 0) {
      let options = customer[0].contacts.map((c) => {
        return (
          <MenuItem value={c.id}>
            {c.first_name +
              " " +
              c.last_name +
              (c.is_primary ? " - Primary" : "")}
          </MenuItem>
        );
      });

      return options;
    }
  };

  const submit = () => {
    let payload = {
      ref_number: refNumber,
      customer_id: customerId,
      contact_id: contactId,
      job_type: type,
    };

    let errors = verifyFields(
      ["ref_number", "customer_id", "contact_id", "job_type"],
      payload
    );
    if (errors.length == 0) {
      props.onLoading("newOrderForm");
      if (props.job) {
        payload.id = job.id;
        updateJob(payload, (result) => {
          props.onClose("newOrderForm");
        });
      } else {
        create(payload, (result) => {
          props.onClose("newOrderForm");
        });
      }
    } else {
      console.log(errors);
      setErrors(errors);
    }
  };

  const reset = () => {
    setRefNumber("");
    setCustomerId(null);
    setContactId(null);
    setType("");
  };

  const setNewCustomer = (newCustomer) => {
    console.log(newCustomer);
    let customersCopy = customers;
    customersCopy.push(newCustomer);
    setCustomers(customersCopy);
    setCustomerId(newCustomer.id);
    setCustomerFormOpen(false);
  };

  const setNewCustomerContact = (newContact) => {
    console.log(newContact);
    let customersCopy = customers;
    customersCopy.map((customer) => {
      if (customer.id == customerId) {
        customer.contacts.push(newContact);
      }
    });
    setCustomers(customersCopy);
    setContactId(newContact.id);
    setCustomerContactFormOpen(false);
  };

  return (
    <Fragment>
      <NewCustomerForm visible={customerFormOpen} onClose={setNewCustomer} />
      <NewCustomerContactForm
        visible={customerContactFormOpen}
        onClose={setNewCustomerContact}
        customerId={customerId}
      />
      <SmallModal
        visible={props.visible}
        closeCallback={props.onClose}
        zIndex={"1"}
      >
        <ModalHeader>Create New Job</ModalHeader>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Reference Number</InputLabel>
          <Input
            disabled={
              job != null &&
              currentUser != null &&
              !currentUser.access_levels.includes("super_user")
            }
            error={errors.includes("ref_number")}
            type={"text"}
            value={refNumber}
            onChange={updateRefNumber}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Select Customer</InputLabel>
          <Select
            disabled={job != null}
            error={errors.includes("customer_id")}
            labelId="customer-select"
            id="customer-select"
            value={customerId}
            onChange={updateCustomer}
          >
            {customers.length > 0 ? (
              getCustomerOptions(customers)
            ) : (
              <MenuItem value={0}>Loading</MenuItem>
            )}
          </Select>
          <div>
            <AddCircleIcon
              className={classes.addIcon}
              sx={{ fontSize: 20 }}
              onClick={() => setCustomerFormOpen(true)}
            />
            <span>Add Customer</span>
          </div>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="contact-select-label">Select Contact</InputLabel>
          <Select
            error={errors.includes("contact_id")}
            labelId="contact-select"
            id="contact-select"
            value={contactId}
            onChange={updateContact}
          >
            {customerId != 0 ? (
              getCustomerContactOptions(customerId)
            ) : (
              <MenuItem value={0}>Loading</MenuItem>
            )}
          </Select>
          <div>
            <AddCircleIcon
              className={classes.addIcon}
              sx={{ fontSize: 20 }}
              onClick={() => setCustomerContactFormOpen(true)}
            />
            <span>Add Contact</span>
          </div>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="type-select-label">Select Type</InputLabel>
          <Select
            error={errors.includes("job_type")}
            labelId="type-select"
            id="type-select"
            value={type}
            onChange={updateType}
          >
            <MenuItem value={"shipper_owned"}>Import - Shipper Owned</MenuItem>
            <MenuItem value={"export_shipper_owned"}>
              Export - Shipper Owned
            </MenuItem>
            <MenuItem value={"live_unload"}>Import - Live Unload</MenuItem>
            <MenuItem value={"export_live_load"}>Export - Live Load</MenuItem>
            <MenuItem value={"drop_n_pick"}>Import - Drop N Pick</MenuItem>
            <MenuItem value={"export_drop_n_pick"}>
              Export - Drop N Pick
            </MenuItem>
            <MenuItem value={"export_street_turn"}>
              Export - Street Turn
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => submit()}
        >
          {job ? "Update" : "Create"}
        </Button>
      </SmallModal>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user,
  };
}

export default connect(mapStateToProps)(NewOrderForm);
