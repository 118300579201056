const token = document.querySelector("[name=csrf-token]").content;

export var createTruck = (newTruck, onSuccess, onError) => {
  return fetch(`/api/v1/vehicles`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    method: 'POST',
    body: JSON.stringify(newTruck)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getAllTrucks = (onSuccess, onError, include) => {
  let incString = "?include="
  if (include) {
    include.map((item) => {
      incString = incString.concat(`,${item}`);
    });
  }
  return fetch(`/api/v1/vehicles${incString}`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getAllTrucksOnMotive = (onSuccess, onError) => {
    return fetch(`/api/v1/motive/vehicles`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token },
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (decoded.status_code == 401) {
          if (confirm("Motive Not Authorised redirect now?")) {
            window.location.href = decoded.redirect;
          }
          if (onSuccess != null) onSuccess({vehicles:[]});
        } else {
          if (onSuccess != null) onSuccess(decoded);
        }
      });
  };