const token = document.querySelector("[name=csrf-token]").content;

export var all = (filter, onSuccess, onError) => {
  return fetch(`/api/v1/active_work?filter=${filter}`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getLoadById = (id, onSuccess, onError) => {
  return fetch(`/api/v1/active_work/${id}`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getActiveWork = (pageData, options, onSuccess, onError) => {
  let url = `/api/v1/active_work?page=${pageData.currPage}&limit=${pageData.perPage}`;
  if (options && options.filter) {
    url = `${url}&filter=${options.filter}`
  }
  if (options && options.search) {
    url = `${url}&search=${options.search}`
  }
  return fetch(url, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getJobContainer = (id, onSuccess, onError) => {
  let url = `/api/v1/job_container/${id}`;
  return fetch(url, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};
