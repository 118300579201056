import React, { Fragment } from "react";
import styled from "styled-components";

export const ScheduleItem = styled.div`
  visibility: visible;
  transition: visibility 0s, opacity 1s linear;
  width: 100%;
  display: inline-table;
  border-bottom: 1px solid black;
`;

export const CalendarContainer = styled.div`
  float: left;
  width: 80%;
`;

export const DayContainer = styled.div`
  width: 33%;
  float: left;
  display: block;
  padding-bottom: 33%;
  border: 1px solid black;
  position: relative;
`;

export const ListContainer = styled.div`
  margin: 3%;
  width: 94%;
  height: 500px;
  overflow-y: scroll;
  max-height: -webkit-fill-available;
  &::-webkit-scrollbar {
    width: 10px;
    border: 1px solid black;
    border-radius: 2rem;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 2rem;
    background: gold;
  }
`;

export const SideTitle = styled.div`
  float: left;
  height: 100%;
  color: black;
  font-size: 3rem;
  font-weight: bold;
  text-orientation: upright;
  writing-mode: vertical-lr;
  text-align: center;
  padding: 3%;
`;
