/* Initialization */
import { Client } from "@twilio/conversations";
let instance = null;

class Twilio {
  constructor(token, listeners = []) {
    this.listeners = listeners;
    this.conversations = [];

    if (token) {
      this.client = new Client(token);

      this.client.on("stateChanged", (state) => {
        if (state === "failed") {
          // The client failed to initialize
          console.log("The twilio client failed to initialize");
          return;
        }

        if (state === "initialized") {
          // Use the client
          console.log("The twilio client is initialized");

          this.init_conversitions();

          this.client.on(
            "conversationUpdated",
            ({ conversation, updateReasons }) => {
              // Fired when the attributes or the metadata of a conversation have been updated
              // console.log("conversationUpdated");
              // console.log(conversation);
              // console.log(updateReasons);
              for (let listener of this.listeners) {
                listener(conversation.eventHistory, updateReasons);
              }
            }
          );
        }
      });

      this.send_message = this.send_message.bind(this);
      this.create_conversation = this.create_conversation.bind(this);
    }
  }

  add_listener(listener) {
    this.listeners.push(listener);
  }

  static getInstance(token) {
    return instance || new Twilio(token, []);
  }

  async init_conversitions() {
    // get the conversations paginator
    let conversationsPaginator = await this.client.getSubscribedConversations();

    // get conversations
    this.conversations = conversationsPaginator.items;

    console.log("Init Conversations");
    console.log(this.conversations);

    for (let conversation of this.conversations) {
      conversation.on("messageUpdated", ({ message, updateReasons }) => {
        // Fired when data of a message has been updated.
        console.log("messageUpdated");
        console.log(message);
        console.log(updateReasons);
      });

      conversation.on("participantJoined", (participant) => {
        // fired when a participant has joined the conversation
        console.log("participantJoined");
        console.log(participant);
      });

      // participant.on("updated", ({participant, updateReasons}) => {
      //     // Fired when the fields of the participant have been updated.
      // });
    }
  }

  async getMe(conversation, identifier) {
    const participant = await conversation.getParticipantByIdentity(identifier);
    console.log(participant);
    return participant.state.sid;
  }

  async getConversations() {
    // get the conversations paginator
    let conversationsPaginator = await this.client.getSubscribedConversations();

    // get conversations
    this.conversations = conversationsPaginator.items;

    return this.conversations;
  }

  async join_conversation(conversation) {
    //join the Conversation
    await conversation.join();
  }

  async leave_conversation(conversation) {
    //leave the Conversation
    await conversation.leave();
  }

  async add_participant(conversation, identity) {
    /* Adding Participants (chat and non-chat) */

    // add chat participant to the conversation by its identity
    await conversation.add(identity);
  }

  async get_participants(conversation) {
    return conversation._participantsEntity.participants;
  }

  async get_message_history(conversation) {
    // get the messages paginator the latest 30 messages
    let messagesPaginator = await conversation.getMessages(30);

    console.log("Messages:");
    console.log(messagesPaginator.items);

    // get messages
    return messagesPaginator.items;
  }

  async send_message(conversation, message) {
    // let participants = await conversation.getParticipants();
    // console.log("Participants:");
    // console.log(participants);
    // await conversation.add("chris@fnctrucking.com");
    // let proxyAddress = "+16406009432";
    // await conversation.addNonChatParticipant(proxyAddress, "+13472098582");
    // await this.join_conversation(conversation);
    //send a basic message into the Conversation
    // for (let participant of conversation._participantsEntity.participants) {
    //   console.log(participant);
    //   await participant.remove();
    // }
    await conversation.sendMessage(message);
  }

  async create_conversation(conversationName) {
    /* Creating Conversation */

    // create new conversation, all the parameters are optional
    await this.client.createConversation({
      attributes: {},
      friendlyName: conversationName,
      uniqueName: conversationName,
    });
  }
}

export default Twilio;
