import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ModalHeader, SmallModal } from "../Modals";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { Input, Select, TextField } from "@material-ui/core";
import { Autocomplete, Checkbox } from "@mui/material";
import { getAllContainers } from "../../api/containers";
import { getContainerTypeOptions, getSteamShipLineOptions } from "./Options";
import { getAllLocations } from "../../api/locations";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { addContainer, createAndAddContainer } from "../../api/jobs";
import moment from "moment";
import NewLocationForm from "./NewLocationForm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { exports, imports } from "../../util/constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: "90%",
  },
  addIcon: {
    margin: "1%",
  },
  button: {
    margin: theme.spacing(2),
    minWidth: "25%",
    height: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

export const NewContainerForm = (props) => {
  const [isBacklog, setIsBacklog] = useState(false);
  const [loadNumber, setLoadNumber] = useState(0);
  const [newPickupLocationFormOpen, setNewPickupLocationFormOpen] =
    useState(false);
  const [newDropOffLocationFormOpen, setNewDropOffLocationFormOpen] =
    useState(false);
  const classes = useStyles();
  const [job, setJob] = useState({});
  const [containerNumber, setContainerNumber] = useState("");
  const [containers, setContainers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [pickupLocation, setPickupLocation] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [returnLocation, setReturnLocation] = useState("");
  const [containerFound, setContainerFound] = useState(false);
  const [type, setType] = useState("");
  const [steamShipLine, setSteamShipLine] = useState("");
  const [id, setId] = useState(-1);
  const [eta, setEta] = useState(moment());
  const [lfd, setLfd] = useState(moment());
  const [earliestReceiveDate, setEarliestReceiveDate] = useState(moment());
  const [cuttOffDate, setCuttOffDate] = useState(moment());
  const [freeTimeCutOff, setFreeTimeCutOff] = useState(moment());

  React.useEffect(() => {
    if (props.visible) {
      reset();
      getAllContainers((result) => {
        setContainers(result.filter((c) => c.number != null));
      });
      getAllLocations((result) => {
        setLocations(result);
      });
      setJob(props.job ? props.job : {});
    }
  }, [props.visible, props.job]);

  const reset = () => {
    setContainerNumber("");
    setLoadNumber(0);
    setId(-1);
    setPickupLocation("");
    setDeliveryLocation("");
    setReturnLocation("");
    setContainerFound(false);
    setSteamShipLine("");
    setType("");
    setEta(moment());
    setLfd(moment());
    setEarliestReceiveDate(moment());
    setCuttOffDate(moment());
    setFreeTimeCutOff(moment());
  };

  const updateContainerNumber = (val) => {
    if (val == null) {
      return;
    }
    console.log("Container Number: " + val);
    setContainerNumber(val);

    let container = null;

    containers.map((c) => {
      if (c.number == val) {
        container = c;
      }
    });

    if (container) {
      setType(container.container_type);
      setSteamShipLine(container.ship_line);
      setId(container.id);
    } else {
      setId(-1);
    }

    setContainerFound(container != null);
  };

  const updateType = (event) => {
    setType(event.target.value);
  };

  const updateSSL = (event) => {
    setSteamShipLine(event.target.value);
  };

  const updatePickupLocation = (obj, val) => {
    console.log(val);
    // console.log(event.target.value);
    setPickupLocation(val);
  };

  const updateDeliveryLocation = (obj, val) => {
    setDeliveryLocation(val);
  };

  const updateReturnLocation = (obj, val) => {
    setReturnLocation(val);
  };

  const matchLocation = (name) => {
    console.log("Matching Location: " + name);
    let found = locations.filter((loc) => name.includes(loc.name));
    console.log(found);

    if (found.length == 0) {
      console.error("Unable to find location: " + name);
      return null;
    }

    return found[0].id;
  };

  const onNewPickupFormClose = (newLocation) => {
    setNewPickupLocationFormOpen(false);
    let newLocationList = locations;

    newLocationList.push(newLocation);

    setLocations(newLocationList);

    console.log(newLocation);

    setPickupLocation(newLocation.name);
  };

  const onNewDropOffFormClose = (newLocation) => {
    setNewDropOffLocationFormOpen(false);
    let newLocationList = locations;

    newLocationList.push(newLocation);

    setLocations(newLocationList);

    setReturnLocation(newLocation.name);
  };

  const submit = () => {
    props.onLoading("newContainerForm");

    console.log("Pickup Location Name");
    console.log(pickupLocation);

    let payload = {
      id: job.id,
      container_number: containerNumber,
      container_id: id,
      origin_location_id: matchLocation(pickupLocation),
      delivery_location_id: matchLocation(deliveryLocation),
      return_location_id: matchLocation(returnLocation),
      eta: eta,
      last_free_day: lfd,
    };

    if (isBacklog) {
      payload.load_number = loadNumber;
    }

    payload.free_time_cut_off_date = freeTimeCutOff;

    if (exports.includes(job.job_type)) {
      payload.cut_off_date = cuttOffDate;
      payload.earliest_receive_date = earliestReceiveDate;
    }

    console.log(payload);

    if (containerFound) {
      addContainer(payload, (result) => {
        props.onClose("newContainerForm");
      });
    } else {
      payload.container_type = type;
      payload.number = containerNumber;
      payload.ship_line = steamShipLine;

      createAndAddContainer(payload, (result) => {
        props.onClose("newContainerForm");
      });
    }
  };

  return (
    <Fragment>
      <SmallModal
        visible={props.visible}
        closeCallback={props.onClose}
        zIndex={"100"}
      >
        <ModalHeader>Add Container</ModalHeader>
        {isBacklog ? (
          <FormControl className={classes.formControl}>
            <InputLabel id="customer-select-label">Load Number</InputLabel>
            <Input
              type={"number"}
              value={loadNumber}
              onChange={(e) => {
                setLoadNumber(e.target.value);
              }}
            />
          </FormControl>
        ) : (
          <></>
        )}
        <span style={{ margin: "5%", width: "200px", padding: "0.5%" }}>
          Enter Manual load #?
          <Checkbox
            value={isBacklog}
            onChange={(e) => {
              setIsBacklog(e.target.checked);
            }}
          />
        </span>
        <FormControl className={classes.formControl}>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            value={containerNumber}
            options={containers.map((c) => c.number)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Containers"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                // onInputChange={(e, val) => updateContainerNumber(val + "1")}
              />
            )}
            onSelect={(e) => updateContainerNumber(e.target.value)}
            onChange={(e, val) => updateContainerNumber(val)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Container Type</InputLabel>
          <Select
            labelId="customer-select"
            id="customer-select"
            value={type}
            onChange={updateType}
          >
            {getContainerTypeOptions()}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Steam Shipline</InputLabel>
          <Select
            labelId="customer-select"
            id="customer-select"
            value={steamShipLine}
            onChange={updateSSL}
          >
            {getSteamShipLineOptions()}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Autocomplete
            id="free-solo-2-demo"
            value={pickupLocation}
            options={locations.map((l) => `${l.name} - ${l.city} - ${l.state}`)}
            renderInput={(params) => {
              params.inputProps.autoComplete = "new-password";
              return (
                <TextField
                  {...params}
                  label="Pickup Location"
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: "new-password",
                    type: "search",
                  }}
                />
              );
            }}
            onChange={updatePickupLocation}
          />
          <AddCircleIcon
            className={classes.addIcon}
            sx={{ fontSize: 20 }}
            onClick={() => setNewPickupLocationFormOpen(true)}
          />
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            value={deliveryLocation}
            options={locations.map((l) => `${l.name} - ${l.city} - ${l.state}`)}
            renderInput={(params) => {
              params.inputProps.autoComplete = "new-password";
              return (
                <TextField
                  {...params}
                  label="Delivery Locations"
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: "new-password",
                    type: "search",
                  }}
                />
              );
            }}
            onChange={updateDeliveryLocation}
          />
          <AddCircleIcon
            className={classes.addIcon}
            sx={{ fontSize: 20 }}
            onClick={() => setNewPickupLocationFormOpen(true)}
          />
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            value={returnLocation}
            options={locations.map((l) => `${l.name} - ${l.city} - ${l.state}`)}
            renderInput={(params) => {
              params.inputProps.autoComplete = "new-password";
              return (
                <TextField
                  {...params}
                  label="Return Location"
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: "new-password",
                    type: "search",
                  }}
                />
              );
            }}
            onChange={updateReturnLocation}
          />
          <AddCircleIcon
            className={classes.addIcon}
            sx={{ fontSize: 20 }}
            onClick={() => setNewDropOffLocationFormOpen(true)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              {imports.includes(job.job_type) ? (
                <Fragment>
                  <DesktopDatePicker
                    label="ETA"
                    inputFormat="MM/DD/YYYY"
                    value={eta}
                    onChange={setEta}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DesktopDatePicker
                    label="Last Free Day"
                    inputFormat="MM/DD/YYYY"
                    value={lfd}
                    onChange={setLfd}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DesktopDatePicker
                    label="Free Time Cut-Off"
                    inputFormat="MM/DD/YYYY"
                    value={freeTimeCutOff}
                    onChange={setFreeTimeCutOff}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <DesktopDatePicker
                    label="Earliest Receive Date"
                    inputFormat="MM/DD/YYYY"
                    value={earliestReceiveDate}
                    onChange={setEarliestReceiveDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DesktopDatePicker
                    label="Free Time Cut-Off"
                    inputFormat="MM/DD/YYYY"
                    value={freeTimeCutOff}
                    onChange={setFreeTimeCutOff}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DesktopDatePicker
                    label="Cut-Off Date"
                    inputFormat="MM/DD/YYYY"
                    value={cuttOffDate}
                    onChange={setCuttOffDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Fragment>
              )}
            </Stack>
          </LocalizationProvider>
        </FormControl>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => submit()}
        >
          Create
        </Button>
      </SmallModal>
      <NewLocationForm
        visible={newPickupLocationFormOpen}
        onClose={onNewPickupFormClose}
      />
      <NewLocationForm
        visible={newDropOffLocationFormOpen}
        onClose={onNewDropOffFormClose}
      />
    </Fragment>
  );
};

export default NewContainerForm;
