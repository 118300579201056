import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import App from "../App";

import { Provider } from "react-redux";
import store from "../store";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.NODE_ENV === "production") {
  // console.log = () => {}
  // console.error = () => {}
  // console.debug = () => {}
  console.log("Production Mode");
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
