import { handleError } from "./handler";

const token = document.querySelector("[name=csrf-token]").content;

export var createInvoice = (invoice, onSuccess, onError) => {
  return fetch(`/api/v1/invoices`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(invoice),
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var updateInvoice = (invoice, onSuccess, onError) => {
  return fetch(`/api/v1/invoices/${invoice.id}`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    method: "PUT",
    body: JSON.stringify(invoice),
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var removeInvoiceLineItem = (invoice_id, id, onSuccess, onError) => {
  return fetch(`/api/v1/invoices/${invoice_id}/line-item/${id}`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    method: "DELETE",
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var addLineItem = (lineItem, onSuccess, onError) => {
  return fetch(`/api/v1/invoices/${lineItem.invoiceId}/add`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(lineItem),
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (decoded.status_code == 401) {
        window.location.href = decoded.redirect;
        onSuccess([]);
      }
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getInvoicesForLoad = (jobContainerId, onSuccess, onError) => {
  return fetch(`/api/v1/load/${jobContainerId}/invoices`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getInvoices = (pageData, options, onSuccess, onError) => {
  let url = `/api/v1/invoices?page=${pageData.currPage}&limit=${pageData.perPage}`;
  if (options && options.filter) {
    url = `${url}&filter=${options.filter}&display_type=${options.display_type}`;
  }
  if (options && options.search) {
    url = `${url}&search=${options.search}`;
  }
  return fetch(url, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var addPayment = (payment, onSuccess, onError) => {
  return fetch(`/api/v1/invoices/payment`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(payment),
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var removePayment = (invoiceId, paymentId, onSuccess, onError) => {
  return fetch(`/api/v1/invoices/${invoiceId}/payment/${paymentId}`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    method: "DELETE",
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    })
    .catch((e) => {
      handleError(e);
    });
};

export var deleteInvoice = (invoiceId, onSuccess, onError) => {
  return fetch(`/api/v1/invoices/${invoiceId}`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    method: "DELETE",
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (decoded.status != 200) {
        if (onError) {
          onError(decoded);
        } else {
          handleError(decoded);
        }
      } else {
        if (onSuccess != null) onSuccess(decoded);
      }
    })
    .catch((e) => {
      handleError(e);
    });
};
