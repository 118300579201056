import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BillingAccess } from "../../util/constants";
import CurrentUserInfo from "./CurrentUserInfo";
import NavBar from "./NavBar";
import ChatComponent from "../chat/ChatComponent";

const Bar = styled.div`
  color: blue;
  width: 100%;
  display: inline-block;
  padding: 1%;
`;

export default (props) => {
  return (
    <>
      <CurrentUserInfo currentUser={props.currentUser} />
      <NavBar currentUser={props.currentUser} openTool={props.openTool} />
      {/* <ChatComponent
        twilioToken={props.currentUser ? props.currentUser.twilio_token : null}
        currentUser={props.currentUser}
        onNotification={props.onNotification}
      /> */}
    </>
  );
};
