import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { ProgressOverlay } from "./Components";

export const ModalHeader = styled.span`
  color: white;
  background-color: black;
  width: 100%;
  display: block;
  font-size: 2rem;
  padding: 3%;
`;

const LargeModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border: 1px solid black;
  display: ${(props) => (props.visible ? "block" : "none")};
  border-radius: 1rem;
  background: white;
  overflow-y: scroll;
  max-height: 1000px;
`;

const MediumModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border: 1px solid black;
  display: ${(props) => (props.visible ? "block" : "none")};
  border-radius: 1rem;
  background: white;
  overflow: hidden;
`;

const SmallModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  border: 1px solid black;
  display: ${(props) => (props.visible ? "block" : "none")};
  border-radius: 1rem;
  background: white;
  overflow: hidden;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height 100%;
  display: block;
  background: #0000007a;
  display: ${(props) => (props.visible ? "block" : "none")};
  z-index:  ${(props) => (props.zIndex != null ? props.zIndex : "99")};
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const LargeModal = (props) => {
  const [waitingFor, setWaitingFor] = useState([]);
  const [isVisible, setVisible] = useState(props.visible);

  const showProgressOverlay = (key) => {
    let waitingForTmp = waitingFor;
    waitingForTmp.push(key);

    setTimeout(dismissPogressOverlay, 5000);

    setWaitingFor(waitingForTmp);
  };

  const dismissPogressOverlay = (key) => {
    if (key == null) {
      setWaitingFor([]);
    } else {
      let waitingForTmp = waitingFor.filter((w) => w != key);
      setWaitingFor(waitingForTmp);
    }
  };

  React.useEffect(() => {
    setVisible(props.visible);

    if (props.loading) {
      showProgressOverlay(props.loading);
    } else {
      dismissPogressOverlay();
    }
  }, [props.visible, props.loading]);

  return (
    <ModalBackground visible={isVisible} zIndex={props.zIndex}>
      <LargeModalContainer visible={isVisible}>
        <CloseIconContainer>
          <CloseIcon
            sx={{ fontSize: 30 }}
            style={{ color: "white" }}
            onClick={() => {
              setVisible(false);
              if (props.closeCallback) {
                props.closeCallback();
              }
            }}
          />
        </CloseIconContainer>
        <ProgressOverlay visible={waitingFor.length > 0} />
        {props.children}
      </LargeModalContainer>
    </ModalBackground>
  );
};

export const MediumModal = (props) => {
  const [waitingFor, setWaitingFor] = useState([]);
  const [isVisible, setVisible] = useState(props.visible);

  const showProgressOverlay = (key) => {
    let waitingForTmp = waitingFor;
    waitingForTmp.push(key);

    setTimeout(dismissPogressOverlay, 5000);

    setWaitingFor(waitingForTmp);
  };

  const dismissPogressOverlay = (key) => {
    if (key == null) {
      setWaitingFor([]);
    } else {
      let waitingForTmp = waitingFor.filter((w) => w != key);
      setWaitingFor(waitingForTmp);
    }
  };

  React.useEffect(() => {
    setVisible(props.visible);

    if (props.loading != null) {
      showProgressOverlay(props.loading);
    } else {
      dismissPogressOverlay();
    }
  }, [props.visible, props.loading]);

  return (
    <ModalBackground visible={isVisible} zIndex={props.zIndex}>
      <MediumModalContainer visible={isVisible}>
        <CloseIconContainer>
          <CloseIcon
            sx={{ fontSize: 30 }}
            style={{ color: "white" }}
            onClick={() => {
              setVisible(false);
              if (props.closeCallback) {
                props.closeCallback();
              }
            }}
          />
        </CloseIconContainer>
        <ProgressOverlay visible={waitingFor.length > 0} />
        {props.children}
      </MediumModalContainer>
    </ModalBackground>
  );
};

export const SmallModal = (props) => {
  const [waitingFor, setWaitingFor] = useState([]);
  const [isVisible, setVisible] = useState(props.visible);

  const showProgressOverlay = (key) => {
    let waitingForTmp = waitingFor;
    waitingForTmp.push(key);

    setTimeout(dismissPogressOverlay, 5000);

    setWaitingFor(waitingForTmp);
  };

  const dismissPogressOverlay = (key) => {
    if (key == null) {
      setWaitingFor([]);
    } else {
      let waitingForTmp = waitingFor.filter((w) => w != key);
      setWaitingFor(waitingForTmp);
    }
  };

  React.useEffect(() => {
    setVisible(props.visible);

    if (props.loading) {
      showProgressOverlay(props.loading);
    } else {
      dismissPogressOverlay();
    }
  }, [props.visible, props.loading]);

  return (
    <ModalBackground visible={isVisible} zIndex={props.zIndex}>
      <SmallModalContainer visible={isVisible}>
        <CloseIconContainer>
          <CloseIcon
            sx={{ fontSize: 30 }}
            style={{ color: "white" }}
            onClick={() => {
              setVisible(false);
              if (props.closeCallback) {
                props.closeCallback();
              }
            }}
          />
        </CloseIconContainer>
        <ProgressOverlay visible={waitingFor.length > 0} />
        {props.children}
      </SmallModalContainer>
    </ModalBackground>
  );
};
