import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { LargeModal, ModalHeader, SmallModal } from '../Modals';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { customerAll, customerCreate } from '../../api/customers';
import Button from '@material-ui/core/Button';
import { Input, Select } from '@material-ui/core';
import { MenuItem } from '@mui/material';
import { createDriver } from '../../api/drivers';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: "90%",
    },
    addIcon: {
        margin: "1%"
    },
    button: {
        margin: theme.spacing(2),
        minWidth: "25%",
        height: "100%"
      },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  }));

export const NewDriverForm = (props) => {

    const classes = useStyles();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [driverType, setDriverType] = useState("");


    const submit = () => {
        let payload = {first_name: firstName, last_name: lastName, phone: phone, address1: address1, address2: address2, city: city, state: state, zip: zip, driver_type: driverType}

        console.log(payload);

        createDriver(payload, (result) => {
            props.onClose(result);
        });
    }

    return (
        <SmallModal visible={props.visible} closeCallback={props.onClose} zIndex={"100"}>
            <ModalHeader>Add Driver</ModalHeader>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">First Name</InputLabel>
                <Input type={"text"} value={firstName} onChange={(e) => {setFirstName(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Last Name</InputLabel>
                <Input type={"text"} value={lastName} onChange={(e) => {setLastName(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Phone</InputLabel>
                <Input type={"text"} value={phone} onChange={(e) => {setPhone(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Address #1</InputLabel>
                <Input type={"text"} value={address1} onChange={(e) => {setAddress1(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Address #2</InputLabel>
                <Input type={"text"} value={address2} onChange={(e) => {setAddress2(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">City</InputLabel>
                <Input type={"text"} value={city} onChange={(e) => {setCity(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">State</InputLabel>
                <Input type={"text"} value={state} onChange={(e) => {setState(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Zip</InputLabel>
                <Input type={"text"} value={zip} onChange={(e) => {setZip(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Type</InputLabel>
                <Select
                labelId="customer-select"
                id="customer-select"
                value={driverType}
                onChange={(e) => {setDriverType(e.target.value)}}
                >
                <MenuItem value={"company_driver"}>Company Driver</MenuItem>
                <MenuItem value={"owner_operator"}>Owner Operator</MenuItem>
                <MenuItem value={"non_owner_operator"}>Non Owner Operator</MenuItem>
                </Select>
            </FormControl>
            <Button className={classes.button} variant="contained" onClick={() => submit()}>Create</Button>
        </SmallModal>
    );
}

export default NewDriverForm;