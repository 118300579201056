import { Button, Input, Select } from "@material-ui/core";
import {
  Add,
  Download,
  Edit,
  Map,
  Note,
  PunchClock,
  RemoveCircle,
  Rule,
  Save,
} from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import React, { useState } from "react";
import {
  createInvoice,
  removeInvoiceLineItem,
  updateInvoice,
} from "../api/invoices";
import { copyTable, titlize, toPDF } from "../util/functions";
import {
  Invoice,
  invoiceHeaderInlineStyle,
  invoiceLineItemsInlineStyle,
  invoiceTableInlineStyle,
} from "./Components";
import { getLineItemOptions, getStatusColor } from "./forms/Options";
import { LargeModal, ModalHeader } from "./Modals";
import { ApprovalAccess } from "../util/constants";
import { CheckCircleOutline } from "@material-ui/icons";

export default (props) => {
  const [fuelSurcharge, setFuelSurcharge] = useState(0.35);
  const [invoices, setInvoices] = useState([]);
  const [jobContainer, setJobContainer] = useState({});
  const [job, setJob] = useState({});
  const [currentInvoice, setCurrentInvoice] = useState(null);
  4;
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    setVisible(props.visible);
    setJobContainer(props.jobContainer);
    setInvoices(props.invoices);
    setJob(props.job);
  }, [props.visible, props.invoices, props.jobContainer]);

  const newInvoice = (invoiceType) => {
    createInvoice(
      { job_container_id: jobContainer.id, invoice_type: invoiceType },
      (result) => {
        setInvoices([...invoices, result]);
      }
    );
  };

  const onLineItemAdded = () => {
    console.log("New Line");
    let filtered = invoices.filter((i) => i.id != currentInvoice.id);
    let invoice = { ...currentInvoice };
    invoice.line_items.push({
      id: new Date().getTime(),
      line_item_type: "not_set",
      amount: 0.0,
      quantity: 1,
      discount: null,
    });
    setInvoices([...filtered, invoice]);
    setCurrentInvoice(invoice);
  };

  const onLineItemRemoved = (id) => {
    console.log(`Delete Line: ${id}`);
    let filtered = invoices.filter((i) => i.id != currentInvoice.id);
    let lineItems = currentInvoice.line_items.filter((i) => i.id != id);
    let invoice = { ...currentInvoice, line_items: lineItems };
    setInvoices([...filtered, invoice]);
    setCurrentInvoice(invoice);
    console.log("Removing From Server");
    removeInvoiceLineItem(currentInvoice.id, id, (result) => {
      console.log("Line Item Removed!");
    });
  };

  const onLineItemUpdated = (
    lineItemId,
    field,
    value,
    lineHaulAmount,
    newFuelSurcharge
  ) => {
    console.log(`ID: ${lineItemId} Field: ${field} Val: ${value}`);
    let found = currentInvoice.line_items.filter(
      (li) => li.id == lineItemId
    )[0];
    let removed = currentInvoice.line_items.filter((li) => li.id != lineItemId);
    found[field] = value;
    if (field == "line_item_type" && value == "fuel_surcharge") {
      if (lineHaulAmount == null) {
        alert("Please Add Line Haul before Fuel Surcharge!");
        return;
      }
      if (newFuelSurcharge == null) {
        newFuelSurcharge = fuelSurcharge;
      }
      found["notes"] = `%${newFuelSurcharge * 100} of Line Haul`;
      found["amount"] = newFuelSurcharge * lineHaulAmount;
      console.log(found);
    } else if (field == "line_item_type") {
      switch (value) {
        case "chassis_rental":
          found["amount"] = 40;
          break;
        case "storage":
          found["amount"] = 40;
          break;
        case "pre_pull":
          found["amount"] = 125;
          break;
      }
    }

    found.amount = found.amount * 1.0;
    removed.push(found);
    setCurrentInvoice({ ...currentInvoice, line_items: removed });
  };

  const saveInvoice = (invoice) => {
    // Remove Fake Ids
    let lineItems = invoice.line_items.map((li) => {
      if (!isNaN(li.id)) {
        li.id = null;
      }
      return li;
    });
    invoice.line_items = lineItems;
    console.log(invoice);
    updateInvoice(invoice, (result) => {
      let removed = invoices.filter((i) => i.id != invoice.id);
      removed.push(result);
      setInvoices(removed);
      setCurrentInvoice(null);
    });
  };

  const getInvoices = (invoiceList) => {
    return invoiceList.map((invoice) => {
      if (currentInvoice == null || currentInvoice.id != invoice.id) {
        return (
          <div style={{ float: "left", width: "98%", margin: "1%" }}>
            <Invoice invoice={invoice} jobContainer={jobContainer} job={job} />
            <div style={{ width: "98%", margin: "1%" }}>
              <Button
                onClick={() => copyTable(`invoice-table-${invoice.id}`)}
                startIcon={<Note />}
              >
                Copy Line Items
              </Button>
              <Button
                onClick={() => setCurrentInvoice(invoice)}
                startIcon={<Edit />}
              >
                Edit
              </Button>
              <Button
                startIcon={<Download />}
                onClick={() =>
                  toPDF(
                    `complete-invoice-${invoice.id}`,
                    `${jobContainer.load_number}-${
                      jobContainer.container
                        ? jobContainer.container.number
                        : "na"
                    }-${job.customer.name}-INVOICE${
                      invoice.invoice_type == "per_diem" ? "-PD" : ""
                    }`
                  )
                }
              >
                Download PDF
              </Button>
              {invoice.status == "created" ? (
                <Button
                  onClick={() => {
                    invoice.status = "awaiting_approval";
                    saveInvoice(invoice);
                  }}
                  startIcon={<Rule />}
                >
                  Request Review
                </Button>
              ) : (
                ""
              )}
              {invoice.status == "awaiting_approval" &&
              ApprovalAccess.includes(props.currentUser.access_level) ? (
                <Button
                  onClick={() => {
                    invoice.status = "approved";
                    saveInvoice(invoice);
                  }}
                  startIcon={<CheckCircleOutline />}
                >
                  Approve Invoice
                </Button>
              ) : (
                ""
              )}
              <span
                style={{ color: getStatusColor(invoice.status), margin: "1%" }}
              >
                {titlize(invoice.status)}
              </span>
              <span style={{ margin: "1%" }}>
                Created By: {invoice.created_by}
              </span>
              {invoice.status == "approved" ? (
                <span
                  style={{
                    margin: "1%",
                  }}
                >
                  Approved By: {invoice.approved_by}
                </span>
              ) : (
                ""
              )}
            </div>
            <table
              id={`invoice-table-${invoice.id}`}
              style={invoiceTableInlineStyle}
            >
              <tr>
                <th
                  colSpan={"5"}
                  style={{
                    ...invoiceHeaderInlineStyle,
                    textAlign: "center",
                    fontSize: "2rem",
                    borderRadius: "10px 10px 0 0",
                  }}
                >{`${titlize(invoice.invoice_type)}-Container: ${
                  jobContainer.container ? jobContainer.container.number : "---"
                }`}</th>
              </tr>
              <tr>
                <th style={invoiceHeaderInlineStyle}>Line</th>
                <th style={invoiceHeaderInlineStyle}>Notes</th>
                <th style={invoiceHeaderInlineStyle}>Amount</th>
                <th style={invoiceHeaderInlineStyle}>QTY</th>
                <th style={invoiceHeaderInlineStyle}>Sum</th>
              </tr>
              {invoice.line_items.map((li) => {
                return (
                  <tr>
                    <td style={invoiceLineItemsInlineStyle}>
                      {titlize(li.line_item_type)}
                    </td>
                    <td style={invoiceLineItemsInlineStyle}>{li.notes}</td>
                    <td
                      style={{
                        ...invoiceLineItemsInlineStyle,
                        textAlign: "center",
                      }}
                    >
                      ${li.amount.toFixed(2)}
                    </td>
                    <td
                      style={{
                        ...invoiceLineItemsInlineStyle,
                        textAlign: "center",
                      }}
                    >
                      {li.quantity}
                    </td>
                    <td
                      style={{
                        ...invoiceLineItemsInlineStyle,
                        textAlign: "center",
                      }}
                    >
                      $
                      {li.discount
                        ? `${(
                            li.amount * li.quantity -
                            li.amount * li.quantity * li.discount
                          ).toFixed(2)} (-%${li.discount * 100})`
                        : (li.amount * li.quantity).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
              <tr style={{ backgroundColor: "lightgrey" }}>
                <td style={{ textAlign: "left" }}>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "center" }}>
                  ${invoice.total.toFixed(2)}
                </td>
              </tr>
            </table>
          </div>
        );
      } else {
        let lineHaulCharge = 0.0;
        let newTotal = 0.0;
        return (
          <div style={{ float: "left", width: "98%", margin: "1%" }}>
            <table
              id={`invoice-table-${invoice.id}`}
              style={invoiceTableInlineStyle}
            >
              <tr>
                <th
                  colSpan={"4"}
                  style={{
                    ...invoiceHeaderInlineStyle,
                    textAlign: "center",
                    fontSize: "2rem",
                    borderRadius: "10px 0 0 0",
                  }}
                >{`Edit - ${titlize(invoice.invoice_type)}`}</th>
                <th style={invoiceHeaderInlineStyle}></th>
                <th style={invoiceHeaderInlineStyle}>
                  <Button
                    style={{ color: "white" }}
                    onClick={() => onLineItemAdded()}
                    startIcon={<Add />}
                  >
                    Add Line
                  </Button>
                </th>
                <th
                  style={{
                    ...invoiceHeaderInlineStyle,
                    borderRadius: "0 10px 0 0",
                  }}
                >
                  <Button
                    style={{ color: "white" }}
                    onClick={() => saveInvoice(invoice)}
                    startIcon={<Save />}
                  >
                    Save
                  </Button>
                </th>
              </tr>
              <tr>
                <th style={invoiceHeaderInlineStyle}>Line</th>
                <th style={invoiceHeaderInlineStyle}>Notes</th>
                <th style={invoiceHeaderInlineStyle}>Amount</th>
                <th style={invoiceHeaderInlineStyle}>QTY</th>
                <th style={invoiceHeaderInlineStyle}>Discount</th>
                <th style={invoiceHeaderInlineStyle}>Sum</th>
                <th style={invoiceHeaderInlineStyle}></th>
              </tr>
              {invoice.line_items.map((li) => {
                let isFuelSurcharge = li.line_item_type == "fuel_surcharge";
                if (li.line_item_type == "line_haul") {
                  lineHaulCharge =
                    li.amount * li.quantity -
                    li.amount * li.quantity * li.discount;
                }
                newTotal += li.discount
                  ? li.amount * li.quantity -
                    li.amount * li.quantity * li.discount
                  : li.amount * li.quantity;
                return (
                  <tr>
                    <td style={invoiceLineItemsInlineStyle}>
                      <Select
                        value={li.line_item_type}
                        onChange={(e) => {
                          onLineItemUpdated(
                            li.id,
                            "line_item_type",
                            e.target.value,
                            lineHaulCharge
                          );
                        }}
                      >
                        {getLineItemOptions()}
                      </Select>
                    </td>
                    <td
                      style={{
                        ...invoiceLineItemsInlineStyle,
                        textAlign: "left",
                      }}
                    >
                      <Input
                        disabled={isFuelSurcharge}
                        type="text"
                        value={li.notes}
                        onChange={(e) => {
                          onLineItemUpdated(li.id, "notes", e.target.value);
                        }}
                      />
                    </td>
                    {isFuelSurcharge ? (
                      <td
                        style={{
                          ...invoiceLineItemsInlineStyle,
                          textAlign: "center",
                        }}
                      >
                        Fuel SC %
                        <Input
                          type="number"
                          value={fuelSurcharge * 100}
                          onChange={(e) => {
                            setFuelSurcharge(e.target.value / 100.0),
                              onLineItemUpdated(
                                li.id,
                                "line_item_type",
                                "fuel_surcharge",
                                lineHaulCharge,
                                e.target.value / 100.0
                              );
                          }}
                        />
                      </td>
                    ) : (
                      <td
                        style={{
                          ...invoiceLineItemsInlineStyle,
                          textAlign: "center",
                        }}
                      >
                        <Input
                          disabled={isFuelSurcharge}
                          type="number"
                          value={li.amount}
                          onChange={(e) => {
                            onLineItemUpdated(li.id, "amount", e.target.value);
                          }}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        />
                      </td>
                    )}
                    <td
                      style={{
                        ...invoiceLineItemsInlineStyle,
                        textAlign: "center",
                      }}
                    >
                      <Input
                        disabled={isFuelSurcharge}
                        type="number"
                        value={li.quantity}
                        onChange={(e) => {
                          onLineItemUpdated(li.id, "quantity", e.target.value);
                        }}
                        startAdornment={
                          <InputAdornment position="start">#</InputAdornment>
                        }
                      />
                    </td>
                    <td
                      style={{
                        ...invoiceLineItemsInlineStyle,
                        textAlign: "center",
                      }}
                    >
                      <Input
                        disabled={isFuelSurcharge}
                        type="number"
                        value={li.discount}
                        onChange={(e) => {
                          onLineItemUpdated(li.id, "discount", e.target.value);
                        }}
                        startAdornment={
                          <InputAdornment position="start">%</InputAdornment>
                        }
                      />
                    </td>
                    <td
                      style={{
                        ...invoiceLineItemsInlineStyle,
                        textAlign: "center",
                      }}
                    >
                      {li.discount
                        ? `${(
                            li.amount * li.quantity -
                            li.amount * li.quantity * li.discount
                          ).toFixed(2)} (-%${li.discount * 100})`
                        : (li.amount * li.quantity).toFixed(2)}
                    </td>
                    <td
                      style={{
                        ...invoiceLineItemsInlineStyle,
                        textAlign: "center",
                      }}
                    >
                      <Button
                        startIcon={<RemoveCircle />}
                        onClick={() => {
                          onLineItemRemoved(li.id);
                        }}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                );
              })}
              <tr style={{ backgroundColor: "lightgrey" }}>
                <td style={{ textAlign: "left" }}>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "center" }}>${newTotal.toFixed(2)}</td>
                <td></td>
              </tr>
            </table>
          </div>
        );
      }
    });
  };

  return (
    <LargeModal visible={visible} closeCallback={props.onClose}>
      <ModalHeader style={{ width: "unset" }}>
        <span>
          Load#{jobContainer ? jobContainer.load_number : ""} Invoices
        </span>
        <Button
          startIcon={<Map />}
          style={{ color: "white", float: "right" }}
          variant="outlined"
          onClick={() => newInvoice("drayage")}
        >
          Create Drayage Invoice
        </Button>
        <Button
          startIcon={<PunchClock />}
          style={{ color: "white", float: "right" }}
          variant="outlined"
          onClick={() => newInvoice("per_diem")}
        >
          Create Per-Diem Invoice
        </Button>
      </ModalHeader>
      {jobContainer != null ? getInvoices(invoices) : ""}
    </LargeModal>
  );
};
