import moment from 'moment';
import React, { useState } from 'react';
import { getAllChassisReport, getChassisReport } from '../../api/chassis';
import { LargeModal, ModalHeader } from '../Modals';
import styled from 'styled-components';
import { Table, TD, TH, TR } from '../styled/Tables';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack, TextField } from '@mui/material';
import { Button } from '@material-ui/core';
import { Download, TwoWheelerSharp } from '@mui/icons-material';
import { toPDF } from '../../util/functions';

export default (props) => {

    const ChassisReportHeader = styled.div`
    display: inline-block;
    width: 98%;
    padding: 5%;
    `

    const ChassisReportInfo = styled.div`
        float: left;
        width: 30%;
        padding: 1%;
    `
    const ChassisReportBold = styled.span`
        width: 98%;
        display: inline-block;
        font-weight: bold;
        font-size: 1.5rem;
    `

    const ChassisReportNormal = styled.span`
        width: 98%;
        display: inline-block;
        font-size: 1.5rem;
    `

    const ChassisReportLargeCentered = styled.span`
        width: 98%;
        display: inline-block;
        font-size: 2rem;
        text-align: center;
    `

    const ChassisReportNormalCentered = styled.span`
        width: 98%;
        text-align: center;
        display: inline-block;
        font-size: 1.5rem;
    `

    const ChassisReportBar = styled.span`
        width: 98%;
        display: inline-block;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
    `

    const ChassisReportScheduleTable = styled.table`
        width: 95%;
        marginL 5%;
        border: 1px solid black;
    `
    const ChassisReportScheduleTD = styled.td`
        border: 1px solid black;
        padding: 1%;
        width: 25%;
    `

    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [data, setData] = useState([]);

    React.useEffect(() => {
        
        if (props.visible) {
            getAllChassisReport(startDate.toISOString(), startDate.clone().endOf('month').toISOString(), (results) => {
                setData(results)
            })
        }


    }, [props.visible])

    const reload = (date) => {
        setStartDate(date);
        let start = date.clone().startOf('month').toISOString()
        let end = date.clone().endOf('month').toISOString()
        getAllChassisReport(start, end, (results) => {
            setData(results)
        })
    }

    const getRows = (data) => {
        let maxPerpage = 20;
        let body = [];
        let rows = [[]];
        let set = 0;
        let count = 0;
        data.sort((a,b) => a.chassis_number.localeCompare(b.chassis_number)).map((c) => {
            if (count == maxPerpage) {
                set++;
                rows[set] = [];
                count = 0;
            }
            rows[set].push(<TR>
                <TD>#{c.chassis_number}</TD>
                <TD>{new Date(c.earliest).toDateString()}</TD>
                <TD>{new Date(c.latest).toDateString()}</TD>
                <TD>{c.days_of_use} day(s)</TD>
                <TD>{c.load_numbers.join(", ")}</TD>
            </TR>);
            count++;
        });
        for(let i=0; i < rows.length; i++) {
            body.push(<ChassisReportScheduleTable style={{ margin: "15% 0", width: "100%"}}>
            <TR style={{color: 'white', background: 'black'}}>
                <TH>
                    Chassis #
                </TH>
                <TH>
                    Loaded From
                </TH>
                <TH>
                    Loaded To
                </TH>
                <TH>
                    Days of Use
                </TH>
                <TH>
                    Loads
                </TH>
            </TR>{rows[i].map((r) => r)}</ChassisReportScheduleTable>);
        }

        return body;
    }

    return <LargeModal visible={props.visible} closeCallback={props.onClose}>
        <ModalHeader><span style={{float: 'left'}}>
            Chassis Report: {startDate.format('M-YYYY')}
            </span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DatePicker
                    views={['year', 'month']}
                    label="View Month"
                    inputFormat="MM/YYYY"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e);
                        reload(e);
                      }}
                    renderInput={(params) => <TextField {...params} sx={{
                        svg: { color: '#fff' },
                        input: { color: '#fff' },
                        marginLeft: "5%",
                        float: 'left',
                        width: "30%",
                        color: 'white',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'white',
                            },
                            '&:hover fieldset': {
                              borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'white',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }
                      }} />}
                    />
                </Stack>
            </LocalizationProvider>
            <Button style={{color: "white", float: "left"}} variant="outlined" startIcon={<Download />} onClick={() => toPDF(`all-chassis-report`, `chassis-report-${startDate.format('M-YYYY')}`)}>Download Report</Button>
        </ModalHeader>
        <div id="all-chassis-report" style={{width: "100%", padding: "5%"}}>
            <ChassisReportHeader>
                <ChassisReportInfo>
                    <ChassisReportBold>
                        Chassis Usage Report
                    </ChassisReportBold>
                </ChassisReportInfo>
                <ChassisReportInfo>
                    
                </ChassisReportInfo>
                <ChassisReportInfo>
                    <ChassisReportBold>
                        {startDate.format('MMMM, YYYY')}
                    </ChassisReportBold>
                </ChassisReportInfo>
            </ChassisReportHeader>
            {getRows(data)}
        </div>
    </LargeModal>
}