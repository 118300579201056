import { handleError } from "./handler";

const token = document.querySelector("[name=csrf-token]").content;

export var create = (job, onSuccess, onError) => {
  return fetch(`/api/v1/jobs`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(job)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var updateJob = (job, onSuccess, onError) => {
  return fetch(`/api/v1/jobs`, {
    method: "PUT",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(job)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var setStatus = (job, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/status`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(job)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var setScheduleStatus = (schedule, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/schedule/status`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(schedule)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (decoded.status != 200) {
        if(onError) {
          onError(decoded);
        } else {
          handleError(decoded);
        }
      } else {
        if (onSuccess != null) onSuccess(decoded);
      }
    }).catch((error) => {
      if(onError) {
        onError(error);
      } else {
        handleError(error);
      }
    });
};

export var addSchedule = (schedule, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container/add-schedule`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(schedule)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var updateSchedule = (schedule, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container/schedule/${schedule.id}`, {
    method: "PUT",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(schedule)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var removeSchedule = (schedule, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container/schedule/${schedule.id}`, {
    method: "DELETE",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(schedule)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var setJobContainerStatus = (jobContainer, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container/status`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(jobContainer)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var setJobContainerLocations = (jobContainer, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container/load/locations`, {
    method: "PUT",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(jobContainer)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var setJobContainerLoadStatus = (jobContainer, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container/load/status`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(jobContainer)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var updateJobContainerInfo = (jobContainer, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container`, {
    method: "PUT",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(jobContainer)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var associateContainer = (id, container, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container/${id}/container/${container.id ? container.id : ""}`, {
    method: "PUT",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(container)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var addContainer = (jobContainer, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container/add`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(jobContainer)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var createAndAddContainer = (jobContainer, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container/create`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(jobContainer)
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getSchedulesBetween = (options, onSuccess, onError) => {
  return fetch(`/api/v1/schedules/?start=${options.start}&end=${options.end}`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var setDriverFee = (scheduleId, amount, onSuccess, onError) => {
  return fetch(`/api/v1/jobs/job-container/schedule/${scheduleId}/driver-fee`, {
    method: "POST",
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify({amount: amount})
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};