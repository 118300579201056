import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { LargeModal, ModalHeader, SmallModal } from '../Modals';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { customerContactCreate } from '../../api/customers';
import Button from '@material-ui/core/Button';
import { Checkbox, Input, Select, TextField } from '@material-ui/core';
import { Autocomplete, FormControlLabel, FormGroup } from '@mui/material';
import { getAllContainers } from '../../api/containers';
import { getContainerTypeOptions, getSteamShipLineOptions } from './Options';
import { getAllLocations } from '../../api/locations';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { addContainer, createAndAddContainer } from '../../api/jobs';
import moment from 'moment';
import { createTruck } from '../../api/trucks';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: "90%",
    },
    addIcon: {
        margin: "1%"
    },
    button: {
        margin: theme.spacing(2),
        minWidth: "25%",
        height: "100%"
      },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  }));

export const NewVehicleForm = (props) => {

    const classes = useStyles();
    const [type, setType] = useState("company_truck");
    const [number, setNumber] = useState();
    const [make, setMake] = useState();
    const [model, setModel] = useState();
    const [vin, setVin] = useState();
    const [plate, setPlate] = useState();
    const [eldSerial, setEldSerial] = useState();
    const [ezpass, setEzpass] = useState();
    const [sync, setSync] = useState(false);

    React.useEffect(() => {
    }, [props.visible])

    const submit = () => {

        let payload = {vin: vin, number: number, plate: plate, make: make, model: model, edl_id: eldSerial, ez_pass_id: ezpass, sync_with_motive: sync};

        createTruck(payload, (result) => {
            props.onClose(result);
        });
        
    }

    return (
        <SmallModal visible={props.visible} closeCallback={props.onClose} zIndex={"100"}>
            <ModalHeader>New Vehicle</ModalHeader>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Truck Number</InputLabel>
                <Input type={"number"} value={number} onChange={(e) => {setNumber(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Make</InputLabel>
                <Input type={"text"} value={make} onChange={(e) => {setMake(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Model</InputLabel>
                <Input type={"text"} value={model} onChange={(e) => {setModel(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Plate #</InputLabel>
                <Input type={"text"} value={plate} onChange={(e) => {setPlate(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">VIN #</InputLabel>
                <Input type={"text"} value={vin} onChange={(e) => {setVin(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">ELD Serial #</InputLabel>
                <Input type={"text"} value={eldSerial} onChange={(e) => {setEldSerial(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">EZ Pass Serial #</InputLabel>
                <Input type={"text"} value={ezpass} onChange={(e) => {setEzpass(e.target.value)}}/>
            </FormControl>
            <FormGroup className={classes.formControl}>
                <FormControlLabel control={<Checkbox value={sync} onChange={(e) => {setSync(e.target.checked)}}/>} label="Sync With Motive?" />
            </FormGroup>
            <Button className={classes.button} variant="contained" onClick={() => submit()}>Create</Button>
        </SmallModal>
    );
}

export default NewVehicleForm;