const token = document.querySelector("[name=csrf-token]").content;

export var getTenantInfo = (onSuccess, onError) => {
  return fetch(`/api/v1/tenant`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};