import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { LargeModal, ModalHeader, SmallModal } from '../Modals';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { customerContactCreate, customerContactUpdate } from '../../api/customers';
import Button from '@material-ui/core/Button';
import { Input } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import { verifyFields } from '../../util/functions';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: "90%",
    },
    addIcon: {
        margin: "1%"
    },
    button: {
        margin: theme.spacing(2),
        minWidth: "25%",
        height: "100%"
      },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  }));

export const NewCustomerContactForm = (props) => {
    const [errors, setErrors] = useState([]);
    const classes = useStyles();
    const [customerId, setCustomerId] = useState(0);
    const [firstName, setFirstname] = useState("");
    const [lastName, setLastName] = useState("");
    const [isPrimary, setIsPrimary] = useState(false);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    React.useEffect(() => {
        if (props.contact) {
            let contact = props.contact;
            setFirstname(contact.first_name);
            setLastName(contact.last_name);
            setEmail(contact.email);
            setPhone(contact.phone);
            setIsPrimary(contact.is_primary);
        } else {
            setCustomerId(props.customerId);
        }
    }, [props.visible, props.client])

    const updateFirstName = (event) => {
        setFirstname(event.target.value);
    }

    const updateLastName = (event) => {
        setLastName(event.target.value);
    }

    const updateIsPrimary = (event) => {
        setIsPrimary(event.target.checked);
    }


    const updatePhone = (event) => {
        setPhone(event.target.value);
    }

    const updateEmail = (event) => {
        setEmail(event.target.value);
    }


    const submit = () => {
        let payload = {"customer_id": customerId, "first_name": firstName, "last_name": lastName, "is_primary": isPrimary, "phone": phone, "email": email};
        let errors = verifyFields(["first_name", "last_name", "phone", "email"], payload);

        if (props.contact) {
            payload.id = props.contact.id;
            if (errors.length == 0) {
                customerContactUpdate(payload, (result) => {
                    props.onClose(result);
                });
            } else {
                setErrors(errors);
            }
        } else {
            if (errors.length == 0) {
                customerContactCreate(payload, (result) => {
                    props.onClose(result);
                });
            } else {
                setErrors(errors);
            }
        }
    }

    return (
        <SmallModal visible={props.visible} closeCallback={props.onClose} zIndex={"100"}>
            <ModalHeader>Add Customer Contact</ModalHeader>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">First Name</InputLabel>
                <Input error = {errors.includes("first_name")} type={"text"} value={firstName} onChange={updateFirstName}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Last Name</InputLabel>
                <Input error = {errors.includes("last_name")} type={"text"} value={lastName} onChange={updateLastName}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Email</InputLabel>
                <Input error = {errors.includes("email")} type={"text"} value={email} onChange={updateEmail}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Phone</InputLabel>
                <Input error = {errors.includes("phone")} type={"text"} value={phone} onChange={updatePhone}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Primary Contact?</InputLabel>
                <Checkbox checked={isPrimary} onChange={updateIsPrimary}/>
            </FormControl>
            <Button className={classes.button} variant="contained" onClick={() => submit()}>Create</Button>
        </SmallModal>
    );
}

export default NewCustomerContactForm;