import { verify } from "crypto";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const titlize = (str) => {
  if (typeof str != 'string') {
    return 'error';
  }
  str = str.replaceAll("-", " ").replaceAll("_", " ");

  var arr = str.split(" ");

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  var str2 = arr.join(" ");

  return str2;
};

export const verifyFields = (fields, payload) => {
  let errors = [];
  fields.forEach(element => {
    if (payload[element] == null || payload[element] == "" || payload[element] <= 0) {
     errors.push(element)
    }
  });
  return errors;
}

export const copyTable = (id) => {
  const elTable = document.getElementById(id);
  const blob = new Blob([elTable.outerHTML], {type: 'text/html'});
  navigator.permissions.query({name: "clipboard-write"}).then((result) => {
    if (result.state === "granted" || result.state === "prompt") {
      console.log("Permissions Granted");
      const clipboardItem = new window.ClipboardItem({ 'text/html': blob });
      navigator.clipboard.write([clipboardItem]);
    } else {
      console.log("Permissions NOT Granted");
    }
  });
}

export const toPDF = (id, filename) => {
  html2canvas(document.querySelector(`#${id}`),{
    quality: 1,
    onclone: function (clonedDoc) {
        clonedDoc.getElementById(id).style.display = 'block';
    }
}).then(canvas => {
  var imgData = canvas.toDataURL('image/png');
  var imgWidth = 210; 
  var pageHeight = 295;  
  var imgHeight = canvas.height * imgWidth / canvas.width;
  var heightLeft = imgHeight;
  var doc = new jsPDF('p', 'mm');
  var position = 0;

  doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight + 200;
    doc.addPage();
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }
  doc.save( `${filename}.pdf`);
  //   var wid = canvas.width;
  //   var hgt = canvas.height;
  //   var hratio = hgt/wid;
  //   const imgData = canvas.toDataURL('image/png');

  //   const pdf = new jsPDF({orientation: 'p', format: 'letter'});
  //   var width = pdf.internal.pageSize.getWidth();
  //   var height = pdf.internal.pageSize.getHeight() * hratio;
  //   pdf.addImage(imgData, 'PNG', 0, 0, width, height);
  //   pdf.save(`${filename}.pdf`); 
  });

}