import { Select } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import moment from "moment";
import React, { Fragment } from "react";
import styled from "styled-components";
import { getAllChassis } from "../api/chassis";
import { getSchedulesBetween } from "../api/jobs";
import { getAllLocations } from "../api/locations";
import { getAllTrucks, getAllTrucksOnMotive } from "../api/trucks";
import { getStatusColor } from "../components/forms/Options";
import MultiMapView from "../components/MultiMapView";
import { getChassissWithLocations } from "../helpers/chassis";
import { getTrucksWithLocations } from "../helpers/trucks";
import { titlize } from "../util/functions";
import SecuredView from "./SecuredView";

const ScheduleItem = styled.div`
  visibility: visible;
  transition: visibility 0s, opacity 1s linear;
  width: 100%;
  display: inline-table;
  border-bottom: 1px solid black;
`;

const CalendarContainer = styled.div`
  float: left;
  width: 80%;
`;

const DayContainer = styled.div`
  width: 33%;
  float: left;
  display: block;
  padding-bottom: 33%;
  border: 1px solid black;
  position: relative;
`;

const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
  max-height: -webkit-fill-available;
  &::-webkit-scrollbar {
    width: 10px;
    border: 1px solid black;
    border-radius: 2rem;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 2rem;
    background: gold;
  }
`;

const SideTitle = styled.div`
  float: left;
  height: 100%;
  color: black;
  font-size: 3rem;
  font-weight: bold;
  text-orientation: upright;
  writing-mode: vertical-lr;
  text-align: center;
  padding: 3%;
`;

class MoveOverviewStatic extends React.Component {
  constructor(props) {
    super(props);
    let dates = [];
    let startDate = moment().startOf("day");
    let endDate = moment().startOf("day").add(6, "days").endOf("day");

    dates.push(startDate);
    [1, 2, 3, 4, 5].forEach((num) => {
      dates.push(moment().startOf("day").add(num, "days"));
    });
    dates.push(endDate);
    this.state = {
      dates: dates,
      locations: [],
      schedules: [],
      chassis: [],
      trucks: [],
      motiveTrucks: [],
      startIndex: 0,
      currentView: "deliveries",
      numCycles: 0,
    };
    this.handleMessage = this.handleMessage.bind(this);
    this.reload = this.reload.bind(this);
  }

  requiredAccessLevels() {
    return ["super_user", "standard_user"];
  }

  handleMessage(message) {
    console.log(message);
    this.reload();
  }

  async reload() {
    let dates = [];
    let startDate = moment().startOf("day");
    let endDate = moment().startOf("day").add(6, "days").endOf("day");

    dates.push(startDate);
    [1, 2, 3, 4, 5].forEach((num) => {
      dates.push(moment().startOf("day").add(num, "days"));
    });

    dates.push(endDate);

    await getSchedulesBetween(
      { start: dates[0].valueOf() / 1000, end: dates[6].valueOf() / 1000 },
      (results) => {
        this.setState({
          schedules: results,
          dates: dates,
        });
      }
    );

    await getAllTrucks(
      (result) => {
        this.setState({ trucks: result });
      },
      null,
      ["location"]
    );

    await getAllChassis(
      (result) => {
        this.setState({ chassis: result });
      },
      null,
      ["location"]
    );

    await getAllLocations((result) => {
      this.setState({
        locations: result.filter((location) => location.geo_fence != null),
      });
    });
  }

  componentDidMount() {
    getSchedulesBetween(
      {
        start: this.state.dates[0].valueOf() / 1000,
        end: this.state.dates[6].valueOf() / 1000,
      },
      (results) => {
        this.setState({
          schedules: results,
        });
      }
    );

    getAllTrucks(
      (result) => {
        this.setState({ trucks: result });
      },
      null,
      ["location"]
    );

    getAllChassis(
      (result) => {
        this.setState({ chassis: result });
      },
      null,
      ["location"]
    );

    getAllLocations((result) => {
      this.setState({
        locations: result.filter((location) => location.geo_fence != null),
      });
    });

    window.setInterval(this.reload, 60000);
  }

  getSeheduleRows(schedules) {
    let index = 1;

    let sorted = schedules
      .sort(function (a, b) {
        return new Date(a.appointment_time) - new Date(b.appointment_time);
      })
      .filter((s) => s != null);

    return sorted.map((schedule) => {
      let row = (
        <ScheduleItem>
          <tr
            style={{
              backgroundColor: getStatusColor(schedule.status),
              fontWeight: "bold",
            }}
          >
            <td rowSpan={4} style={{ fontWeight: "bold" }}>
              {index}
            </td>
            <td colSpan={2}>{`#${schedule.job_container.load_number} ${
              schedule.pickup_location.name
            } -> ${
              schedule.is_delivery_location
                ? `${schedule.drop_location.city},${schedule.drop_location.state}`
                : schedule.drop_location.name
            }`}</td>
          </tr>
          {schedule.container ? (
            <Fragment>
              <tr style={{ backgroundColor: getStatusColor(schedule.status) }}>
                <td>
                  {schedule.container.number}-
                  {titlize(schedule.container.container_type)}
                </td>
                <td>{schedule.container.ship_line.toUpperCase()}</td>
              </tr>
              <tr style={{ backgroundColor: getStatusColor(schedule.status) }}>
                <td>
                  {new Date(schedule.appointment_time).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </td>
                <td>{titlize(schedule.status)}</td>
              </tr>
            </Fragment>
          ) : (
            <tr style={{ backgroundColor: getStatusColor(schedule.status) }}>
              <td colSpan={2}>NO CONTAINER</td>
            </tr>
          )}
          <tr style={{ backgroundColor: getStatusColor(schedule.status) }}>
            <td>
              {schedule.driver
                ? `${schedule.driver.first_name} ${schedule.driver.last_name}`
                : "No Driver"}
            </td>
            <td>{`Trk: ${schedule.truck ? schedule.truck.number : "--"} Ch:${
              schedule.chassis ? schedule.chassis.number : "--"
            }`}</td>
          </tr>
        </ScheduleItem>
      );
      index++;
      return row;
    });
  }

  getDayContainers(dates, schedules, startIndex, currentView) {
    let index = 0;
    let containers = dates.map((date) => {
      let matchedSchedules = schedules
        .map((schedule) => {
          let appt_time = moment(schedule.appointment_time);
          if (
            appt_time.isAfter(date.startOf("day")) &&
            appt_time.isBefore(date.endOf("day"))
          ) {
            return schedule;
          }
        })
        .filter((s) => s != null);

      let filtered = matchedSchedules.filter((s) => {
        if (currentView == "deliveries") {
          return s.is_delivery_location;
        } else {
          return !s.is_delivery_location;
        }
      });

      console.log(date.weekday());
      if (date.weekday() == 0 || date.weekday() == 6) {
        if (filtered.length == 0) {
          return null;
        }
      }

      index++;

      return (
        <DayContainer>
          <ListContainer>
            <span style={{ fontSize: "2rem", fontWeight: "bold" }}>{`${new Date(
              date.valueOf()
            ).toLocaleString("en-US", {
              weekday: "long",
              month: "short",
              day: "numeric",
            })}`}</span>
            <table width={"100%"} style={{ borderCollapse: "collapse" }}>
              <tbody>{this.getSeheduleRows(filtered)}</tbody>
            </table>
          </ListContainer>
        </DayContainer>
      );
    });

    return containers;
  }

  render() {
    return (
      <SecuredView
        accessLevels={["super_user", "standard_user"]}
        fullScreen={true}
      >
        <div style={{ width: "100%", padding: "1%" }}>
          <Select
            value={this.state.currentView}
            onChange={(e) => {
              this.setState({ currentView: e.target.value });
            }}
          >
            <MenuItem value={"deliveries"}>Deliveries</MenuItem>
            <MenuItem value={"local_moves"}>Local Moves</MenuItem>
          </Select>
        </div>
        <SideTitle>{titlize(this.state.currentView)}</SideTitle>
        <CalendarContainer>
          {this.getDayContainers(
            this.state.dates,
            this.state.schedules,
            this.state.startIndex,
            this.state.currentView
          )}
          <DayContainer>
            <div
              style={{ position: "absolute", width: "100%", height: "100%" }}
            >
              <MultiMapView
                truckInfoList={getTrucksWithLocations(this.state.trucks)}
                chassisList={getChassissWithLocations(this.state.chassis)}
                geoFenceLocations={this.state.locations}
              />
            </div>
          </DayContainer>
        </CalendarContainer>
        <SideTitle>{titlize(this.state.currentView)}</SideTitle>
      </SecuredView>
    );
  }
}

export default MoveOverviewStatic;
