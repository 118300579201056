import React, { Fragment } from "react";
import styled from "styled-components";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SecuredView from "./SecuredView";
import NavBar from "../components/nav_bar";
import { getYardLogs } from "../api/chassis";
import { titlize } from "../util/functions";
import NewDriverForm from "../components/forms/NewDriverForm";
import { createSettlement, getAllDrivers, getDriversSchedulesBetween, getDriversSettlementBetween } from "../api/drivers";
import { Button, Stack, TextField } from "@mui/material";
import { Schedule } from "@mui/icons-material";
import { LargeModal, MediumModal, ModalHeader } from "../components/Modals";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Add, Map, Money } from "@material-ui/icons";
import { DriverPaySchedule } from "../components/Components";
import { HRAccess } from "../util/constants";
import { ComplianceForm } from "../components/forms/ComplianceForm";
import { ComplianceBadge } from "../components/styled/Badges";
import { connect } from "react-redux";

const Table = styled.table`
color: black;
background: #8080802e;
width: 90%;
margin: 5%;
box-shadow: 8px 5px 5px 0px #aaa;
border-radius: 1rem;
overflow: hidden;
& tr:nth-child(odd) {background: #cccccc;}
`;

const TR = styled.tr`
border-bottom: 0.5px solid black
`;

const TH = styled.th`
font-size: 1rem;
font-weight: bold;
padding: 1%;
`;

const TD = styled.td`
width: ${(props) => props.width};
font-weight: ${(props) => props.fontWeight};
padding: 1%;
`;

class Drivers extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        addDriverFormOpen: false,
        drivers: [],
        currentDriver: null,
        scheduleFormVisible: false,
        payoutViewOpen: false,
        selectedDay: moment(),
        schedules: [],
        currentSettlement: null
      };
      this.closeDriverFormCallback = this.closeDriverFormCallback.bind(this);
      this.onScheduelViewClose = this.onScheduelViewClose.bind(this);
      this.onDriverPayViewClose = this.onDriverPayViewClose.bind(this);
      this.onComplianceFormClose = this.onComplianceFormClose.bind(this);
    }
  
    componentDidMount() {
        getAllDrivers((drivers) => {
            this.setState({drivers: drivers});
        }, null, ["compliance_items"]);
    }

    openAddDriverForm() {
        this.setState({
            addDriverFormOpen: true
        });
    }

    closeDriverFormCallback(result) {
        getAllDrivers((drivers) => {
            this.setState({
                addDriverFormOpen: false, drivers: drivers
            });
        });
    }

    getRows(drivers) {
        return drivers.map((t) => {
            let upcomingRenewals = t.compliance_items.filter((c) => c.days_left <= 30 && c.days_left > 15);
            let criticalUpcomingRenewals = t.compliance_items.filter((c) => c.days_left <= 15);
            return <TR>
                <TD>
                    #{t.id}
                </TD>
                <TD>
                    {t.first_name}
                </TD>
                <TD>
                    {t.last_name}
                </TD>
                <TD>
                    {titlize(t.driver_type)}
                </TD>
                <TD>
                    {t.phone}
                </TD>
                <TD>
                    <Button startIcon={<Schedule />} onClick={() => this.openSchedulesView(t)}>View Schedules</Button>
                    {HRAccess.includes(this.props.currentUser.access_level) ? <Button startIcon={<Money />} onClick={() => this.openDriverPayView(t)}>View Payouts</Button> : ""}
                </TD>
                <TD>
                  {upcomingRenewals.length > 0 ? <ComplianceBadge style={{color: "black",backgroundColor: "yellow"}}>{upcomingRenewals.length}</ComplianceBadge> : <></>}
                  {criticalUpcomingRenewals.length > 0 ? <ComplianceBadge>{criticalUpcomingRenewals.length}</ComplianceBadge> : <></>}
                  <Button startIcon={<Add />} onClick={() => this.openComplianceForm(t)}>Add Compliance</Button>
                </TD>
            </TR>
        });
    }

    openSchedulesView(driver) {
        let start = moment();
        start.startOf('week').toISOString();
        let end = moment();
        end.endOf('week').toISOString();
        getDriversSchedulesBetween(driver.id, start, end, (result) => {
            this.setState({currentDriver: {...driver, schedules: result}, scheduleFormVisible: true, schedules: result});
        });
    }

    onScheduelViewClose() {
        this.setState({currentDriver: null, scheduleFormVisible: false});
    }

    openDriverPayView(driver) {
        let start = moment();
        start.startOf('week').toISOString();
        let end = moment();
        end.endOf('week').toISOString();
        getDriversSettlementBetween(driver.id, start, end, (result) => {
            if (result.id != null) {
                this.setState({currentDriver: driver, currentSettlement: result, payoutViewOpen: true, schedules: result.schedules});
            } else {
                getDriversSchedulesBetween(driver.id, start, end, (result) => {
                    this.setState({currentDriver: {...driver, schedules: result}, payoutViewOpen: true, schedules: result});
                });
            }
        });
        
    }

    checkSettlements(date) {
        let start = date.clone().startOf('week').toISOString();
        let end =  date.clone().endOf('week').toISOString();
        getDriversSettlementBetween(this.state.currentDriver.id, start, end, (result) => {
            if (result.id != null) {
                this.setState({currentSettlement: result, payoutViewOpen: true, schedules: result.schedules, selectedDay: date});
            } else {
                getDriversSchedulesBetween(this.state.currentDriver.id, start, end, (result) => {
                    this.setState({currentSettlement: null, payoutViewOpen: true, schedules: result, selectedDay: date});
                });
            }
        });
    }

    checkSchedules(date) {
        let start = date.clone().startOf('week').toISOString();
        let end =  date.clone().endOf('week').toISOString();
        getDriversSchedulesBetween(this.state.currentDriver.id, start, end, (result) => {
            this.setState({currentDriver: {...this.state.currentDriver, schedules: result}, currentSettlement: null, schedules: result, selectedDay: date});
        });
    }

    onDriverPayViewClose() {
        this.setState({currentDriver: null, payoutViewOpen: false});
    }

    newSettlement() {
        createSettlement({
            driver_id: this.state.currentDriver.id, 
            start_date: this.state.selectedDay.clone().startOf('week').toISOString(), 
            end_date: this.state.selectedDay.clone().endOf('week').toISOString(), 
            included_schedules: this.state.schedules.map((s) => s.id)
        }, (result) => {
            this.setState({currentSettlement: result});
        });
    }

    getScheduleRows(driver, date) {
        return driver.schedules.map((s) => {
            let appt = moment(s.appointment_time);
            if (date.endOf("day").isAfter(appt) 
                && date.startOf("day").isBefore(appt) ) {
                    console.log(`${s.job_container.load_number} is True`)
                return <tr>
                    <td>{new Date(s.appointment_time).toLocaleTimeString()}</td>
                    <td>{s.drop_location.name}</td>
                    <td>{s.job_container.load_number}</td>
                    <td>{s.job_container.container.number}</td>
                    <td>
                        <Button href={`/admin-dashboard?load_id=${s.job_container.id}`} startIcon={<Map />}>View Load</Button>
                    </td>
                </tr>
                }
            });
    }

    openComplianceForm(driver) {
        this.setState({complianceFormOpen: true, currentDriver: driver});
    }

    onComplianceFormClose() {
        this.setState({complianceFormOpen: false, currentDriver: null});
    }

    render() {
        return(
            <SecuredView accessLevels={["super_user", "standard_user", "billing_user", "hr_user"]}>
        <Table>
            <TR>
            <TH>ID</TH>
            <TH>First Name</TH>
            <TH style={{minWidth: 75}}>Last Name</TH>
              <TH style={{minWidth: 120}}>Type</TH>
              <TH>Phone</TH>
              <TH><AddCircleIcon sx={{ fontSize: 30 }} onClick={() => this.openAddDriverForm()}/></TH>
              <TH>Compliance</TH>
            </TR>
            {this.getRows(this.state.drivers)}
          </Table>
          <MediumModal visible={this.state.scheduleFormVisible} closeCallback={this.onScheduelViewClose}>
            <ModalHeader>
                <span style={{float: 'left'}}>{this.state.currentDriver ? `${this.state.currentDriver.first_name} ${this.state.currentDriver.last_name} - Moves` : ""}</span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DatePicker
                    label="View Day"
                    inputFormat="MM/DD/YYYY"
                      value={this.state.selectedDay}
                      onChange={(v) => {
                            console.log(v.toLocaleString());
                            this.checkSchedules(v);
                        }}
                    renderInput={(params) => <TextField {...params} sx={{
                        svg: { color: '#fff' },
                        input: { color: '#fff' },
                        marginLeft: "5%",
                        float: 'left',
                        width: "30%",
                        color: 'white',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'white',
                            },
                            '&:hover fieldset': {
                              borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'white',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }
                      }} />}
                    />
                </Stack>
            </LocalizationProvider>
            </ModalHeader>
            <div>
                <Table style={{width: "98%", margin: "1%"}}>
                    <tr>
                        <th>Appt Time</th>
                        <th>Destination</th>
                        <th>Load#</th>
                        <th>Cont#</th>
                        <th>View</th>
                    </tr>
                    {
                        this.state.currentDriver ?  this.getScheduleRows(this.state.currentDriver, this.state.selectedDay) : <></>
                    }
                </Table>
            </div>
          </MediumModal>
          <LargeModal visible={this.state.payoutViewOpen} closeCallback={this.onDriverPayViewClose}>
            <ModalHeader>
                <span style={{float: 'left'}}>{this.state.currentDriver ? `${this.state.currentDriver.first_name} ${this.state.currentDriver.last_name} - Payout` : ""}</span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DatePicker
                    label="View Day"
                    inputFormat="MM/DD/YYYY"
                      value={this.state.selectedDay}
                      onChange={(v) => {
                        console.log(v.toLocaleString());
                        this.checkSettlements(v);}}
                    renderInput={(params) => <TextField {...params} sx={{
                        svg: { color: '#fff' },
                        input: { color: '#fff' },
                        marginLeft: "5%",
                        float: 'left',
                        width: "30%",
                        color: 'white',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'white',
                            },
                            '&:hover fieldset': {
                              borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'white',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }
                      }} />}
                    />
                </Stack>
            </LocalizationProvider>
            {this.state.currentSettlement ? "" : <Button onClick={() => {this.newSettlement()}}>Create Settlement</Button>}
            </ModalHeader>
            <div style={{overflow: 'scroll', maxHeight: '700px'}}>
                <DriverPaySchedule settlement={this.state.currentSettlement} schedules={this.state.schedules} date={this.state.selectedDay} driver={this.state.currentDriver ? this.state.currentDriver : {}} visible={true}/>
            </div>
          </LargeModal>
          <NewDriverForm visible={this.state.addDriverFormOpen} onClose={this.closeDriverFormCallback} />
          <ComplianceForm visible={this.state.complianceFormOpen} onClose={this.onComplianceFormClose} type="driver" obj={this.state.currentDriver} />
          </SecuredView>)
    }
}

function mapStateToProps(state){
    return{
      currentUser: state.user.user,
    }
  }
 
  
  export default connect(mapStateToProps)(Drivers);