import { Book, Delete, Edit, People, PlusOne } from "@mui/icons-material";
import { Button, MenuItem, Select } from "@mui/material";
import React from "react";
import { getAllLocations } from "../api/locations";
import { ProgressOverlay } from "../components/Components";
import NewLocationForm from "../components/forms/NewLocationForm";
import NavBar from "../components/nav_bar";
import SearchBar from "../components/SearchBar";
import { Table, TR, TH, TD } from "../components/styled/Tables";
import SecuredView from "./SecuredView";
import { customerAll, customerContactsAll } from "../api/customers";
import NewCustomerForm from "../components/forms/NewCustomerForm";
import NewCustomerContactForm from "../components/forms/NewCustomerContactForm";
import { connect } from "react-redux";
import { dismissProgressOverlay, displayProgressOverlay } from "../store/busy";
import {
  License,
  LicenseContainer,
  LicenseInfo,
  LicenseLabel,
  LicenseLogo,
  LicenseRow,
} from "../components/styled/Licenses";
import { getTenantInfo } from "../api/tenant";
import { titlize } from "../util/functions";
import {
  createConversation,
  deleteConversation,
  getConversations,
} from "../api/chat";
import { Input } from "@material-ui/core";
import { getAllDrivers, getDriversSchedulesBetween } from "../api/drivers";
import { map } from "jquery";
import { DeleteForever } from "@material-ui/icons";

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingFor: [],
      currentTab: "locations",
      locations: [],
      clients: [],
      currentCustomer: null,
      contacts: [],
      currentContact: null,
      formOpen: false,
      currentLocation: null,
      currentView: "subscriptions",
      tenant: {},
      conversations: [],
      drivers: [],
      createConvoData: { driver_id: "none" },
    };

    this.onFormClose = this.onFormClose.bind(this);
  }

  setCurrentView(viewName) {
    this.setState({ currentView: viewName });
  }

  showProgressOverlay(key) {
    this.props.displayProgressOverlay({ id: key });
  }

  dismissProgressOverlay(key) {
    this.props.dismissProgressOverlay({ id: key });
  }

  componentDidMount() {
    this.loadData(this.state.currentTab);

    getTenantInfo((result) => {
      this.setState({ tenant: result });
    });
  }

  setTab(tab) {
    this.loadData(tab);
    this.setState({ currentTab: tab });
  }

  loadData(tab) {
    this.showProgressOverlay(tab);
    if (tab == "locations") {
      getAllLocations((results) => {
        this.dismissProgressOverlay(tab);
        this.setState({ locations: results });
      });
    }

    if (tab == "clients") {
      customerAll((results) => {
        this.dismissProgressOverlay(tab);
        this.setState({ clients: results });
      });
    }

    if (tab == "contacts") {
      customerContactsAll((results) => {
        this.dismissProgressOverlay(tab);
        this.setState({ contacts: results });
      });
    }

    if (tab == "chat") {
      getAllDrivers((drivers) => {
        this.setState({ drivers: drivers });
      });
      getConversations((conversations) => {
        this.dismissProgressOverlay(tab);
        console.log("Conversations");
        console.log(conversations);
        this.setState({ conversations: conversations });
      });
    }
  }

  getDriversMenuItems(drivers) {
    return drivers.map((driver) => {
      return (
        <MenuItem value={driver.id}>
          {driver.first_name + " " + driver.last_name}
        </MenuItem>
      );
    });
  }

  getContent(tab, locations, clients, contacts, conversations) {
    let content = [];
    switch (tab) {
      case "locations":
        content = (
          <>
            <TR>
              <TH>Name</TH>
              <TH>Address #1</TH>
              <TH>Address #2</TH>
              <TH>City</TH>
              <TH>State</TH>
              <TH>ZIP</TH>
              <TH>Edit</TH>
            </TR>
            {locations.map((l) => {
              return (
                <TR>
                  <TD>{l.name}</TD>
                  <TD>{l.address_1}</TD>
                  <TD>{l.address_2}</TD>
                  <TD>{l.city}</TD>
                  <TD>{l.state}</TD>
                  <TD>{l.zip}</TD>
                  <TD>
                    <Button
                      startIcon={<Edit />}
                      onClick={() => this.openLocationEditForm(l)}
                    >
                      Edit
                    </Button>
                  </TD>
                </TR>
              );
            })}
          </>
        );
        break;
      case "clients":
        content = (
          <>
            <TR>
              <TH>Name</TH>
              <TH>Address #1</TH>
              <TH>Address #2</TH>
              <TH>City</TH>
              <TH>State</TH>
              <TH>ZIP</TH>
              <TH>Edit</TH>
            </TR>
            {clients.map((c) => {
              return (
                <TR>
                  <TD>{c.name}</TD>
                  <TD>{c.address_1}</TD>
                  <TD>{c.address_2}</TD>
                  <TD>{c.city}</TD>
                  <TD>{c.state}</TD>
                  <TD>{c.zip}</TD>
                  <TD>
                    <Button
                      startIcon={<Edit />}
                      onClick={() => this.openCustomerEditForm(c)}
                    >
                      Edit
                    </Button>
                  </TD>
                </TR>
              );
            })}
          </>
        );
        break;
      case "contacts":
        content = (
          <>
            <TR>
              <TH>First Name</TH>
              <TH>Last Name</TH>
              <TH>Email</TH>
              <TH>Phone</TH>
              <TH></TH>
              <TH>Edit</TH>
            </TR>
            {contacts.map((c) => {
              return (
                <TR>
                  <TD>{c.first_name}</TD>
                  <TD>{c.last_name}</TD>
                  <TD>{c.email}</TD>
                  <TD>{c.phone}</TD>
                  <TD>{c.is_primary ? "Primary" : ""}</TD>
                  <TD>
                    <Button
                      startIcon={<Edit />}
                      onClick={() => this.openContactEditForm(c)}
                    >
                      Edit
                    </Button>
                  </TD>
                </TR>
              );
            })}
          </>
        );
        break;
      case "chat":
        content = (
          <>
            <span>
              <h2>Add Conversation</h2>
              <Select
                value={this.state.createConvoData.driver_id}
                onChange={(e) => {
                  console.log(e);
                  this.setState({
                    createConvoData: { driver_id: e.target.value },
                  });
                }}
              >
                {this.getDriversMenuItems(this.state.drivers)}
              </Select>
              <Button
                onClick={() => {
                  createConversation(this.state.createConvoData, (result) => {
                    let convo_list = this.state.conversations;
                    convo_list.push(result.conversation);
                    this.setState({ conversations: convo_list });
                  });
                }}
              >
                Create
              </Button>
            </span>
            <TR>
              <TH>Name</TH>
              <TH>Participants</TH>
              <TH>Edit</TH>
              <TH>Delete</TH>
            </TR>
            {conversations.map((c) => {
              return (
                <TR>
                  <TD>{c.friendly_name}</TD>
                  <TD>{c.participants.length}</TD>
                  <TD>
                    <Button
                      startIcon={<Edit />}
                      onClick={() => this.openContactEditForm(c)}
                    >
                      Edit
                    </Button>
                  </TD>
                  <TD>
                    <Button
                      startIcon={<DeleteForever />}
                      onClick={() =>
                        deleteConversation(c.sid, () => {
                          let convo_list = this.state.conversations.filter(
                            (convo) => {
                              return convo.sid != c.sid;
                            }
                          );
                          this.setState({ conversations: convo_list });
                        })
                      }
                    >
                      Delete
                    </Button>
                  </TD>
                </TR>
              );
            })}
          </>
        );
    }
    return content;
  }

  openLocationEditForm(location) {
    this.setState({
      formOpen: true,
      currentLocation: location,
    });
  }

  openCustomerEditForm(customer) {
    this.setState({
      formOpen: true,
      currentCustomer: customer,
    });
  }

  openContactEditForm(contact) {
    this.setState({
      formOpen: true,
      currentContact: contact,
    });
  }

  onFormClose(newObject) {
    this.setState({
      formOpen: false,
      currentLocation: null,
    });
    this.loadData(this.state.currentTab);
  }

  getLicenses(licenses) {
    console.log(licenses);
    if (licenses) {
      return licenses.map((license) => {
        return (
          <License>
            <LicenseLogo src={`/assets/logos/${license.type}`} />
            <LicenseRow>
              <LicenseLabel>Status:</LicenseLabel>
              <LicenseInfo>
                {license.is_active ? "Active" : "Expired"}
              </LicenseInfo>
            </LicenseRow>
            <LicenseRow>
              <LicenseLabel>Type:</LicenseLabel>
              <LicenseInfo>{titlize(license.type)}</LicenseInfo>
            </LicenseRow>
            <LicenseRow>
              <LicenseLabel>Expires At:</LicenseLabel>
              <LicenseInfo>
                {new Date(license.expires_at).toDateString()}
              </LicenseInfo>
            </LicenseRow>
            <LicenseRow>
              <LicenseLabel>Days Left:</LicenseLabel>
              <LicenseInfo>
                {(
                  (new Date(license.expires_at) - new Date()) /
                  86400000
                ).toFixed(0)}
              </LicenseInfo>
            </LicenseRow>
            <LicenseRow>
              <LicenseLabel></LicenseLabel>
              <LicenseInfo>
                {license.is_enabled ? (
                  <Button variant="outlined" startIcon={<Delete color="red" />}>
                    Cancel
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<PlusOne color="green" />}
                  >
                    Subscribe
                  </Button>
                )}
              </LicenseInfo>
            </LicenseRow>
          </License>
        );
      });
    } else {
      return <div>No Licenses</div>;
    }
  }

  render() {
    return (
      <SecuredView accessLevels={["super_user"]}>
        <Button
          style={{
            margin: "1%",
            float: "left",
            color:
              this.state.currentView == "subscriptions" ? "white" : "black",
            background:
              this.state.currentView == "subscriptions" ? "black" : "white",
          }}
          startIcon={<Book />}
          onClick={() => this.setCurrentView("subscriptions")}
        >
          Subscriptions
        </Button>
        <Button
          style={{
            margin: "1%",
            float: "left",
            color: this.state.currentView == "assets" ? "white" : "black",
            background: this.state.currentView == "assets" ? "black" : "white",
          }}
          startIcon={<People />}
          onClick={() => this.setCurrentView("assets")}
        >
          Edit Assets
        </Button>

        {this.state.currentView == "assets" ? (
          <>
            <div style={{ margin: "5%" }}>
              <div style={{ float: "left", margin: "1%" }}>
                <Select
                  value={this.state.currentTab}
                  onChange={(e) => {
                    this.setTab(e.target.value);
                  }}
                >
                  <MenuItem value={"locations"}>Locations</MenuItem>
                  <MenuItem value={"clients"}>Clients</MenuItem>
                  <MenuItem value={"contacts"}>Contacts</MenuItem>
                  <MenuItem value={"chat"}>Chat</MenuItem>
                </Select>
              </div>
              <SearchBar items={[]} />
            </div>
            <Table>
              {this.getContent(
                this.state.currentTab,
                this.state.locations,
                this.state.clients,
                this.state.contacts,
                this.state.conversations
              )}
            </Table>
          </>
        ) : (
          <LicenseContainer>
            {this.getLicenses(this.state.tenant.licenses)}
          </LicenseContainer>
        )}
        <NewLocationForm
          visible={this.state.formOpen && this.state.currentLocation != null}
          location={this.state.currentLocation}
          onClose={this.onFormClose}
        />
        <NewCustomerForm
          visible={this.state.formOpen && this.state.currentCustomer != null}
          customer={this.state.currentCustomer}
          onClose={this.onFormClose}
        />
        <NewCustomerContactForm
          visible={this.state.formOpen && this.state.currentContact != null}
          contact={this.state.currentContact}
          onClose={this.onFormClose}
        />
      </SecuredView>
    );
  }
}

function mapStateToProps(state) {
  return {
    // alerts: state.alerts.alerts,
    // currentUser: state.user.user,
    // waitingFor: state.busy.waitingFor
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // addAlert: alert => dispatch(addAlert(alert)),
    // removeAlert: alert => dispatch(removeAlert(alert)),
    // setUser: user => dispatch(setUser(user)),
    dismissProgressOverlay: (identifier) =>
      dispatch(dismissProgressOverlay(identifier)),
    displayProgressOverlay: (identifier) =>
      dispatch(displayProgressOverlay(identifier)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
