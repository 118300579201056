import { createSlice } from '@reduxjs/toolkit'
// Slice
const slice = createSlice({
  name: 'alerts',
  initialState: {
    alerts: [],
  },
  reducers: {
    createAlert: (state, action) => {
      console.log(action.payload);
      let list = [...state.alerts];
      list.push(action.payload.alert);
      state.alerts = list;
    },
    dismissAlert: (state, action) =>  {
      console.log(action.payload);
      let list = state.alerts.filter((a) => a.id != action.payload.id);
      state.alerts = list;
    },
  },
});
export default slice.reducer
// Actions
const { createAlert, dismissAlert } = slice.actions

export const addAlert = (alert) => async dispatch => {
  try {
    dispatch(createAlert({alert}));
  } catch (e) {
    return console.error(e.message);
  }
}
export const removeAlert = (alert) => async dispatch => {
  console.log(alert);
  try {
    return dispatch(dismissAlert(alert))
  } catch (e) {
    return console.error(e.message);
  }
}