import React, { Fragment } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { DirectionsBoat, Place, Train, Warehouse } from "@mui/icons-material";

export const JobStatusOptions = () => {
  return [
    <MenuItem value={"accepted"}>Accepted</MenuItem>,
    <MenuItem value={"pending"}>Pending</MenuItem>,
    <MenuItem value={"billing_ready"}>Billing Ready</MenuItem>,
    <MenuItem value={"final_charges_requested"}>
      Final Charges Requested
    </MenuItem>,
    <MenuItem value={"invoiced"}>Invoiced</MenuItem>,
    <MenuItem value={"closed"}>Closed</MenuItem>,
    <MenuItem value={"canceled"}>Canceled</MenuItem>,
  ];
};

export const ContainerStatusOptions = () => {
  return [
    <MenuItem value={"demurrage"}>Demurrage</MenuItem>,
    <MenuItem value={"picked_up"}>Picked Up</MenuItem>,
    <MenuItem value={"awaiting_arrival"}>Awaiting Arrival</MenuItem>,
    <MenuItem value={"arrived"}>Arrived at Port</MenuItem>,
    <MenuItem value={"available"}>Available</MenuItem>,
    <MenuItem value={"returned"}>Returned</MenuItem>,
    <MenuItem value={"street_turned"}>Street Turned</MenuItem>,
    <MenuItem value={"holds"}>Holds</MenuItem>,
  ];
};

export const ScheduleStatusOptions = () => {
  return [
    <MenuItem value={"scheduled"}>Scheduled</MenuItem>,
    <MenuItem value={"issue"}>Issue</MenuItem>,
    <MenuItem value={"en_route"}>En Route</MenuItem>,
    <MenuItem value={"dropped"}>Dropped</MenuItem>,
  ];
};

export const getContainerTypeOptions = () => {
  return [
    <MenuItem value={"20_standard"}>20FT Standard</MenuItem>,
    <MenuItem value={"40_standard"}>40FT Standard</MenuItem>,
    <MenuItem value={"40_high_cubed"}>40FT High Cubed</MenuItem>,
    <MenuItem value={"40_reefer"}>40FT Reefer</MenuItem>,
    <MenuItem value={"45_standard"}>45FT Standard</MenuItem>,
    <MenuItem value={"45_high_cubed"}>45FT High Cubed</MenuItem>,
    <MenuItem value={"45_reefer"}>45FT Reefer</MenuItem>,
  ];
};

export const getSteamShipLineOptions = () => {
  return [
    <MenuItem value={"maersk"}>Maersk</MenuItem>,
    <MenuItem value={"evergreen"}>Evergreen</MenuItem>,
    <MenuItem value={"hapag_lloyd"}>Hapag Lloyd</MenuItem>,
    <MenuItem value={"cma"}>CMA</MenuItem>,
    <MenuItem value={"acl"}>ACL</MenuItem>,
    <MenuItem value={"cosco"}>COSCO</MenuItem>,
    <MenuItem value={"hamburg_sud"}>HAMBURG SUD</MenuItem>,
    <MenuItem value={"msc"}>MSC</MenuItem>,
    <MenuItem value={"one"}>ONE</MenuItem>,
    <MenuItem value={"wan_hai"}>WAN HAI</MenuItem>,
    <MenuItem value={"yang_ming"}>YANG MING</MenuItem>,
    <MenuItem value={"zim"}>ZIM</MenuItem>,
    <MenuItem value={"oocl"}>OOCL</MenuItem>,
    <MenuItem value={"hmm"}>HMM</MenuItem>,
    <MenuItem value={"ts_lines"}>TS Lines</MenuItem>,
    <MenuItem value={"ellerman_city_liners"}>Ellerman City Liners</MenuItem>,
    <MenuItem value={"turkon_lines"}>Turkon Lines</MenuItem>,
  ];
};

export const getLineItemOptions = () => {
  return [
    <MenuItem value={"line_haul"}>Line Haul</MenuItem>,
    <MenuItem value={"storage"}>Storage</MenuItem>,
    <MenuItem value={"chassis_rental"}>Chassis Rental</MenuItem>,
    <MenuItem value={"port_fee"}>Port Fee</MenuItem>,
    <MenuItem value={"pre_pull"}>Pre-Pull</MenuItem>,
    <MenuItem value={"fuel_surcharge"}>Fuel Surcharge</MenuItem>,
    <MenuItem value={"port_congestion"}>Port Congestion</MenuItem>,
    <MenuItem value={"layover"}>Layover</MenuItem>,
    <MenuItem value={"detention"}>Detention</MenuItem>,
    <MenuItem value={"dry_run"}>Dry Run</MenuItem>,
    <MenuItem value={"bob_tail_run"}>Bob-Tail Run</MenuItem>,
    <MenuItem value={"per_diem"}>Per Diem</MenuItem>,
    <MenuItem value={"misc"}>Misc</MenuItem>,
    <MenuItem value={"tolls"}>Tolls</MenuItem>,
    <MenuItem value={"genset_fee"}>Genset Fee</MenuItem>,
    <MenuItem value={"genset_fuel"}>Genset Fuel</MenuItem>,
  ];
};

export const getStatusColor = (statusType) => {
  let color = "lightgrey";

  if (statusType == null) {
    return "grey";
  }

  switch (statusType) {
    case "demurrage":
      color = "lightblue";
      break;
    case "holds":
      color = "red";
      break;
    case "picked_up":
      color = "yellow";
      break;
    case "available":
      color = "lightgreen";
      break;
    case "final_charges_requested":
      color = "lightyellow";
      break;
    case "billing_ready":
      color = "lightblue";
      break;
    case "invoiced":
      color = "lightsalmon";
      break;
    case "partial_payment":
      color = "orange";
      break;
    case "paid":
      color = "lightgreen";
      break;
    case "accepted":
      color = "lightgrey";
      break;
    case "factored":
      color = "lightsalmon";
      break;
    case "en_route":
      color = "yellow";
      break;
    case "dropped":
      color = "lightseagreen";
      break;
    case "issue":
      color = "red";
      break;
    case "pending":
      color = "lightgreen";
      break;
    case "closed":
      color = "grey";
      break;
    case "returned":
      color = "green";
      break;
    case "street_turned":
      color = "yellow";
      break;
    case "created":
      color = "orange";
      break;
    case "awaiting_approval":
      color = "orange";
      break;
    case "approved":
      color = "blue";
      break;
    case "partially_paid":
      color = "yellow";
      break;
    case "paid":
      color = "green";
      break;
  }

  return color;
};

export const getLogColor = (statusType) => {
  let color = "lightgrey";

  switch (statusType) {
    case "created":
      color = "lightblue";
      break;
    case "modified":
      color = "orange";
      break;
    case "deleted":
      color = "red";
      break;
  }
  return color;
};

export const getNoteColor = (noteType) => {
  let color = "lightgrey";

  switch (noteType) {
    case "dispatch_note":
      color = "grey";
      break;
    case "trip_note":
      color = "lightgreen";
      break;
    default:
      color = "grey";
      break;
  }
  return color;
};

export const getWarningColor = (number) => {
  if (number <= 30 && number > 15) {
    return "lightyellow";
  } else if (number <= 15) {
    return "lightcoral";
  }

  return "lightgreen";
};

export const getLocationIcon = (location_type) => {
  switch (location_type) {
    case "warehouse":
      return <Warehouse />;
    case "terminal":
      return <DirectionsBoat />;
    case "rail_yard":
      return <Train />;
    default:
      return <Place />;
  }
};
