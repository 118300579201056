import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LargeModal, ModalHeader, SmallModal } from "../Modals";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { Input, ListItemIcon, MenuItem, Select } from "@material-ui/core";
import {
  createGeoFence,
  createLocation,
  updateGeoFence,
  updateLocation,
} from "../../api/locations";
import {
  Circle,
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import { getLocationIcon } from "./Options";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: "90%",
  },
  addIcon: {
    margin: "1%",
  },
  button: {
    margin: theme.spacing(2),
    minWidth: "25%",
    height: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

export const NewLocationForm = (props) => {
  const { isLoaded } = useJsApiLoader({
    libraries: ["places"],
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCLTsEEDF3WurlqNWzoP1zytfiSftn2n84",
  });

  const [center, setCenter] = useState({
    lat: 40.733778,
    lng: -74.17264,
    zoom: 15,
  });

  const containerStyle = {
    width: "90%",
    height: "600px",
    margin: "5%",
  };

  const classes = useStyles();

  const [id, setId] = useState();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [locationType, setLocationtype] = useState("");
  const [map, setMap] = React.useState(null);
  const [bounds, setBounds] = useState([]);
  const [circle, setCircle] = useState(null);
  const [radius, setRadius] = useState(50);
  const [circleCenter, setCircleCenter] = useState(center);
  const [searchBox, setSearchBox] = useState(null);
  const [searchMarkers, setSearchMarkers] = useState([]);

  const onLoad = React.useCallback(function callback(map) {
    setBounds(
      new window.google.maps.LatLngBounds({ lat: 40.733778, lng: -74.17264 })
    );
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    let markerArray = [];
    let results = searchBox.getPlaces();
    for (let i = 0; i < results.length; i++) {
      let place = results[i].geometry.location;
      markerArray.push(<Marker key={i} position={place} />);
    }
    setSearchMarkers(markerArray);
    console.log(markerArray);

    let b = new window.google.maps.LatLngBounds(markerArray[0].position);
    markerArray.map((marker) => {
      b.extend(marker.props.position);
    });
    setBounds(b);
    setCenter({ ...b.center, zoom: 12 });
    map.fitBounds(b);
    setMap(map);
  };

  React.useEffect(() => {
    if (props.location != null) {
      setId(props.location.id);
      setName(props.location.name);
      setAddress1(props.location.address_1);
      setAddress2(props.location.address_2);
      setCity(props.location.city);
      setState(props.location.state);
      setZip(props.location.zip);
      setEmail(props.location.email);
      setPhone(props.location.phone);
      setLocationtype(props.location.location_type);

      if (props.location.geo_fence) {
        let gf = props.location.geo_fence;

        setCircleCenter({ lat: gf.center_lat, lng: gf.center_lng });
        setCenter({ lat: gf.center_lat, lng: gf.center_lng, zoom: 15 });
        setRadius(gf.radius);
      }
    }
  }, [props.visible, props.location]);

  const submit = () => {
    let payload = {
      name: name,
      email: email,
      phone: phone,
      address_1: address1,
      address_2: address2,
      city: city,
      state: state,
      zip: zip,
      location_type: locationType,
    };

    if (id) {
      payload.id = id;

      console.log(payload);

      updateLocation(payload, (result) => {
        props.onClose(result);
      });
    } else {
      console.log(payload);

      createLocation(payload, (result) => {
        props.onClose(result);
      });
    }
  };

  const saveGeoFence = () => {
    console.log(circleCenter);
    console.log(radius);

    if (props.location.geo_fence) {
      updateGeoFence(
        props.location.id,
        {
          ...circleCenter,
          radius: radius,
          alerts_enabled: true,
        },
        (result) => {
          props.onClose(result);
        }
      );
    } else {
      createGeoFence(
        props.location.id,
        {
          ...circleCenter,
          radius: radius,
        },
        (result) => {
          props.onClose(result);
        }
      );
    }
  };

  return (
    <LargeModal
      visible={props.visible}
      closeCallback={props.onClose}
      zIndex={"100"}
    >
      <ModalHeader>
        {props.location ? "Edit Location" : "Add Location"}
      </ModalHeader>
      <div style={{ width: "50%", float: "left" }}>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Label</InputLabel>
          <Input
            type={"text"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Email</InputLabel>
          <Input
            type={"text"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Phone</InputLabel>
          <Input
            type={"text"}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Address #1</InputLabel>
          <Input
            type={"text"}
            value={address1}
            onChange={(e) => {
              setAddress1(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Address #2</InputLabel>
          <Input
            type={"text"}
            value={address2}
            onChange={(e) => {
              setAddress2(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">City</InputLabel>
          <Input
            type={"text"}
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">State</InputLabel>
          <Input
            type={"text"}
            value={state}
            onChange={(e) => {
              setState(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Zip</InputLabel>
          <Input
            type={"text"}
            value={zip}
            onChange={(e) => {
              setZip(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-select-label">Type</InputLabel>
          <Select
            labelId="customer-select"
            id="customer-select"
            value={locationType}
            onChange={(e) => {
              setLocationtype(e.target.value);
            }}
          >
            <MenuItem value={"warehouse"}>
              <ListItemIcon>{getLocationIcon("warehouse")}</ListItemIcon>
              WareHouse
            </MenuItem>
            <MenuItem value={"terminal"}>
              <ListItemIcon>{getLocationIcon("terminal")}</ListItemIcon>
              Terminal
            </MenuItem>
            <MenuItem value={"rail_yard"}>
              <ListItemIcon>{getLocationIcon("rail_yard")}</ListItemIcon>
              Rail Yard
            </MenuItem>
            <MenuItem value={"yard"}>
              <ListItemIcon>{getLocationIcon("yard")}</ListItemIcon>
              Yard
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => submit()}
        >
          {props.location ? "Update" : "Create"}
        </Button>
      </div>
      <div style={{ width: "50%", float: "left" }}>
        {isLoaded ? (
          <>
            <div
              id="searchbox"
              style={{
                display: "inline-block",
                width: "100%",
                float: "left",
              }}
            >
              <StandaloneSearchBox
                onLoad={onSBLoad}
                onPlacesChanged={onPlacesChanged}
                // bounds={this.state.bounds}
              >
                <input
                  type="text"
                  placeholder="Customized your placeholder"
                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `90%`,
                    height: `32px`,
                    padding: `1%`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    margin: "5%",
                  }}
                />
              </StandaloneSearchBox>
            </div>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={center.zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={(e) => {
                map && setCircleCenter(e.latLng.toJSON());
              }}
            >
              <Circle
                center={circleCenter}
                radius={radius}
                draggable={true}
                editable={true}
                onLoad={(circle) => setCircle(circle)}
                onUnmount={(circle) => setCircle(null)}
                onDragEnd={() =>
                  circle &&
                  setCircleCenter({
                    lat: circle["center"].lat(),
                    lng: circle["center"].lng(),
                  })
                }
                onRadiusChanged={() =>
                  circle && setRadius(parseInt(circle["radius"]))
                }
              />
              {searchMarkers}
            </GoogleMap>
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => saveGeoFence()}
            >
              {props.location && props.location.geo_fence
                ? "Update GeoFence"
                : "Create GeoFence"}
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
    </LargeModal>
  );
};

export default NewLocationForm;
