import React, { Fragment } from "react";
import styled from "styled-components";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SecuredView from "./SecuredView";
import { titlize } from "../util/functions";
import { deleteInvoice, getInvoices, removePayment } from "../api/invoices";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { getStatusColor } from "../components/forms/Options";
import { AttachMoney, Map, Money } from "@mui/icons-material";
import { NewPaymentForm } from "../components/forms/NewPaymentForm";
import { LargeModal, ModalHeader } from "../components/Modals";
import { Invoice } from "../components/Components";
import SortBar from "../components/SortBar";
import { connect } from "react-redux";
import { dismissProgressOverlay, displayProgressOverlay } from "../store/busy";
import { StatsContainer, StatsItem } from "../components/styled/Elements";
import { Delete } from "@material-ui/icons";
import { addAlert } from "../store/alerts";

const Table = styled.table`
  color: black;
  background: #8080802e;
  width: 90%;
  margin: 5%;
  box-shadow: 8px 5px 5px 0px #aaa;
  border-radius: 1rem;
  overflow: hidden;
  & tr:nth-child(odd) {
    background: #cccccc;
  }
`;

const TR = styled.tr`
  border-bottom: 0.5px solid black;
`;

const TH = styled.th`
  font-size: 1rem;
  font-weight: bold;
  padding: 1%;
`;

const TD = styled.td`
  width: ${(props) => props.width};
  font-weight: ${(props) => props.fontWeight};
  padding: 1%;
`;

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      stats: null,
      filtered: [],
      filter: "created",
      displayType: "all",
      paymentFormOpen: false,
      currentInvoice: null,
      viewablePayments: [],
      viewInvoice: false,
      pageData: {
        currPage: 1,
        maxPage: 1,
        perPage: 10,
      },
    };

    this.onPaymentClose = this.onPaymentClose.bind(this);
    this.onFound = this.onFound.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onPage = this.onPage.bind(this);
  }

  onPage(page, perPage) {
    console.log(page);
    console.log(perPage);
    this.reloadData(page, perPage, {
      filter: this.state.filter,
      display_type: this.state.displayType,
    });
  }

  showProgressOverlay(key) {
    this.props.displayProgressOverlay({ id: key });
  }

  dismissProgressOverlay(key) {
    this.props.dismissProgressOverlay({ id: key });
  }

  componentDidMount() {
    this.showProgressOverlay("invoices");
    getInvoices(
      this.state.pageData,
      { filter: "created", display_type: this.state.displayType },
      (result) => {
        this.setState({
          stats: result.extra,
          invoices: result.data,
          filtered: result.data,
          pageData: {
            currPage: result.page,
            maxPage: result.max_page,
            perPage: this.state.pageData.perPage,
          },
        });
        this.dismissProgressOverlay("invoices");
      }
    );
  }

  reloadData(page, perPage, options) {
    this.showProgressOverlay("invoices");
    getInvoices(
      { currPage: page, perPage: perPage },
      options == null
        ? { filter: this.state.filter, display_type: this.state.displayType }
        : options,
      (result) => {
        this.setState({
          stats: result.extra,
          invoices: result.data,
          filtered: result.data,
          filter: options.filter ? options.filter : "all",
          displayType: options.display_type ? options.display_type : "all",
          pageData: {
            currPage: result.page,
            maxPage: result.max_page,
            perPage: perPage,
          },
        });
        this.dismissProgressOverlay("invoices");
      }
    );
  }

  openPaymentForm(invoice) {
    this.setState({
      currentInvoice: invoice,
      paymentFormOpen: true,
    });
  }

  onPaymentClose(result) {
    getInvoices(this.state.pageData, {}, (result) => {
      this.setState({
        invoices: result.data,
        paymentFormOpen: false,
        currentInvoice: null,
        viewInvoice: false,
      });
    });
  }

  openInvoice(invoice) {
    this.setState({ currentInvoice: invoice, viewInvoice: true });
  }

  getPaymentRows(payments, invoiceTotal) {
    if (payments.length > 0) {
      let remainingBalance = invoiceTotal;

      let rows = payments.map((p) => {
        let factoredAmount =
          (p.amount / (1.0 - p.factoring_fee)) * p.factoring_fee;
        let factoringBlurb = "";
        if (p.factoring_fee && p.factoring_fee > 0.0) {
          remainingBalance -= p.amount / (1.0 - p.factoring_fee);
          factoringBlurb = ` (${
            p.factoring_fee * 100
          }% Fee $${factoredAmount.toFixed(2)})`;
        } else {
          remainingBalance -= p.amount;
        }

        return (
          <tr>
            <td>{new Date(p.created_at).toLocaleDateString()}</td>
            <td>{titlize(p.payment_type)}</td>
            <td>{p.reference_number}</td>
            <td>
              ${p.amount}
              {factoringBlurb}
              {remainingBalance > 0.0
                ? ` ($${remainingBalance.toFixed(2)} Bal. Remaining)`
                : ""}
            </td>
            <td>
              <Button
                startIcon={<Delete style={{ color: "red" }} />}
                onClick={() =>
                  removePayment(p.invoice_id, p.id, (result) => {
                    this.reloadData(
                      this.state.pageData.page,
                      this.state.pageData.amount,
                      { filter: this.state.filter }
                    );
                  })
                }
              >
                {" "}
                Delete Payment{" "}
              </Button>
            </td>
          </tr>
        );
      });

      return (
        <table style={{ width: "100%", fontSize: "0.80rem" }}>
          <tr>
            <th>Paid On</th>
            <th>Type</th>
            <th>Ref</th>
            <th>Amount</th>
            <th>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({
                    viewablePayments: this.state.viewablePayments.filter(
                      (id) => id != payments[0].invoice_id
                    ),
                  });
                }}
              >
                Hide Payments
              </Button>
            </th>
          </tr>
          {rows}
        </table>
      );
    } else {
      return null;
    }
  }

  getRows(invoices, viewablePayments) {
    return invoices
      .sort((a, b) => b.job_container.load_number - a.job_container.load_number)
      .map((i) => {
        let rows = [
          <TR style={{ backgroundColor: getStatusColor(i.status) }}>
            <TD>
              {`FCAI${i.job_container.load_number}${
                i.invoice_type == "per_diem" ? "-PD" : ""
              }`}
            </TD>
            <TD>{i.job_container.load_number}</TD>
            <TD>{i.job_container.container.number}</TD>
            <TD>{i.job_container.job.customer.name}</TD>
            <TD>{new Date(i.created_at).toLocaleDateString()}</TD>
            <TD>${i.total}</TD>
            <TD>{titlize(i.status)}</TD>
            <TD>
              <Button
                startIcon={<AddCircleIcon sx={{ fontSize: 30 }} />}
                onClick={() => {
                  this.openPaymentForm(i);
                }}
              >
                Add Payment
              </Button>
              <Button
                startIcon={<Map />}
                href={`/admin-dashboard?load_id=${i.job_container.id}&filter=billing`}
              >
                Go To Load
              </Button>
              <Button
                startIcon={<AttachMoney />}
                onClick={() => {
                  this.setState({
                    viewablePayments: [...this.state.viewablePayments, i.id],
                  });
                }}
              >
                View Payments
              </Button>
              <Button
                startIcon={<AttachMoney />}
                onClick={() => {
                  this.openInvoice(i);
                }}
              >
                View Invoice
              </Button>
              {this.props.currentUser.access_levels.includes("super_user") ? (
                <Button
                  startIcon={<Delete />}
                  onClick={() => {
                    let proceed = confirm(
                      `Are you sure you want to delete invoice ${`FCAI${
                        i.job_container.load_number
                      }${i.invoice_type == "per_diem" ? "-PD" : ""}`}?`
                    );
                    if (proceed) {
                      deleteInvoice(i.id, (results) => {
                        let invoicesCopy = this.state.invoices.filter(
                          (inv) => inv.id != i.id
                        );
                        this.setState({
                          invoices: invoicesCopy,
                          filtered: invoicesCopy,
                        });
                        this.props.addAlert({
                          id: new Date().getTime(),
                          body: "Invoice Deleted",
                        });
                      });
                    }
                  }}
                >
                  Delete Invoice
                </Button>
              ) : (
                <></>
              )}
            </TD>
          </TR>,
        ];
        if (viewablePayments.includes(i.id)) {
          rows.push(
            <TR>
              <TD colSpan={"8"}>{this.getPaymentRows(i.payments, i.total)}</TD>
            </TR>
          );
        }
        return rows;
      });
  }

  getSearchOptions(invoices) {
    return invoices
      .sort((a, b) => b.job_container.load_number - a.job_container.load_number)
      .map((i) => {
        return {
          id: i.id,
          label: `Load# ${i.job_container.load_number}- Container #${
            i.job_container.container.number
          } - ${titlize(i.invoice_type)}`,
        };
      });
  }

  onFound(found) {
    if (found == null) return;
    let visible = [];
    found.map((f) => {
      let invoice = this.state.invoices.filter((i) => i.id == f.id);
      if (invoice.length > 0) {
        visible.push(invoice[0]);
      }
    });

    this.setState({ filtered: visible });
  }

  onFilter(filter, displayType) {
    console.log("Filter: " + filter);

    if (displayType == null) {
      displayType = this.state.displayType;
    }

    console.log("Display Type: " + displayType);

    let filter_data = {};

    if (filter != null) {
      filter_data.filter = filter;
    }

    if (displayType != null) {
      filter_data.display_type = displayType;
    }

    this.reloadData(1, this.state.pageData.perPage, filter_data);
  }

  onSearch(searchText) {
    this.reloadData(
      1,
      this.state.pageData.perPage,
      { search: searchText },
      (results) => {
        this.setState({
          filtered: results,
          pageData: { currPage: 1, maxPage: 1 },
        });
      }
    );
  }

  render() {
    return (
      <SecuredView accessLevels={["super_user", "billing_user"]}>
        <SortBar
          currentPage={this.state.pageData.currPage}
          onPage={this.onPage}
          maxPages={this.state.pageData.maxPage}
          initialFilter={this.state.filter}
          filterOptions={[
            "all",
            "created",
            "awaiting_approval",
            "approved",
            "paid",
            "partially_paid",
          ]}
          onFilter={(filterVal) =>
            this.onFilter(filterVal, this.state.displayType)
          }
          searchLabel={
            "Search Invoices by Load #, Ref # Container # or Customer Name"
          }
          searchItems={this.getSearchOptions(this.state.invoices)}
          onSearchFound={this.onFound}
          onSearchNotFound={this.onSearch}
        >
          <FormControl style={{ float: "left", marginRight: "1%" }}>
            <InputLabel>Invoice Type</InputLabel>
            <Select
              style={{ float: "left", width: 200 }}
              value={this.state.displayType}
              onChange={(e) => this.onFilter(this.state.filter, e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="drayage">Drayage Only</MenuItem>
              <MenuItem value="per_diem">Per-Diem Only</MenuItem>
            </Select>
          </FormControl>
        </SortBar>

        {this.state.stats ? (
          <StatsContainer>
            <StatsItem>
              Open-Invoices: {this.state.stats.unpaid_count} (Un-Paid:{" "}
              {this.state.stats.unpaid_count_created}, Partially Paid:{" "}
              {this.state.stats.unpaid_count_partially_paid})
            </StatsItem>
            <StatsItem>
              Open-Balances: ${this.state.stats.unpaid_amount.toLocaleString()}{" "}
              (Un-Paid: $
              {this.state.stats.unpaid_amount_created.toLocaleString()},
              Partially Paid: $
              {this.state.stats.unpaid_amount_partially_paid.toLocaleString()})
            </StatsItem>
            <StatsItem>
              Oldest Un-Paid Invoice:{" "}
              {`FCAI${
                this.state.stats.oldest_invoice.job_container.load_number
              }${
                this.state.stats.oldest_invoice.invoice_type == "per_diem"
                  ? "-PD"
                  : ""
              } Sent: ${new Date(
                this.state.stats.oldest_invoice.created_at
              ).toLocaleDateString()}`}
            </StatsItem>
          </StatsContainer>
        ) : (
          <></>
        )}
        <Table>
          <TR>
            <TH>Invoice No.</TH>
            <TH>Load No.</TH>
            <TH>Container No.</TH>
            <TH>Customer</TH>
            <TH>Sent On</TH>
            <TH>Amount</TH>
            <TH>Status</TH>
            <TH></TH>
          </TR>
          {this.getRows(this.state.filtered, this.state.viewablePayments)}
        </Table>
        <NewPaymentForm
          visible={this.state.paymentFormOpen}
          invoiceId={
            this.state.currentInvoice ? this.state.currentInvoice.id : -1
          }
          onClose={this.onPaymentClose}
        />
        <LargeModal
          visible={this.state.viewInvoice}
          closeCallback={this.onPaymentClose}
        >
          <ModalHeader>
            View Invoice -
            {this.state.currentInvoice ? (
              <span style={{ color: "white", margin: "1%" }}>
                Created By: {this.state.currentInvoice.created_by}
              </span>
            ) : (
              ""
            )}
            {this.state.currentInvoice &&
            this.state.currentInvoice.status == "approved" ? (
              <span
                style={{
                  color: "white",
                  margin: "1%",
                }}
              >
                - Approved By: {this.state.currentInvoice.approved_by}
              </span>
            ) : (
              ""
            )}
          </ModalHeader>
          {this.state.viewInvoice && this.state.currentInvoice ? (
            <div
              style={{
                display: "inline-block",
                width: "100%",
                overflowY: "scroll",
                height: "500px",
              }}
            >
              <Invoice
                visible={true}
                invoice={this.state.currentInvoice}
                jobContainer={this.state.currentInvoice.job_container}
                job={this.state.currentInvoice.job_container.job}
              />
            </div>
          ) : (
            <></>
          )}
        </LargeModal>
      </SecuredView>
    );
  }
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts.alerts,
    currentUser: state.user.user,
    // waitingFor: state.busy.waitingFor
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addAlert: (alert) => dispatch(addAlert(alert)),
    // removeAlert: alert => dispatch(removeAlert(alert)),
    // setUser: user => dispatch(setUser(user)),
    dismissProgressOverlay: (identifier) =>
      dispatch(dismissProgressOverlay(identifier)),
    displayProgressOverlay: (identifier) =>
      dispatch(displayProgressOverlay(identifier)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
