import { createSlice } from '@reduxjs/toolkit'
import {login, currentUser, logout as logOutUser} from '../api/user'
// Slice
const slice = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
    },
    logoutSuccess: (state, action) =>  {
      state.user = null;
    },
    userLoggedIn: (state, action) => {
      state.user = action.payload;
    },
  },
});
export default slice.reducer
// Actions
const { loginSuccess, logoutSuccess, userLoggedIn } = slice.actions
export const authenticate = (loginParams, callback) => async dispatch => {
  try {
    login(loginParams, (result) => {
        callback(result);
        dispatch(loginSuccess(result));
    })
    
  } catch (e) {
    return console.error(e.message);
  }
}
export const setUser = (user) => async dispatch => {
    try {
        dispatch(userLoggedIn(user));
      
    } catch (e) {
      return console.error(e.message);
    }
  }
export const logout = () => async dispatch => {
  try {
    const res = await logOutUser();
    return dispatch(logoutSuccess())
  } catch (e) {
    return console.error(e.message);
  }
}