import styled from 'styled-components';

export const LicenseContainer = styled.div`
    width: 90%;
    display: inline-block;
    margin: 5%
`

export const License = styled.div`
    width: 38%;
    display: inline-block;
    float: left;
    margin: 5%;
    border: 1px solid grey;
    box-shadow: 8px 5px 5px 0px #aaa;
`
export const LicenseLogo = styled.div`
    width: 26%;
    margin: 2%;
    height: auto;
    float: left;
    background: url("${(props) => props.src}");
    border-radius: 50%;
    padding-bottom: 26%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 2px solid #e9e9e9;
`

export const LicenseLabel = styled.div`
    width: 28%
    display: inline-block;
    float: left;
    margin: 1%
`

export const LicenseInfo = styled.div`
    width: 68%
    display: inline-block;
    float: left;
    margin: 1%
`
export const LicenseRow = styled.div`
    width: 70%;
    display: inline-block;
    float: left;
`