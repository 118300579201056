import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { LargeModal, ModalHeader, SmallModal } from '../Modals';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { Input, Select, TextField } from '@material-ui/core';
import { Autocomplete, Checkbox, FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import { getContainerTypeOptions, getSteamShipLineOptions } from './Options';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { addContainer, createAndAddContainer } from '../../api/jobs';
import moment from 'moment';
import { createChassis, getAllChassis, updateChassis } from '../../api/chassis';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: "90%",
    },
    addIcon: {
        margin: "1%"
    },
    button: {
        margin: theme.spacing(1),
        minWidth: "25%",
        height: "100%"
      },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  }));

export const NewChassisForm = (props) => {

    const [isEdit, setIsEdit] = useState(false);
    const classes = useStyles();
    const [id, setId] = useState();
    const[chasses, setChasses] = useState([]);
    const [leaseOptions, setLeaseOptions] = useState([]);
    const [chassisNumber, setChassisNumber] = useState("");
    const [vin, setVin] = useState("");
    const [plate, setPlate] = useState("");
    const [minLength, setMinLength] = useState(20);
    const [maxLength, setMaxLength] = useState(-1);
    const [extendable, setExtendable] = useState(false);
    const [companyOwned, setCompanyOwned] = useState(false);
    const [leasedFrom, setLeasedFrom] = useState("");
    const[inRepair, setinRepair] = useState(false);
    const[isActive, setIsActive] = useState(false);


    const setFromChassis = (chassis) => {
        setIsEdit(true);
        setId(chassis.id);
        setChassisNumber(chassis.number);
        setVin(chassis.vin);
        setPlate(chassis.plate);
        setMinLength(chassis.length);
        setMaxLength(chassis.max_length);
        setExtendable(chassis.max_length > 0);
        setCompanyOwned(chassis.is_company_owned);
        setLeasedFrom(chassis.leased_from);
        setinRepair(chassis.in_repair);
        setIsActive(chassis.is_active);
    }

    const getActiveCheckBox = () => {
        if (isEdit) {
                    return <Fragment>
                        <FormGroup className={classes.formControl}>
                                <FormControlLabel control={<Checkbox value={inRepair} checked={inRepair} onChange={(e) => {setinRepair(e.target.checked)}}/>} label="In Repair?" />
                            </FormGroup>
                            <FormGroup className={classes.formControl}>
                                <FormControlLabel control={<Checkbox value={isActive} checked={isActive} onChange={(e) => {setIsActive(e.target.checked)}}/>} label="Active?" />
                            </FormGroup>
                    </Fragment>
                }
    }
    React.useEffect(() => {
        getAllChassis((result) => {
            console.log(result);
            setChasses(result);
            
            let leaseOpt = result.map((c) => {
                if (c.leased_from != null && c.leased_from.length > 0) return c.leased_from;
            });

            let options = [...new Set(leaseOpt)].filter((opt) => {return opt != null});
            
            setLeaseOptions(options.map((opt) => {return {label: opt}}));
            
        })
    }, [props.visible])

    React.useEffect(() => {
        if (props.chassis) {
            setFromChassis(props.chassis);
        } else {
            setIsEdit(false)
        }
        
    }, [props.chassis])

    const submit = () => {

        let payload = {number: chassisNumber, vin: vin, plate: plate, is_company_owned: companyOwned, leased_from: leasedFrom, length: minLength, max_length: maxLength};
        
        if (isEdit) {
            payload.id = id;
            payload.is_active = isActive;
            payload.in_repair = inRepair;
            console.log(payload);
            updateChassis(payload, (result) => {props.onClose(result)});
        } else {
            console.log(payload);
            createChassis(payload, (result) => {props.onClose(result)});
        }
        
    }

    return (
        <SmallModal visible={props.visible} closeCallback={props.onClose} zIndex={"100"}>
            <ModalHeader>Add Chassis</ModalHeader>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Chassis Number</InputLabel>
                <Input type={"text"} value={chassisNumber} onChange={(e) => {setChassisNumber(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Plate</InputLabel>
                <Input type={"text"} value={plate} onChange={(e) => {setPlate(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">VIN</InputLabel>
                <Input type={"text"} value={vin} onChange={(e) => {setVin(e.target.value)}}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Length</InputLabel>
                <Select
                labelId="customer-select"
                id="customer-select"
                value={minLength}
                onChange={(e) => {setMinLength(e.target.value)}}
                >
                <MenuItem value={20}>20FT</MenuItem>
                <MenuItem value={40}>40FT</MenuItem>
                <MenuItem value={45}>45FT</MenuItem>
                </Select>
            </FormControl>
            <FormGroup className={classes.formControl}>
                <FormControlLabel control={<Checkbox value={extendable} checked = {extendable} onChange={(e) => {setExtendable(e.target.checked)}}/>} label="Adjustable?" />
            </FormGroup>
            <FormControl className={classes.formControl} disabled={!extendable}>
                <InputLabel id="customer-select-label">Length</InputLabel>
                <Select
                labelId="customer-select"
                id="customer-select"
                value={maxLength}
                onChange={(e) => {setMaxLength(e.target.value)}}
                >
                <MenuItem value={-1}>Not Set</MenuItem>
                <MenuItem value={20}>20FT</MenuItem>
                <MenuItem value={40}>40FT</MenuItem>
                <MenuItem value={45}>45FT</MenuItem>
                </Select>
            </FormControl>
            <FormGroup className={classes.formControl}>
                <FormControlLabel control={<Checkbox value={companyOwned} checked={companyOwned} onChange={(e) => {setCompanyOwned(e.target.checked)}}/>} label="Company Owned?" />
            </FormGroup>
            <FormControl className={classes.formControl}>
            <Autocomplete
                disabled={companyOwned}
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={leaseOptions}
                value = {leasedFrom}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Leased From"
                    InputProps={{
                    ...params.InputProps,
                    type: 'search',
                    }}
                    onSelect={(e) => {setLeasedFrom(e.target.value)}}
                />
                )}
            />
            </FormControl>
            {
                getActiveCheckBox()
            }
            <Button className={classes.button} variant="contained" onClick={() => submit()}>{isEdit ? "Update" : "Create"}</Button>
        </SmallModal>
    );
}

export default NewChassisForm;