import styled from "styled-components";
import RigtheousRegular from '../../fonts/Righteous-Regular.ttf'


export const Bar = styled.div`
  color: blue;
  width: 98%;
  display: inline-block;
  padding: 1%;
  border-bottom: ${(props) => props.borderBottom ? "3px solid black" : "none"};
`;

export const UserName = styled.div`
  float: right;
  color: grey;
  margin: 1%;
  font-size: 1.15vw;
`;

export const Title = styled.div`
    @font-face {
        font-family: 'RigtheousRegular';
        src: url(${RigtheousRegular}) format('truetype');
        font-weight: 300;
        font-style: normal;
        font-display: auto;
    }
    
    float: left;
    font-size: 6vw;
    text-align: center;
    width: 70%;
    color: black;
    font-family: 'RigtheousRegular';
`;

export const Logo = styled.img`
  float: left;
  color: grey;
  width: 13%;
  margin: 1%;
`;

export const UserInfoContainer = styled.div`
  float: left;
  margin: 1%;
  width: 13%
`;

export const LogoutButton = styled.button`
  float: right;
  margin: 1%;
  border-radius: 2rem;
  background: #990000;
  color: white;
  padding: 0 2%;
`;

export const NavItem = styled.button`
  float: left;
  margin: 0 1%;
  border-radius: 0.5rem;
  min-width: 5%;
  font-size: 1.5rem;
  background-color: ${(props) => props.currentPath ? "black" : "white"};

  a {
    color: ${(props) => props.currentPath ? "white" : "black"};
    text-decoration: none;
  }
`;