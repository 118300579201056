import React, { Fragment } from "react";
import styled from "styled-components";
import { getAllTrucks, getAllTrucksOnMotive } from "../api/trucks";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ContactlessIcon from "@material-ui/icons/Contactless";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import MapView from "../components/MapView";
import NewVehicleForm from "../components/forms/NewVehicleForm";
import SecuredView from "./SecuredView";
import NavBar from "../components/nav_bar";
import { getAllChassisReport, getYardLogs } from "../api/chassis";
import { titlize } from "../util/functions";
import NewChassisForm from "../components/forms/NewChassisForm";
import { Edit, ReportTwoTone } from "@material-ui/icons";
import { Button, MenuItem, Select } from "@material-ui/core";
import { ComplianceBadge } from "../components/styled/Badges";
import { Add, DocumentScanner, Message, Report } from "@mui/icons-material";
import { ComplianceForm } from "../components/forms/ComplianceForm";
import ChassisReport from "../components/reports/ChassisReport";
import moment from "moment";
import AllChassesReport from "../components/reports/AllChassesReport";

const Table = styled.table`
  color: black;
  background: #8080802e;
  width: 98%;
  margin: 1%;
  box-shadow: 8px 5px 5px 0px #aaa;
  border-radius: 1rem;
  overflow: hidden;
  & tr:nth-child(odd) {
    background: #cccccc;
  }
`;

const TR = styled.tr`
  border-bottom: 0.5px solid black;
`;

const TH = styled.th`
  font-size: 1rem;
  font-weight: bold;
  padding: 1%;
`;

const TD = styled.td`
  width: ${(props) => props.width};
  font-weight: ${(props) => props.fontWeight};
  padding: 1%;
`;

class YardLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "is_active",
      addChassisFormVisible: false,
      yardLogs: [],
      currentChassis: null,
      chassisReportOpen: false,
      allChassisReportOpen: false,
      reportStart: moment(),
      reportEnd: moment(),
      reportData: null,
    };
    this.closeChassisFormCallback = this.closeChassisFormCallback.bind(this);
    this.onComplianceFormClose = this.onComplianceFormClose.bind(this);
    this.onReportClose = this.onReportClose.bind(this);
  }

  componentDidMount() {
    getYardLogs(
      (logs) => {
        this.setState({
          yardLogs: logs.sort((a, b) => a.number.localeCompare(b.number)),
        });
      },
      null,
      ["location", "compliance_items"]
    );

    let start = moment();
    start.startOf("month").toISOString();
    let end = moment();
    end.endOf("month").toISOString();

    this.setState({
      reportStart: start,
      reportEnd: end,
    });
  }

  openAddChassisForm() {
    this.setState({
      currentChassis: null,
      addChassisFormVisible: true,
    });
  }

  closeChassisFormCallback(result) {
    this.setState({
      currentChassis: null,
      addChassisFormVisible: false,
    });
    getYardLogs((logs) => {
      this.setState({ yardLogs: logs });
    });
  }

  openComplianceForm(chassis) {
    this.setState({ complianceFormOpen: true, currentChassis: chassis });
  }

  onComplianceFormClose() {
    this.setState({ complianceFormOpen: false, currentChassis: null });
  }

  openReport(chassis) {
    this.setState({ chassisReportOpen: true, currentChassis: chassis });
  }

  onReportClose() {
    this.setState({
      chassisReportOpen: false,
      currentChassis: null,
      allChassisReportOpen: false,
    });
  }

  getRows(yardLogs) {
    let filtered = [];

    if (this.state.filter != "all") {
      filtered = yardLogs.filter(
        (chassis) => chassis[this.state.filter] == true
      );
    } else {
      filtered = yardLogs;
    }
    let rows = [];
    filtered
      .filter((t) => t.job_container != null)
      .map((t) => {
        let upcomingRenewals = t.compliance_items.filter(
          (c) => c.days_left <= 30 && c.days_left > 15
        );
        let criticalUpcomingRenewals = t.compliance_items.filter(
          (c) => c.days_left <= 15
        );
        rows.push(
          <TR
            style={{
              backgroundOpacity: "30%",
              background: t.in_repair
                ? "repeating-linear-gradient(45deg,#DEE190,#DEE190 10px,#B8B8B8 10px,#B8B8B8 20px)"
                : "",
            }}
          >
            <TD>{t.number}</TD>
            <TD style={{ textAlign: "center" }}>
              {t.is_company_owned ? "F&C" : t.leased_from}
            </TD>
            <TD>
              #
              {t.chassis_snapshot != null &&
              t.chassis_snapshot.load_number != null
                ? t.chassis_snapshot.load_number
                : "- - -"}
            </TD>
            <TD>{t.container != null ? t.container.number : "Vacant"}</TD>
            <TD>
              {t.container != null
                ? titlize(t.container.container_type)
                : "- - -"}
            </TD>
            <TD>
              {t.job_container != null
                ? t.job_container.is_loaded
                  ? "Loaded"
                  : "Empty"
                : "- - -"}
            </TD>
            <TD>
              {t.container != null
                ? t.container.ship_line.toUpperCase()
                : "- - -"}
            </TD>
            <TD>
              {t.return_location != null ? t.return_location.name : "- - -"}
            </TD>
            <TD>{t.plate}</TD>
            <TD>
              {t.max_length > -1
                ? `${t.length}FT Ext -> ${t.max_length}FT`
                : `${t.length}FT`}
            </TD>
            <TD>
              {t.job_container != null
                ? t.destination != null
                  ? t.destination.name
                  : "Not Scheduled"
                : "- - -"}
            </TD>
            <TD>{t.location != null ? t.location.location_name : "???"}</TD>
            <TD>
              <Edit
                onClick={() => {
                  this.setState({
                    currentChassis: t,
                    addChassisFormVisible: true,
                  });
                }}
              />
            </TD>
            <TD>
              {upcomingRenewals.length > 0 ? (
                <ComplianceBadge
                  style={{ color: "black", backgroundColor: "yellow" }}
                >
                  {upcomingRenewals.length}
                </ComplianceBadge>
              ) : (
                <></>
              )}
              {criticalUpcomingRenewals.length > 0 ? (
                <ComplianceBadge>
                  {criticalUpcomingRenewals.length}
                </ComplianceBadge>
              ) : (
                <></>
              )}
              <Button
                startIcon={<Add />}
                onClick={() => this.openComplianceForm(t)}
              >
                Add Compliance
              </Button>
            </TD>
            <TD>
              <Button
                startIcon={<Message />}
                onClick={() => this.openReport(t)}
              />
            </TD>
          </TR>
        );
      });
    filtered
      .filter((t) => t.job_container == null)
      .map((t) => {
        let upcomingRenewals = t.compliance_items.filter(
          (c) => c.days_left <= 30 && c.days_left > 15
        );
        let criticalUpcomingRenewals = t.compliance_items.filter(
          (c) => c.days_left <= 15
        );
        rows.push(
          <TR
            style={{
              backgroundOpacity: "30%",
              background: t.in_repair
                ? "repeating-linear-gradient(45deg,#DEE190,#DEE190 10px,#B8B8B8 10px,#B8B8B8 20px)"
                : "",
            }}
          >
            <TD>{t.number}</TD>
            <TD style={{ textAlign: "center" }}>
              {t.is_company_owned ? "F&C" : t.leased_from}
            </TD>
            <TD>
              #
              {t.chassis_snapshot != null &&
              t.chassis_snapshot.load_number != null
                ? t.chassis_snapshot.load_number
                : "- - -"}
            </TD>
            <TD>
              {t.chassis_snapshot != null &&
              t.chassis_snapshot.container_number != null
                ? t.chassis_snapshot.container_number
                : "Vacant"}
            </TD>
            <TD>
              {t.chassis_snapshot != null &&
              t.chassis_snapshot.container_type != null
                ? titlize(t.chassis_snapshot.container_type)
                : "- - -"}
            </TD>
            <TD>
              {t.chassis_snapshot != null && t.chassis_snapshot.loaded != null
                ? t.chassis_snapshot.loaded
                  ? "Loaded"
                  : "Empty"
                : "- - -"}
            </TD>
            <TD>
              {t.chassis_snapshot != null &&
              t.chassis_snapshot.container_ssl != null
                ? t.chassis_snapshot.container_ssl.toUpperCase()
                : "- - -"}
            </TD>
            <TD>
              {t.chassis_snapshot &&
              t.chassis_snapshot.return_location_name != null
                ? t.chassis_snapshot.return_location_name
                : "- - -"}
            </TD>
            <TD>{t.plate}</TD>
            <TD>
              {t.max_length > -1
                ? `${t.length}FT Ext -> ${t.max_length}FT`
                : `${t.length}FT`}
            </TD>
            <TD>
              {t.chassis_snapshot && t.chassis_snapshot.load_number != null
                ? t.chassis_snapshot.delivery_location_name
                  ? t.chassis_snapshot.delivery_location_name
                  : "Not Scheduled"
                : "- - -"}
            </TD>
            <TD>
              {t.chassis_snapshot != null &&
              t.chassis_snapshot.location_name != null
                ? t.chassis_snapshot.location_name
                : t.chassis_snapshot && t.chassis_snapshot.location
                ? t.chassis_snapshot.location.location_name
                : "Unkown"}
            </TD>
            <TD>
              <Edit
                onClick={() => {
                  this.setState({
                    currentChassis: t,
                    addChassisFormVisible: true,
                  });
                }}
              />
            </TD>
            <TD>
              {upcomingRenewals.length > 0 ? (
                <ComplianceBadge
                  style={{ color: "black", backgroundColor: "yellow" }}
                >
                  {upcomingRenewals.length}
                </ComplianceBadge>
              ) : (
                <></>
              )}
              {criticalUpcomingRenewals.length > 0 ? (
                <ComplianceBadge>
                  {criticalUpcomingRenewals.length}
                </ComplianceBadge>
              ) : (
                <></>
              )}
              <Button
                startIcon={<Add />}
                onClick={() => this.openComplianceForm(t)}
              >
                Add Compliance
              </Button>
            </TD>
            <TD>
              <Button
                startIcon={<Message />}
                onClick={() => this.openReport(t)}
              />
            </TD>
          </TR>
        );
      });
    return rows;
  }

  render() {
    return (
      <SecuredView
        accessLevels={[
          "super_user",
          "standard_user",
          "billing_user",
          "hr_user",
        ]}
      >
        <div style={{ margin: "5%" }}>
          <Select
            value={this.state.filter}
            onChange={(e) => {
              this.setState({ filter: e.target.value });
            }}
          >
            <MenuItem value={"is_active"}>Active Only</MenuItem>
            <MenuItem value={"is_company_owned"}>Company Owned</MenuItem>
            <MenuItem value={"in_repair"}>In Repair</MenuItem>
            <MenuItem value={"all"}>View All</MenuItem>
          </Select>
          <Button
            onClick={() => {
              this.setState({ allChassisReportOpen: true });
            }}
          >
            Generate Report
          </Button>
        </div>
        <Table>
          <TR>
            <TH>Chassis</TH>
            <TH>Company Owned?</TH>
            <TH style={{ minWidth: 75 }}>Load #</TH>
            <TH style={{ minWidth: 120 }}>Container #</TH>
            <TH>Container Type</TH>
            <TH>Loaded?</TH>
            <TH>SSL</TH>
            <TH>Return To</TH>
            <TH>Plate</TH>
            <TH>Chassis Size</TH>
            <TH>Delivery Location</TH>
            <TH>Current Location</TH>
            <TH>
              <AddCircleIcon
                sx={{ fontSize: 30 }}
                onClick={() => this.openAddChassisForm()}
              />
            </TH>
            <TH>Compliance</TH>
            <TH>Reports</TH>
          </TR>
          {this.getRows(this.state.yardLogs)}
        </Table>
        <NewChassisForm
          chassis={this.state.currentChassis}
          visible={this.state.addChassisFormVisible}
          onClose={this.closeChassisFormCallback}
        />
        <ComplianceForm
          visible={this.state.complianceFormOpen}
          onClose={this.onComplianceFormClose}
          type="chassis"
          obj={this.state.currentChassis}
        />
        <ChassisReport
          visible={this.state.chassisReportOpen}
          chassis={this.state.currentChassis}
          onClose={this.onReportClose}
        />
        <AllChassesReport
          visible={this.state.allChassisReportOpen}
          onClose={this.onReportClose}
        />
      </SecuredView>
    );
  }
}

export default YardLogs;
