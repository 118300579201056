import React, { useRef } from "react";
import Twilio from "./twilio";
import { OutlinedInput, Button } from "@material-ui/core";
import { MediumModal, ModalHeader } from "../Modals";
import {
  ChatListItem,
  MainChatContainer,
  ChatListContainer,
  ChatMessageContainer,
  ChatMessage,
  ChatBody,
  ChatInfo,
} from "../styled/Chat";
import { getContacts } from "../../api/chat";
import moment from "moment/moment";
import { Alert } from "@mui/material";
import { Feedback } from "@mui/icons-material";

const messagesEnd = React.createRef();

class ChatComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unreadMessages: 0,
      onNotification: props.onNotification,
      currentUser: props.currentUser,
      message: "",
      mySid: null,
      twilioClient: Twilio.getInstance(props.twilioToken),
      expanded: false,
      conversations: [],
      currentConversation: null,
      currentConversationMessages: [],
      dispatchers: [],
      drivers: [],
    };

    this.handleConversationUpdate = this.handleConversationUpdate.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.update(this.state.twilioClient);
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.twilioToken !== this.props.twilioToken) {
      if (!this.props.twilioToken) {
        return;
      }
      console.log("Setting Twilio Client");
      // console.log(props.twilioToken);
      let client = Twilio.getInstance(this.props.twilioToken);
      client.add_listener(this.handleConversationUpdate);
      this.update(client);
    }
    if (prevProps.currentUser !== this.props.currentUser) {
      this.setState({ currentUser: this.props.currentUser });
    }
  }

  update = async (client) => {
    let newContacts = [];
    await getContacts((contacts) => {
      newContacts = contacts;
    });
    let conversations = await client.getConversations();

    this.setState({
      twilioClient: client,
      conversations: conversations,
      dispatchers: newContacts.dispatchers,
      drivers: newContacts.drivers,
    });
  };

  scrollToBottom = () => {
    messagesEnd.current?.scrollIntoView({ behavior: "smooth" });
  };

  handleConversationUpdate = async (conversationEvents, updateReasons) => {
    console.log("Conversation Updated");
    console.log(this.state.currentConversation);
    console.log(conversationEvents);
    console.log(updateReasons);
    // getConversations(conversations);
    for (let event of conversationEvents) {
      console.log(event);
      if (event[0] == "messageAdded") {
        if (this.state.expanded == false) {
          this.setState({ unreadMessages: this.state.unreadMessages + 1 });
        }

        console.log("Message Added");
        console.log(event[1][0]);

        let participants = await this.state.twilioClient.get_participants(
          event[1][0].conversation
        );

        let [friendlyName, isDispatcher, isMe] = this.getSenderInfo(
          event[1][0].conversation,
          event[1][0],
          participants,
          this.state.dispatchers,
          this.state.drivers,
          this.state.mySid
        );

        if (
          this.state.currentConversation != null &&
          this.state.currentConversation.sid == event[1][0].conversation.sid
        ) {
          console.log("Adding Message to Conversation");
          let formattedDate = moment(event[1][0].state.dateUpdated).format(
            "DD/MM/YYYY hh:mm a"
          );

          // Remove the last element (the empty div) and add the new message
          let messages = this.state.currentConversationMessages;
          this.setState({
            currentConversationMessages: [
              ...messages,
              <ChatMessage
                isDispatcher={isDispatcher}
                senderName={friendlyName}
              >
                <ChatBody isDispatcher={isDispatcher}>
                  {event[1][0].state.body}
                </ChatBody>
                <ChatInfo isDispatcher={isDispatcher}>
                  {friendlyName} at {formattedDate}
                </ChatInfo>
              </ChatMessage>,
            ],
          });
          this.scrollToBottom();
        } else {
          this.state.onNotification({
            type: "chat",
            body: friendlyName + " said: \n" + event[1][0].state.body,
          });
        }
      }
    }
  };

  setConvo = async (convo) => {
    let sid = this.state.mySid;
    if (sid == null) {
      sid = await this.state.twilioClient.getMe(
        convo,
        this.state.currentUser.email
      );
    }

    let messages = await this.getConversationMessages(sid, convo);
    this.setState({
      mySid: sid,
      currentConversation: convo,
      currentConversationMessages: messages,
    });

    setTimeout(this.scrollToBottom, 1000);
  };

  getConversations = (conversations) => {
    // console.log(conversations);
    let elements = [];
    for (let conversation of conversations) {
      elements.push(
        <ChatListItem
          selected={this.state.currentConversation === conversation}
          onClick={() => {
            console.log("Setting Convo");
            this.setConvo(conversation);
          }}
        >
          {conversation._internalState.friendlyName}
        </ChatListItem>
      );
    }
    elements.push(
      <div
        style={{ float: "left", clear: "both" }}
        ref={(el) => {
          this.messagesEnd = el;
        }}
      ></div>
    );
    return elements;
  };

  getConversationMessages = async (sid, conversation) => {
    if (!conversation) {
      return [];
    }

    let participants = await this.state.twilioClient.get_participants(
      conversation
    );
    conversation.participants = participants;

    let elements = [];
    for (let message of await this.state.twilioClient.get_message_history(
      conversation
    )) {
      let [friendlyName, isDispatcher, isMe] = this.getSenderInfo(
        conversation,
        message,
        participants,
        this.state.dispatchers,
        this.state.drivers,
        sid
      );
      let formattedDate = moment(message.state.dateUpdated).format(
        "DD/MM/YYYY hh:mm a"
      );
      elements.push(
        <div style={{ float: "left", width: "100%" }}>
          <ChatMessage isDispatcher={isDispatcher} senderName={friendlyName}>
            <ChatBody isDispatcher={isDispatcher}>
              {message.state.body}
            </ChatBody>
            <ChatInfo isDispatcher={isDispatcher}>
              {friendlyName} at {formattedDate}
            </ChatInfo>
          </ChatMessage>
        </div>
      );
    }
    return elements;
  };

  getSenderInfo = (
    conversation,
    message,
    participants,
    dispatchers,
    drivers,
    _mySid
  ) => {
    let friendlyName = "Unknown";
    let isDispatcher = false;
    let isMe = false;
    // console.log("My SID: " + _mySid);
    if (message.state.participantSid == _mySid) {
      // console.log("Is Me!");
      isMe = true;
      isDispatcher = true;
      friendlyName = "Me";
    } else {
      // console.log("Particpants");
      // console.log(participants);
      for (const [sid, participant] of participants.entries()) {
        // console.log(`${sid}: ${participant}`);
        if (participant.state.sid == message.state.participantSid) {
          // friendlyName = participant.state.identity;
          for (let dispatcher of dispatchers) {
            if (dispatcher.email == participant.state.identity) {
              // console.log("Dispatcher Found");
              // console.log(dispatcher);
              isDispatcher = true;
              friendlyName = dispatcher.first_name + " " + dispatcher.last_name;
              return [friendlyName, isDispatcher, isMe];
            }
          }
          // if (isDispatcher == true) {
          //   break;
          // }
          for (let driver of drivers) {
            if (
              participant.state.bindings.sms != null &&
              driver.phone ==
                participant.state.bindings.sms.address.replace("+1", "")
            ) {
              // console.log("Driver Found");
              friendlyName = driver.first_name + " " + driver.last_name;
              isDispatcher = false;
              return [friendlyName, isDispatcher, isMe];
            }
          }
          if (
            participant.state.bindings.sms != null &&
            friendlyName == "Unknown"
          ) {
            // console.log("SMS Found");
            friendlyName = participant.state.bindings.sms.address.replace(
              "+1",
              ""
            );
            isDispatcher = false;
            return [friendlyName, isDispatcher, isMe];
          }
        }
      }
    }

    return [friendlyName, isDispatcher, isMe];
  };

  close() {
    this.setState({ expanded: false });
  }

  open() {
    this.setState({ expanded: true });
  }

  render() {
    return (
      <div>
        <MediumModal visible={this.state.expanded} closeCallback={this.close}>
          <ModalHeader>
            Chat:{" "}
            {this.state.currentConversation
              ? this.state.currentConversation._internalState.friendlyName
              : "Choose A Chat..."}
          </ModalHeader>
          {/* <Button
          onClick={() => {
            console.log("Creating Chat");
            twilioClient.create_conversation("Dispatch - Chris");
          }}
        >
          Create Chat
        </Button> */}
          <MainChatContainer>
            <ChatListContainer>
              {this.getConversations(this.state.conversations)}
            </ChatListContainer>
            <ChatMessageContainer>
              {this.state.currentConversationMessages}
              <div
                ref={messagesEnd}
                style={{
                  float: "left",
                  width: "100%",
                  textAlign: "center",
                  margin: "10% 0",
                }}
              >
                End of Conversation
              </div>
            </ChatMessageContainer>
            <OutlinedInput
              fullWidth={true}
              multiline={true}
              minRows={5}
              maxRows={10}
              placeholder="Enter your message"
              value={this.state.message}
              onChange={(e) => {
                this.setState({ message: e.target.value });
              }}
            />
            <button
              onClick={() => {
                this.state.twilioClient.send_message(
                  this.state.currentConversation,
                  this.state.message
                );
                this.setState({ message: "" });
              }}
            >
              Send
            </button>
          </MainChatContainer>
        </MediumModal>
        <Button
          onClick={() => {
            this.open();
          }}
          endIcon={
            this.state.unreadMessages > 0 ? (
              <Feedback sx={{ color: "#ff0000" }} />
            ) : null
          }
        >
          Chat
        </Button>
      </div>
    );
  }
}

export default ChatComponent;
