import { MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { getDropNPickContainers } from '../api/containers';
import SearchBar from '../components/SearchBar';
import { dismissProgressOverlay, displayProgressOverlay } from '../store/busy';
import { titlize } from '../util/functions';
import SecuredView from './SecuredView';


const Table = styled.table`
color: black;
background: #8080802e;
width: 90%;
margin: 5%;
box-shadow: 8px 5px 5px 0px #aaa;
border-radius: 1rem;
overflow: hidden;
`;

const TR = styled.tr`
border-bottom: 0.5px solid black;
`;

const TH = styled.th`
font-size: 1.5rem;
font-weight: bold;
padding: 1%;
`;

const TD = styled.td`
width: ${(props) => props.width};
font-weight: ${(props) => props.fontWeight};
padding: 1%;
`;

class ContainerLocations extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            jobs_filtered: [],
            waitingFor: ["loadContainers"],
            locationFilter: "all"
        };

        this.searchCallback = this.searchCallback.bind(this);
    }

    showProgressOverlay(key) {
        this.props.displayProgressOverlay({id: key});
    }

    dismissProgressOverlay(key) {
        this.props.dismissProgressOverlay({id: key});
    }

    componentDidMount() {
        this.showProgressOverlay("loadContainers");
        getDropNPickContainers((results) => {
            this.setState({jobs: results, jobs_filtered: results});
            this.dismissProgressOverlay("loadContainers");
        })
    }

    getSearchOptions(jobs) {
        let options = [];

        jobs.map((job) => {
            job.job_containers.map((jc) => {
                options.push({
                    id: job.id,
                    label: `Container #${jc.container.number} Load#${jc.load_number}`,
                });
            });
        });

        return options;
    }

    searchCallback(matched) {
        console.log(matched);
        let jobs = this.state.jobs.filter((job) => matched.filter((m) => m.id = job.id).length > 0);
        console.log(jobs);
        this.setState({jobs_filtered: jobs});
    }

    getRows(jobs, isImport, locationFilter) {
        let filter = isImport ? ["drop_n_pick"] : ["export_drop_n_pick"];

        let available = [];
        let not_available = [];
        let scheduled = [];

        jobs.map((job) => {

            if (filter.includes(job.job_type)) {
                job.job_containers.map((jc) => {
                    let schedules = jc.schedules.filter((s) => s.is_delivery_location)
                    let deliverySchedule = null;
                    if (schedules.length > 0) {
                        deliverySchedule = schedules[0];
                    }
                    let returnSchedules = jc.schedules.filter((s) => {
                        if (s.appointment_time != null && s.pickup_location.location_type == "warehouse" && (s.drop_location.location_type == "yard" || s.drop_location.location_type == "terminal")) {
                            return s;
                        }});
                    let returnSchedule = null;
                    if (returnSchedules.length > 0) {
                        returnSchedule = returnSchedules[0];
                    }
                    let row = <TR style={{backgroundColor: isImport ? jc.is_loaded ? "red" : returnSchedule == null ? "lightgreen" : "lightskyblue" : jc.is_loaded ? returnSchedule == null ? "lightgreen" : "lightskyblue" : "red"}}>
                        <TD>#{jc.load_number}</TD>
                        <TD>#{jc.container.number}</TD>
                        <TD>{titlize(jc.container.container_type)}</TD>
                        <TD>{schedules.length > 0 ? `${schedules[0].drop_location.city},${schedules[0].drop_location.state}` : "---"}</TD>
                        <TD>{schedules.length > 0 && schedules[0].status == "dropped" ? `${new Date(schedules[0].appointment_time).toLocaleString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        })} (${moment().diff(moment(schedules[0].appointment_time), 'days')} days ago)` : "---"}
                        </TD>
                        <TD>
                            {jc.return_location.name}
                        </TD>
                        {isImport ? "" : <TD>{new Date(jc.earliest_recieve_date).toLocaleString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        })}</TD>}
                        <TD>{isImport ? jc.free_time_cut_off ? new Date(jc.free_time_cut_off).toLocaleString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        }) : "---" : jc.cut_off_date ? new Date(jc.cut_off_date).toLocaleString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        }) : "---"}</TD>
                        <TD>
                            {returnSchedule != null ? new Date(returnSchedule.appointment_time).toLocaleString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        }) : "---"}
                        </TD>
                        <TD>
                            {jc.is_loaded ? "Loaded" : "Empty"}
                        </TD>
                        <TD><Link to={`/admin-dashboard?load_id=${jc.id}`}>Go To</Link></TD>
                    </TR>

                    let shouldReturn = false;
                    if ((deliverySchedule != null && deliverySchedule.drop_location.name == locationFilter) || locationFilter == "all") {
                        shouldReturn = true;
                    }
                    if (shouldReturn) {
                        if (returnSchedule != null) {
                            scheduled.push(row);
                        } else if ((isImport && !jc.is_loaded) || (!isImport && jc.is_loaded)) {
                            available.push(row);
                        } else if ((isImport && jc.is_loaded) || (!isImport && !jc.is_loaded)) {
                            not_available.push(row);
                        }
                    }
                });
            }
        });

        let groups = [available, not_available, scheduled];

        return groups.map((group) => {
            return group.map((j) => j);
        });
    }

    getOptions(jobs) {
        let foundLocations = [];
        let options = [];

        jobs.map((j) => {
            j.job_containers.map((jc) => {
                let schedules = jc.schedules.filter((s) => s.is_delivery_location);

                if (schedules.length > 0) {
                    let deliverySchedule = schedules[0];
                    if (!foundLocations.includes(deliverySchedule.drop_location.name)) {
                        foundLocations.push(deliverySchedule.drop_location.name);
                        options.push(<MenuItem value={deliverySchedule.drop_location.name}>{deliverySchedule.drop_location.name}</MenuItem>);
                    }
                }
            });
        });

        return options;

    }

    render () {
        return <SecuredView accessLevels={['super_user', 'standard_user', 'hr_user']}>
            <div style={{ margin: "5%", display: 'flex' }}>
                <Select
                value={this.state.locationFilter}
                onChange={(e) => {this.setState({locationFilter: e.target.value})}}
                >   <MenuItem value={"all"}>All Locations</MenuItem>
                    {this.getOptions(this.state.jobs)}
                </Select>
                <SearchBar
                items={this.getSearchOptions(
                this.state.jobs
                )}
                onFound={this.searchCallback}
            />
            </div>
          <Table>
            <TR><TH colSpan={"8"}>Imports</TH></TR>
                <TR>
                    <TH>Load #</TH>
                    <TH>Container #</TH>
                    <TH>Type</TH>
                    <TH>Delivery Location</TH>
                    <TH>Delivered On</TH>
                    <TH>Return To</TH>
                    <TH>Free Cut-Off Date</TH>
                    <TH>Scheduled For</TH>
                    <TH>Loaded?</TH>
                    <TH style={{minWidth: "100px"}}>Link</TH>
                </TR>
                {this.getRows(this.state.jobs_filtered, true, this.state.locationFilter)}
            </Table>
            <Table>
                <TR><TH colSpan={"9"}>Exports</TH></TR>
                <TR>
                    <TH>Load #</TH>
                    <TH>Container #</TH>
                    <TH>Type</TH>
                    <TH>Delivery Location</TH>
                    <TH>Delivered On</TH>
                    <TH>Return To</TH>
                    <TH>Earliest Recv. Date</TH>
                    <TH>Cut-Off Date</TH>
                    <TH>Scheduled Pickup For</TH>
                    <TH>Loaded?</TH>
                    <TH style={{minWidth: "100px"}}>Link</TH>
                </TR>
                {this.getRows(this.state.jobs_filtered, false, this.state.locationFilter)}
            </Table>
            </SecuredView>
    }
}

function mapStateToProps(state){
    return{
      // alerts: state.alerts.alerts,
      // currentUser: state.user.user,
      // waitingFor: state.busy.waitingFor
    }
  }
  
  function mapDispatchToProps(dispatch){
    return {
      // addAlert: alert => dispatch(addAlert(alert)),
      // removeAlert: alert => dispatch(removeAlert(alert)),
      // setUser: user => dispatch(setUser(user)),
      dismissProgressOverlay: identifier => dispatch(dismissProgressOverlay(identifier)),
      displayProgressOverlay: identifier => dispatch(displayProgressOverlay(identifier)),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ContainerLocations);