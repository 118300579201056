const token = document.querySelector("[name=csrf-token]").content;

export var getAllContainers = (onSuccess, onError) => {
  return fetch(`/api/v1/containers`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getDropNPickContainers = (onSuccess, onError) => {
  return fetch(`/api/v1/containers-drop-pick`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};