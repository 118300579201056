import { object } from "prop-types";

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export const getDistance = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // km
  var dLat = toRad(lat2-lat1);
  var dLon = toRad(lon2-lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  console.log(`Distance: ${d * 1000}m`);
  return d * 1000;
}

// Converts numeric degrees to radians
function toRad(Value) 
{
    return Value * Math.PI / 180;
}

export const clusterObjectLocations = (objects) => {
    let clusters = [];
    let independant = [];
    objects.map((obj) => {
        let addedToCluster = false;
        if (clusters.length > 0) {
                clusters.map((c) => {
                    if (getDistance(obj.mapLocation.lat, obj.mapLocation.lng, c.mapLocation.lat, c.mapLocation.lng) <= 50) {
                        c.ids.push(obj.id);
                        c.numbers.push(obj.number);
                        addedToCluster = true;
                    }
                });
        } 

        if (!addedToCluster) {
            objects.map((obj2) => {
                if (obj.id != obj2.id) {
                    if (getDistance(obj.mapLocation.lat, obj.mapLocation.lng, obj2.mapLocation.lat, obj2.mapLocation.lng) <= 50) {
                        let matchedClusters = clusters.filter((c) => c.ids.includes(obj.id) || c.ids.includes(obj2.id));
                        console.log("Matched:")
                        console.log(matchedClusters);
                        if (matchedClusters.length > 0) {
                            matchedClusters[0].ids.push(obj.id);
                        } else {
                            clusters.push({ids: [obj.id, obj2.id], numbers: [obj.number, obj2.number], mapLocation: obj.mapLocation});
                        }
                    }
                }
            });
        }
    });

    // Make Id Array Unique
    clusters.map((c) => {
        c.numbers = c.numbers.filter(onlyUnique);
        c.ids = c.ids.filter(onlyUnique);
    });

    objects.map((obj) => {
        let matchedClusters = clusters.filter((c) => c.ids.includes(obj.id));

        if (matchedClusters.length == 0) {
            independant.push({id: obj.id, number: obj.number, mapLocation: obj.mapLocation});
        }
    });

    console.log(`Clusters:`);

    console.log(clusters);

    console.log(`Independant: ${independant}`);

    return [clusters, independant];
}

export const checkForPairedObjects = (trucks, chassis) => {

    let paired = [];
    let independantTrucks = [];
    let independantChasses = [];


    trucks.map((t) => {
        chassis.map((c) => {
            let distance = getDistance(t.mapLocation.lat, t.mapLocation.lng, c.mapLocation.lat, c.mapLocation.lng);
            console.log(`Truck: ${t.number} Ch: ${c.number} Distance: ${distance}meters`);
            if ( distance <= 300) {
                paired.push({truck: t, chassis: c});
            }
        });
    });

    console.log("Pairs: ");
    console.log(paired);

    independantTrucks = trucks.filter((t) => !paired.some((p) => p.truck.id == t.id));

    console.log("Trucks: ");
    console.log(independantTrucks);

    independantChasses = chassis.filter((c) => !paired.some((p) => p.chassis.id == c.id));

    console.log("Chassis: ");
    console.log(independantChasses);

    return [paired, independantTrucks, independantChasses];
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}