import { FormControl, InputLabel } from "@material-ui/core";
import { Button, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { titlize } from "../util/functions";
import SearchBar from "./SearchBar";
import {
  SearchBarContainer,
  SearchBarFull,
  SearchBarThird,
} from "./styled/Elements";

export default (props) => {
  const [searchOptions, setSearchOptions] = useState([]);
  const [filter, setFilter] = useState(props.initialFilter);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  React.useEffect(() => {
    console.log("Children");
    console.log(props.children);
    setSearchOptions(props.searchItems);
    setPage(props.currentPage);
    setMaxPages(props.maxPages);
  }, [
    props.searchItems,
    props.filterOptions,
    props.maxPages,
    props.currentPage,
    props.children,
  ]);

  const onFilter = (filter) => {
    if (props.onFilter) {
      props.onFilter(filter);
    }
    setFilter(filter);
  };

  const onPageChange = (newPage, newPerPage) => {
    props.onPage(newPage, newPerPage);
    setPage(newPage);
    setPerPage(newPerPage);
  };

  return (
    <SearchBarContainer>
      <SearchBarFull>
        {props.filterOptions ? (
          <FormControl style={{ float: "left", marginRight: "1%" }}>
            <Select
              style={{ float: "left", width: 200 }}
              value={filter}
              onChange={(e) => onFilter(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {props.filterOptions.map((option) => (
                <MenuItem value={option}>{titlize(option)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <></>
        )}
        <SearchBar
          label={props.searchLabel}
          items={searchOptions}
          onFound={props.onSearchFound}
          onNotFound={(searchString) => {
            if (props.onSearchNotFound) {
              props.onSearchNotFound(searchString);
            } else {
              console.log(`Search not Implimented: ${searchString}`);
            }
          }}
        />
      </SearchBarFull>
      {props.onPage ? (
        <>
          <SearchBarThird>
            <Select
              style={{ float: "left" }}
              value={perPage}
              onChange={(e) => onPageChange(page, e.target.value)}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Button
              style={{ fontSize: "1.5rem" }}
              disabled={page <= 1}
              onClick={() => onPageChange(page - 1, perPage)}
            >
              Previous
            </Button>
            {`Page: ${page} / ${maxPages}`}
            <Button
              style={{ fontSize: "1.5rem" }}
              disabled={page >= maxPages}
              onClick={() => onPageChange(page + 1, perPage)}
            >
              Next
            </Button>
          </SearchBarThird>
        </>
      ) : (
        <></>
      )}
      {props.children}
    </SearchBarContainer>
  );
};
