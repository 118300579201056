import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import user from './user'
import alerts from './alerts'
import busy from './busy'
const reducer = combineReducers({
  user,
  alerts,
  busy
})
const store = configureStore({
  reducer,
})
export default store;