const token = document.querySelector("[name=csrf-token]").content;

export var getToken = (load_id, onSuccess, onError) => {
    return fetch(`/api/v1/load-tracking`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json"  },
      method: "POST",
      body: JSON.stringify({load_id: load_id})
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };


  export var getTrackingInfo = (jwt, onSuccess, onError) => {
    return fetch(`/api/v1/load-tracking/retrieve`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({jwt: jwt})
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };