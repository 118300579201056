export const imports = ["drop_n_pick", "live_unload", "shipper_owned"];
export const exports = [
  "export_drop_n_pick",
  "export_live_load",
  "export_shipper_owned",
];

export const ClientNotesAdd = ["super_user"];
export const ClientBillingNotesAdd = ["super_user"];
export const JobNotesAdd = ["super_user", "standard_user"];
export const JobBillingNotesAdd = ["super_user"];
export const ClientNotesView = ["super_user", "standard_user", "billing_user"];
export const ClientBillingNotesView = ["super_user", "billing_user"];
export const JobNotesView = ["super_user", "standard_user", "billing_user"];
export const JobBillingNotesView = ["super_user", "billing_user"];

export const BillingAccess = ["super_user", "billing_user"];
export const ApprovalAccess = ["super_user"];

export const HRAccess = ["super_user", "hr_user"];
