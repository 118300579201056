import React, { Fragment } from "react";
import styled from "styled-components";
import { getAllTrucks, getAllTrucksOnMotive } from "../api/trucks";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContactlessIcon from '@material-ui/icons/Contactless';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import MapView from "./MapView";
import NewVehicleForm from "./forms/NewVehicleForm";
import { findMotiveMatch } from "../helpers/trucks";
import { ComplianceForm } from "./forms/ComplianceForm";
import { Button } from "@material-ui/core";
import { Add } from "@mui/icons-material";
import { ComplianceBadge } from "./styled/Badges";

const Table = styled.table`
color: black;
background: #8080802e;
width: 90%;
margin: 5%;
box-shadow: 8px 5px 5px 0px #aaa;
border-radius: 1rem;
overflow: hidden;
`;

const TR = styled.tr`
border-bottom: 0.5px solid black
`;

const TH = styled.th`
font-size: 1.5rem;
font-weight: bold;
padding: 1%;
`;

const TD = styled.td`
width: ${(props) => props.width};
font-weight: ${(props) => props.fontWeight};
padding: 1%;
`;

class TruckList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        trucks: [],
        motiveTrucks: [],
        truckLocation: null,
        truckFormOpen: false
      };

      this.handleTruckFormClose = this.handleTruckFormClose.bind(this);
      this.onComplianceFormClose = this.onComplianceFormClose.bind(this);
    }
  
    componentDidMount() {
      getAllTrucks((result) => {
        this.setState({ trucks: result });
      }, null, []);
      
      getAllTrucks((result) => {
        this.setState({ trucks: result });
      }, null, ["compliance_items"]);

      // getAllTrucksOnMotive((result) => {
      //   this.setState({ motiveTrucks: result.vehicles });
      // });
    }

    
    setTruck(truck) {
        this.setState({currentTruck: truck, truckLocation: {zoom: 15, lat: parseFloat(truck.truck_snapshot.location.latitude), lon: parseFloat(truck.truck_snapshot.location.longitude)}});
    }

    getTruckRows(trucks) {
        return trucks.map((t) => {
            // let motiveData = findMotiveMatch(this.state.motiveTrucks, t.number)
            let upcomingRenewals = [];
            let criticalUpcomingRenewals = [];
            if (t.compliance_items) {
              upcomingRenewals = t.compliance_items.filter((c) => c.days_left <= 30 && c.days_left > 15);
              criticalUpcomingRenewals = t.compliance_items.filter((c) => c.days_left <= 15);
            } 
            return <TR>
                <TD>
                    {t.number}
                </TD>
                <TD>
                    {t.plate}
                </TD>
                <TD>
                    {t.vin}
                </TD>
                <TD>
                    {t.truck_snapshot && t.truck_snapshot.location_name ? t.truck_snapshot.location_name : t.truck_snapshot && t.truck_snapshot.location ? t.truck_snapshot.location.location_name  : "Unkown"}
                </TD>
                <TD>
                    {t.truck_snapshot && t.truck_snapshot.location ? <ContactlessIcon style={{color: t.truck_snapshot.location != null ? "lightgreen" : "grey"}} sx={{ fontSize: 30 }} /> : ""}
                    {t.truck_snapshot && t.truck_snapshot.location ? <GpsFixedIcon onClick={() => this.setTruck(t)} style={{color: t.truck_snapshot.location &&  t.location != null ? "lightgreen" : "grey"}} sx={{ fontSize: 30 }} /> : ""}
                </TD>
                <TD>
                  {upcomingRenewals.length > 0 ? <ComplianceBadge style={{color: "black",backgroundColor: "yellow"}}>{upcomingRenewals.length}</ComplianceBadge> : <></>}
                  {criticalUpcomingRenewals.length > 0 ? <ComplianceBadge>{criticalUpcomingRenewals.length}</ComplianceBadge> : <></>}
                  <Button startIcon={<Add />} onClick={() => this.openComplianceForm(t)}>Add Compliance</Button>
                </TD>
            </TR>
        });
    }

    openTruckForm() {
        this.setState({truckFormOpen: true});
    }

    handleTruckFormClose() {
        getAllTrucks((result) => {
            this.setState({ trucks: result });
        });

        getAllTrucksOnMotive((result) => {
            this.setState({ motiveTrucks: result.vehicles, truckFormOpen: false });
        });
    }

    openComplianceForm(truck) {
      this.setState({complianceFormOpen: true, currentTruck: truck});
    }

    onComplianceFormClose() {
      this.setState({complianceFormOpen: false, currentTruck: null});
    }

    render() {
        return(
        <Fragment><Table>
            <TR>
              <TH>#</TH>
              <TH>Plate</TH>
              <TH>Vin</TH>
              <TH>Status</TH>
              <TH>Compliance</TH>
              <TH><AddCircleIcon sx={{ fontSize: 30 }} onClick={() => this.openTruckForm()}/></TH>
            </TR>
            {this.getTruckRows(this.state.trucks)}
          </Table>
          <NewVehicleForm visible={this.state.truckFormOpen} onClose={this.handleTruckFormClose}/>
        <MapView truckInfo={this.state.currentTruck} center={this.state.truckLocation != null ? {lat: this.state.truckLocation.lat, lng: this.state.truckLocation.lon} : null } />
          <ComplianceForm visible={this.state.complianceFormOpen} onClose={this.onComplianceFormClose} type="truck" obj={this.state.currentTruck} />
          </Fragment>)
    }
}

export default TruckList;