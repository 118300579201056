import { Button, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import { Save } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import React, { useState } from 'react'
import { createComplianceItem, renewComplianceItem } from '../../api/compliance';
import { titlize } from '../../util/functions';
import { MediumModal, ModalHeader, SmallModal } from '../Modals'


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: "90%",
    },
    addIcon: {
        margin: "1%"
    },
    button: {
        margin: theme.spacing(2),
        minWidth: "25%",
        height: "100%"
      },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  }));

export const ComplianceForm = (props) => {
    const classes = useStyles();
    const [objId, setObjId] = useState(-1);
    const [objName, setObjName] = useState("");
    const [objType, setObjType] = useState();
    const [complianceType, setComplianceType] = useState();
    const [expiresOn, setExpiresOn] = useState(moment());
    const [renewedOn, setRenewedOn] = useState();
    const [compliance, setCompliance] = useState();

    React.useEffect(() => {
       
        if (props.obj) {
            let name = "";
            switch(props.type) {
                case "truck":
                    name = `Truck: ${props.obj.number} - ${props.obj.plate}`
                    break;
                case "driver":
                    name = `Driver: ${props.obj.first_name} ${props.obj.last_name}`
                    break;
                case "chassis":
                    name = `Chassis: ${props.obj.number} - ${props.obj.plate}`
                    break;
            }
            setObjName(name);
            setObjType(props.type);
            setObjId(props.obj.id);
        }

        if (props.compliance) {
            setCompliance(props.compliance);
        }
        
    }, [props.visible, props.type, props.obj])

    const getComplancOptions = (type) => {
        if (type == "truck") {
            return [
            <MenuItem value="ifta">IFTA</MenuItem>,
            <MenuItem value="hut">HUT</MenuItem>,
            <MenuItem value="registration">Registration</MenuItem>,
            <MenuItem value="bob_tail_insurance">Bob Tail Insurance</MenuItem>,
            <MenuItem value="smoke_inspection">Smoke Inspection</MenuItem>,
            <MenuItem value="annual_inspection">Annual Inspection</MenuItem>
            ]
        }

        if (type == "driver") {
            return [
            <MenuItem value="license">License</MenuItem>,
            <MenuItem value="drug_screening">Drug Screening</MenuItem>,
            <MenuItem value="sea_link">Sea Link</MenuItem>,
            <MenuItem value="twic">TWIC</MenuItem>,
            <MenuItem value="clearing_house">Clearinghouse Inquiry</MenuItem>,
            <MenuItem value="medical_card">Medical Card</MenuItem>,
            ]
        }

        if (type == "chassis") {
            return [
            <MenuItem value="registration">Registration</MenuItem>,
            <MenuItem value="inspection">Inspection</MenuItem>,
            ]
        }
    }

    const submit = () => {
        let payload = {obj_type: objType, item_type: complianceType, expires_on: expiresOn};

        if (compliance) {
            payload.id = compliance.id;
            payload.renewed_on = renewedOn;
        } else {
            payload.id = objId;

            console.log(payload);
            
            createComplianceItem(payload, (result) => {
                props.onClose();
            });
        }
    }

    return <MediumModal visible={props.visible} closeCallback={props.onClose}>
        <ModalHeader>{!compliance ? `Add Compliance` : `Edit Compliance`} / {objName}</ModalHeader>
        <FormControl className={classes.formControl}>
            <InputLabel>Compliance Type</InputLabel>
            <Select
            value={complianceType}
            onChange={(e) => setComplianceType(e.target.value)}
            >
                {getComplancOptions(objType)}
            </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DatePicker
                    label="Expires On"
                    inputFormat="MM/DD/YYYY"
                      value={expiresOn}
                      onChange={setExpiresOn}
                    renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
            </LocalizationProvider>
        </FormControl>
            { compliance ? 
                <FormControl className={classes.formControl}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DatePicker
                            label="Renewed On"
                            inputFormat="MM/DD/YYYY"
                            value={renewedOn}
                            onChange={setRenewedOn}
                            renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </FormControl> : <></>
            }
        <FormControl className={classes.formControl}>
            <Button startIcon={<Save />} onClick={() => submit()} >{compliance ? "Save" : "Create"}</Button>
        </FormControl>
        
    </MediumModal>;
}

export const ComplianceRenewalForm = (props) => {
    const classes = useStyles();
    const [id, setId] = useState(-1);
    const [renewedOn, setRenewedOn] = useState(moment());
    const [expiresOn, setExpiresOn] = useState(moment());

    React.useEffect(() => {
       
        setId(props.itemId);
        
    }, [props.visible, props.type, props.itemId, props.objName])

    const renew = () => {
        let payload = {id: id, expires_on: expiresOn, renewed_on: renewedOn};
        console.log(payload);
        renewComplianceItem(payload, (result) => {
            props.onClose();
        });
        
    }

    return <SmallModal visible={props.visible} closeCallback={props.onClose}>
        <ModalHeader>Renew {props.objName} - {titlize(props.type)}</ModalHeader>
        <FormControl className={classes.formControl}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DatePicker
                    label="Renewed On"
                    inputFormat="MM/DD/YYYY"
                      value={renewedOn}
                      onChange={setRenewedOn}
                    renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DatePicker
                    label="Expires On"
                    inputFormat="MM/DD/YYYY"
                      value={expiresOn}
                      onChange={setExpiresOn}
                    renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
            </LocalizationProvider>
        </FormControl>
        <Button  variant="outlined" onClick={() => renew()}>Renew</Button>
    </SmallModal>
}