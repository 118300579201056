import { createStyles, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React, { useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiAutocomplete-input": {
        fontSize: "1.5rem"
      },
      "& .MuiAutocomplete-listbox": {
        "& li:nth-child(even)": { backgroundColor: "#CCC" },
        "& li:nth-child(odd)": { backgroundColor: "#FFF" },
        '&:hover': {
          backgroundColor: '#9c27b0',
        },
      },
    }
  })
);

export default (props) => {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");

  React.useEffect(() => {
    console.log(props.items);

    setItems(props.items);
  }, [props.items]);

  const handleSearch = (e) => {
    console.log(e.target.value);
    setValue(e.target.value);

    if (e.target.value == null || e.target.value == "") {
      props.onFound(null);
      return;
    }

    let found = [];

    found = items.filter((item) => item.label.includes(e.target.value));

    if (found.length > 0) {
      props.onFound([...new Set(found)]);
    } else {
      props.onFound([]);
    }
  };

  const search = (searchText) => {
    if (props.onNotFound != null) {
      props.onNotFound(searchText);
    } else {
      props.onFound([]);
    }
  }

  return (
    <div style={{ float: "left", width: "80%" }}>
      <Autocomplete
        classes={classes}
        size="medium"
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        value={value}
        options={items ? items.map((i) => i.label) : []}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label ? props.label : "Search"}
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onSelect={handleSearch}
        onKeyDown={(e) => {
          if ((e.code === 'Enter' || e.code === 'NumpadEnter') && e.target.value) {
            search(e.target.value);
          }
        }}
      />
    </div>
  );
};
