import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { LargeModal, ModalHeader, SmallModal } from '../Modals';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { customerCreate, customerUpdate } from '../../api/customers';
import Button from '@material-ui/core/Button';
import { Input } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: "90%",
    },
    addIcon: {
        margin: "1%"
    },
    button: {
        margin: theme.spacing(2),
        minWidth: "25%",
        height: "100%"
      },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  }));

export const NewCustomerForm = (props) => {
    const classes = useStyles();

    const [customerName, setCustomerName] = useState("");
    const [customerAddress1, setCustomerAddress1] = useState("");
    const [customerAddress2, setCustomerAddress2] = useState("");
    const [customerCity, setCustomerCity] = useState("");
    const [customerState, setCustomerState] = useState("");
    const [customerZip, setCustomerZip] = useState("");

    const updateCustomerName = (event) => {
        setCustomerName(event.target.value);
    }

    const updateAddress1 = (event) => {
        setCustomerAddress1(event.target.value);
    }

    const updateAddress2 = (event) => {
        setCustomerAddress2(event.target.value);
    }


    const updateCustomerCity = (event) => {
        setCustomerCity(event.target.value);
    }

    const updateCustomerState = (event) => {
        setCustomerState(event.target.value);
    }


    const updateCustomerZip = (event) => {
        setCustomerZip(event.target.value);
    }

    React.useEffect(() => {

        if (props.customer != null) {
            let customer = props.customer;
            setCustomerName(customer.name);
            setCustomerAddress1(customer.address_1);
            setCustomerAddress2(customer.address_2);
            setCustomerCity(customer.city);
            setCustomerState(customer.state);
            setCustomerZip(customer.zip);
        }
            
      }, [props.visible, props.customer])


    const submit = () => {
        if (props.customer) {
            customerUpdate({"id": props.customer.id, "name": customerName, "address_1": customerAddress1, "address_2": customerAddress2, "city": customerCity, "state": customerState, "zip": customerZip}, (result) => {
                props.onClose(result);
            });
        } else {
            customerCreate({"name": customerName, "address_1": customerAddress1, "address_2": customerAddress2, "city": customerCity, "state": customerState, "zip": customerZip}, (result) => {
                props.onClose(result);
            });
        }
    }

    return (
        <SmallModal visible={props.visible} closeCallback={props.onClose} zIndex={"100"}>
            <ModalHeader>{ props.customer ? "Edit Customer" : "Add Customer"}</ModalHeader>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Company Name</InputLabel>
                <Input type={"text"} value={customerName} onChange={updateCustomerName}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Address #1</InputLabel>
                <Input type={"text"} value={customerAddress1} onChange={updateAddress1}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Address #2</InputLabel>
                <Input type={"text"} value={customerAddress2} onChange={updateAddress2}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">City</InputLabel>
                <Input type={"text"} value={customerCity} onChange={updateCustomerCity}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">State</InputLabel>
                <Input type={"text"} value={customerState} onChange={updateCustomerState}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="customer-select-label">Zip</InputLabel>
                <Input type={"text"} value={customerZip} onChange={updateCustomerZip}/>
            </FormControl>
            <Button className={classes.button} variant="contained" onClick={() => submit()}>{ props.customer ? "Update" : "Create"}</Button>
        </SmallModal>
    );
}

export default NewCustomerForm;