import React, { Fragment } from "react";
import styled from "styled-components";
import { LargeModal, ModalHeader } from "./Modals";
import { copyTable, titlize } from "../util/functions";
import Select from "@material-ui/core/Select";
import {
  ScheduleStatusOptions,
  getStatusColor,
  ContainerStatusOptions,
  getNoteColor,
  getContainerTypeOptions,
  getSteamShipLineOptions,
  getLocationIcon,
} from "./forms/Options";
import {
  setScheduleStatus,
  setJobContainerStatus,
  setJobContainerLoadStatus,
  setJobContainerLocations,
  setDriverFee,
  updateJobContainerInfo,
  associateContainer,
} from "../api/jobs";
import moment from "moment";
import {
  AddCircle,
  AttachMoney,
  CopyAll,
  Edit,
  Money,
  MoneyRounded,
  NoteAddRounded,
  Save,
  ThirtyFpsRounded,
} from "@mui/icons-material";
import { CheckBox, ThirteenMp } from "@mui/icons-material";
import NewScheduleForm from "./forms/NewScheduleForm";
import {
  Autocomplete,
  Button,
  FormControl,
  FormLabel,
  getAccordionActionsUtilityClass,
  Stack,
  TextField,
} from "@mui/material";
import {
  invoiceHeaderInlineStyle,
  invoiceLineItemsInlineStyle,
  invoiceTableInlineStyle,
  Pill,
  POD,
} from "./Components";
import NewNoteForm from "./forms/NewNoteForm";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  BillingAccess,
  ClientBillingNotesAdd,
  ClientBillingNotesView,
  ClientNotesAdd,
  ClientNotesView,
  HRAccess,
  imports,
  JobBillingNotesAdd,
  JobBillingNotesView,
  JobNotesAdd,
  JobNotesView,
} from "../util/constants";
import { getAllLocations } from "../api/locations";
import { getInvoicesForLoad } from "../api/invoices";
import Billing from "./Billing";
import {
  Box,
  ButtonBase,
  ButtonGroup,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import { getAllContainers } from "../api/containers";
import { Delete } from "@material-ui/icons";
import { deleteNote } from "../api/notes";
import { getToken } from "../api/tracking";
import { connect } from "react-redux";
import { addAlert, createAlert, removeAlert } from "../store/alerts";
import { dismissProgressOverlay, displayProgressOverlay } from "../store/busy";
import { getJobContainer } from "../api/active_work";

const ContainerInfoDiv = styled.div`
  float: left;
  width: 20%;
  margin: 0 0;
  display: block;
  padding: 2%;
`;

const ContainerInfo = styled.p`
  font-size: 1rem;
`;

const ContainerScheduleDiv = styled.div`
  float: left;
  width: 70%;
  margin: 5% 0;
  display: block;
  padding: 1%;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
const TR = styled.tr`
  border-bottom: 1px solid black;
`;
const TH = styled.th``;
const TD = styled.td``;

const useStyles = (theme) => ({
  formControl: {
    margin: theme.spacing(2),
    width: "90%",
  },
  addIcon: {
    margin: "1%",
  },
  button: {
    margin: theme.spacing(2),
    minWidth: "25%",
    height: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Container extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      currentUser: props.currentUser,
      job: props.job,
      jobContainer: props.jobContainer,
      container:
        props.jobContainer != null ? props.jobContainer.container : null,
      schedules: [],
      scheduleFormOpen: false,
      scheduleNoteFormOpen: false,
      locations: [],
      currentNoteType: "customer",
      currentId: null,
      invoices: [],
      billingViewOpen: false,
      driverFees: {},
      isEdit: false,
      containers: [],
    };

    this.clearContainer = this.clearContainer.bind(this);
    this.handleScheduleFormClose = this.handleScheduleFormClose.bind(this);
    this.handleNoteFormClose = this.handleNoteFormClose.bind(this);
    this.handleBillignViewClose = this.handleBillignViewClose.bind(this);
    this.handlePODClose = this.handlePODClose.bind(this);
  }

  componentDidMount() {
    if (this.props.jobContainer) {
      getJobContainer(this.props.jobContainer.jc_id, (jc) => {
        this.setState({
          jobContainer: jc,
          job: this.props.job,
          container: jc.container,
        });

        getInvoicesForLoad(jc.id, (result) => {
          this.setState({ invoices: result });
        });
      });
    }
  }

  handleDriverFeeUpdated(fee) {
    let fees = { ...this.state.driverFees };
    fees[fee.id] = fee.amount;
    this.setState({ driverFees: fees });
  }

  handleDriverFee(fee) {
    setDriverFee(fee.id, fee.amount, (result) => {});
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.jobContainer !== this.props.jobContainer &&
      this.props.jobContainer
    ) {
      getAllLocations((result) => {
        console.log("Locations Loaded!");
        this.setState({ locations: result });
      });

      getAllContainers((results) => {
        this.setState({ containers: results });
      });

      getJobContainer(this.props.jobContainer.jc_id, (jc) => {
        let driverFees = {};

        if (jc != null) {
          jc.schedules.map((s) => {
            if (s.driverFees != null) {
              driverFees[`sched-${s.id}`] =
                s.driver_fees.length > 0 ? s.driver_fees[0].amount : 0.0;
            }
          });
        }

        this.setState({
          jobContainer: jc,
          job: this.props.job,
          container: jc.container,
          schedules: jc ? jc.schedules : [],
          driverFees: driverFees,
        });

        getInvoicesForLoad(jc.id, (result) => {
          this.setState({ invoices: result });
        });
      });
    }

    if (prevProps.currentUser !== this.props.currentUser) {
      this.setState({
        currentUser: this.props.currentUser,
      });
    }

    console.log(this.state.jobContainer);
  }

  clearContainer() {
    this.props.onClose();
    this.setState({
      container: null,
      jobContainer: null,
      job: null,
      currentSchedule: null,
    });
  }

  updateContainer(number, ssl, size) {
    this.props.displayProgressOverlay({ id: "update" });
    console.log(number);
    console.log(this.state.jobContainer);
    let found = number
      ? this.state.containers.filter((c) => c.number == number)
      : [];
    if (found.length > 0) {
      console.log("Found");
      let c = found[0];
      if (ssl) {
        c.ship_line = ssl;
      }

      if (size) {
        c.container_type = size;
      }
      this.props.dismissProgressOverlay({ id: "update" });
      this.setState({
        jobContainer: {
          ...this.state.jobContainer,
          container: c,
          container_id: c.id,
        },
      });
    } else {
      console.log("Not Found");
      if (this.state.jobContainer.container != null) {
        let container = {
          number: this.state.jobContainer.container.number,
          ship_line: this.state.jobContainer.container.ship_line,
          container_type: this.state.jobContainer.container.container_type,
        };

        container.number = number
          ? number
          : this.state.jobContainer.container.number;
        container.ship_line = ssl
          ? ssl
          : this.state.jobContainer.container.ship_line;
        container.container_type = size
          ? size
          : this.state.jobContainer.container.container_type;

        console.log(container);

        this.setState({
          jobContainer: { ...this.state.jobContainer, container: container },
        });
      } else {
        let container = {};
        if (number != null) {
          container.number = number;
        }

        if (ssl != null) {
          container.ship_line = ssl;
        }

        if (size != null) {
          container.container_type = size;
        }

        console.log(container);

        this.setState({
          jobContainer: { ...this.state.jobContainer, container: container },
        });
      }
      this.props.dismissProgressOverlay({ id: "update" });
    }
  }

  associateContainer() {
    this.props.displayProgressOverlay({ id: "update" });
    associateContainer(
      this.state.jobContainer.id,
      this.state.jobContainer.container,
      (result) => {
        this.props.dismissProgressOverlay({ id: "update" });
        this.setState({
          jobContainer: result,
          container: result.container,
          isEdit: false,
        });
      }
    );
  }

  updateLocations(pickupLocation, returnLocation) {
    console.log(pickupLocation);
    console.log(returnLocation);
    let payload = { job_container_id: this.state.jobContainer.id };

    payload.load_number = this.state.jobContainer.load_number;
    payload.pickup_location_name = this.state.jobContainer.pickup_location.name;
    payload.return_location_name = this.state.jobContainer.return_location.name;

    let pickupChanged = false;
    let dropChanged = false;

    if (pickupLocation != null) {
      let found = this.state.locations.filter((l) => l.name == pickupLocation);
      if (found.length > 0) {
        pickupChanged =
          this.state.jobContainer.origin_location_id.$oid != found[0].id;
        payload.origin_location_id = found[0].id;
        payload.pickup_location_name = pickupLocation;
      }
    }

    if (returnLocation != null) {
      let found = this.state.locations.filter((l) => l.name == returnLocation);
      if (found.length > 0) {
        console.log(this.state.jobContainer.return_location_id.$oid);
        console.log(found[0].id);
        dropChanged =
          this.state.jobContainer.return_location_id.$oid != found[0].id;
        payload.return_location_id = found[0].id;
        payload.return_location_name = returnLocation;
      }
    }

    // Only update if changed
    console.log(pickupChanged);
    console.log(dropChanged);
    if (!pickupChanged && !dropChanged) {
      return;
    }

    this.props.displayProgressOverlay({ id: "update" });

    setJobContainerLocations(payload, (result) => {
      this.setState({ jobContainer: result });
      this.props.dismissProgressOverlay({ id: "update" });
    });
  }

  updateLoadInfo(
    bookingNumber,
    bol,
    seal,
    weight,
    commodity,
    qty,
    genset_number
  ) {
    this.props.displayProgressOverlay({ id: "update" });
    let payload = {
      id: this.state.jobContainer.id,
      booking_number: bookingNumber
        ? bookingNumber
        : this.state.jobContainer.booking_number,
      bol: bol ? bol : this.state.jobContainer.bol,
      qty: qty ? qty : this.state.jobContainer.qty,
      genset_number: genset_number
        ? genset_number
        : this.state.jobContainer.genset_number,
      seal: seal ? seal : this.state.jobContainer.seal,
      weight: weight
        ? parseFloat(weight).toFixed(1)
        : this.state.jobContainer.weight,
      commodity: commodity ? commodity : this.state.jobContainer.commodity,
    };

    console.log(payload);

    updateJobContainerInfo(payload, (result) => {
      this.setState({ jobContainer: result });
      this.props.dismissProgressOverlay({ id: "update" });
    });
  }

  updateScheduleStatus(event, scheduleId) {
    let newSchedules = this.state.schedules;

    let schedule;

    newSchedules.forEach((s) => {
      if (s.id == scheduleId) {
        s.status = event.target.value;
        schedule = s;
      }
    });

    setScheduleStatus(schedule, (result) => {
      this.setState({ schedules: newSchedules, container: result.container });
    });
  }

  updateJobContainerStatus(eta, lfd, ftc, erd, cod, status, dependencies) {
    let jobContainer = this.state.jobContainer;

    if (eta != null) {
      jobContainer.eta = eta;
    }

    if (lfd != null) {
      jobContainer.last_free_day = lfd;
    }

    if (ftc != null) {
      jobContainer.free_time_cut_off = ftc;
    }

    if (erd != null) {
      jobContainer.earliest_recieve_date = erd;
    }

    if (cod != null) {
      jobContainer.cut_off_date = cod;
    }

    if (status != null) {
      jobContainer.status = status;
    }

    if (dependencies != null) {
      jobContainer.dependencies = dependencies.filter((d) => d != "None");
    }

    setJobContainerStatus(jobContainer, (result) => {
      this.setState({ jobContainer: result });
    });
  }

  openClientNoteForm(type, note) {
    this.setState({
      clientNoteFormOpen: true,
      currentNoteType: type,
      currentId:
        type == "job" || type == "job_billing"
          ? this.state.job.id
          : this.state.job.customer.id,
      currentNote: note,
    });
  }

  openNoteScheduleForm(schedule, note) {
    this.setState({
      scheduleNoteFormOpen: true,
      currentSchedule: schedule,
      currentNoteType: "schedule",
      currentId: schedule.id,
      currentNote: note,
    });
  }

  handleNoteFormClose(newNote) {
    console.log("Note form close: ");
    console.log(newNote);
    if (newNote != null && newNote.note_type == "schedule") {
      let scheduleList = [...this.state.schedules];

      scheduleList.forEach((s) => {
        s.notes = s.notes.filter((n) => n.id != newNote.id);
        if (s.id == newNote.schedule_id) {
          s.notes.push(newNote);
        }
      });

      this.setState({
        schedules: scheduleList,
        currentSchedule: null,
        scheduleNoteFormOpen: false,
        clientNoteFormOpen: false,
        currentNote: null,
      });
    } else if (
      newNote != null &&
      (newNote.note_type == "customer" ||
        newNote.note_type == "customer_billing")
    ) {
      let job = { ...this.state.job };

      job.customer.notes = job.customer.notes.filter((n) => n.id != newNote.id);

      job.customer.notes.push(newNote);

      this.setState({
        job: job,
        currentSchedule: null,
        scheduleNoteFormOpen: false,
        clientNoteFormOpen: false,
        currentNote: null,
      });
    } else {
      let job = { ...this.state.job };

      job.notes = job.notes.filter((n) => n.id != newNote.id);

      job.notes.push(newNote);

      this.setState({
        job: job,
        currentSchedule: null,
        scheduleNoteFormOpen: false,
        clientNoteFormOpen: false,
        currentNote: null,
      });
    }
  }

  handleNoteDeleted(deletedNote) {
    if (deletedNote != null && deletedNote.note_type == "schedule") {
      let scheduleList = [...this.state.schedules];

      scheduleList.forEach((s) => {
        if (s.id == deletedNote.schedule_id) {
          let notes = s.notes.filter((n) => n.id != deletedNote.id);
          s.notes = notes;
        }
      });

      this.setState({
        schedules: scheduleList,
        currentSchedule: null,
        scheduleNoteFormOpen: false,
        clientNoteFormOpen: false,
        currentNote: null,
      });
    } else if (
      deletedNote != null &&
      (deletedNote.note_type == "customer" ||
        deletedNote.note_type == "customer_billing")
    ) {
      let job = { ...this.state.job };

      job.customer.notes = job.customer.notes.filter(
        (n) => n.id != deletedNote.id
      );

      this.setState({
        job: job,
        currentSchedule: null,
        scheduleNoteFormOpen: false,
        clientNoteFormOpen: false,
        currentNote: null,
      });
    } else {
      let job = { ...this.state.job };

      job.notes = job.notes.filter((n) => n.id != deletedNote.id);

      this.setState({
        job: job,
        currentSchedule: null,
        scheduleNoteFormOpen: false,
        clientNoteFormOpen: false,
        currentNote: null,
      });
    }
  }

  getSchedules(schedules) {
    schedules.sort((a, b) => {
      if (b.appointment_time == null && a.appointment_time == null) {
        return 0;
      }
      if (b.appointment_time == null) {
        return -1;
      }
      if (a.appointment_time == null) {
        return 1;
      }
      return (
        moment(a.appointment_time).unix() - moment(b.appointment_time).unix()
      );
    });
    return schedules.map((schedule) => {
      return (
        <Fragment>
          <TR
            key={schedule.id}
            style={{
              backgroundColor: schedule.is_delivery_location ? "green" : "",
              color: schedule.is_delivery_location ? "white" : "",
            }}
          >
            <TD>
              {schedule.pickup_location
                ? getLocationIcon(schedule.pickup_location.location_type)
                : ""}
              {schedule.pickup_location ? schedule.pickup_location.name : "---"}
            </TD>
            <TD>
              {schedule.drop_location
                ? getLocationIcon(schedule.drop_location.location_type)
                : ""}
              {schedule.drop_location
                ? schedule.is_delivery_location
                  ? `${schedule.drop_location.city},${schedule.drop_location.state}`
                  : schedule.drop_location.name
                : "---"}
            </TD>
            <TD>
              {schedule.appointment_time
                ? new Date(schedule.appointment_time).toLocaleString("en-us", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })
                : "---"}
            </TD>
            <TD>
              <Pill color={getStatusColor(schedule.status)}>
                <Select
                  disableUnderline
                  labelId={"status_select_" + schedule.id}
                  id={"status_select_" + schedule.id}
                  value={schedule.status}
                  onChange={(e) => this.updateScheduleStatus(e, schedule.id)}
                >
                  {ScheduleStatusOptions()}
                </Select>
              </Pill>
            </TD>
            <TD>
              {schedule.driver
                ? `${schedule.driver.first_name} ${schedule.driver.last_name}`
                : "---"}
            </TD>
            <TD>{schedule.truck ? schedule.truck.number : "---"}</TD>
            <TD>{schedule.chassis ? schedule.chassis.number : "---"}</TD>
            <TD>
              <Edit
                sx={{ fontSize: 30 }}
                onClick={() => this.openNewScheduleForm(schedule)}
              />
            </TD>
            <TD>
              <NoteAddRounded
                sx={{ fontSize: 30 }}
                onClick={() => this.openNoteScheduleForm(schedule)}
              />
            </TD>
            <TD>
              <Button
                style={{
                  color: schedule.is_delivery_location ? "white" : "black",
                }}
                onClick={() => this.openPOD(schedule)}
              >
                VIEW POD
              </Button>
            </TD>
          </TR>
          {schedule.notes != null && schedule.notes.length > 0
            ? schedule.notes.map((n) => {
                return (
                  <TR
                    style={{
                      fontSize: "0.75rem",
                      backgroundColor: getNoteColor(n.sub_type),
                    }}
                  >
                    <TD>
                      {new Date(n.updated_at).toLocaleString("en-us", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </TD>
                    <TD>{n.user_name}</TD>
                    <TD colSpan={7}>{n.entry}</TD>
                    <TD>
                      {n.user_id == this.state.currentUser.id ? (
                        <>
                          <Button
                            startIcon={<Edit />}
                            onClick={() =>
                              this.openNoteScheduleForm(schedule, n)
                            }
                          ></Button>
                          <Button
                            startIcon={<Delete />}
                            onClick={() =>
                              deleteNote(n, (result) => {
                                this.handleNoteDeleted(n);
                              })
                            }
                          ></Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </TD>
                  </TR>
                );
              })
            : null}
        </Fragment>
      );
    });
  }

  openNewScheduleForm(schedule = null, chassis = null) {
    this.setState({
      scheduleFormOpen: true,
      currentSchedule: schedule,
      suggestedChassis: chassis,
    });
  }

  handleScheduleFormClose(newSchedule, deleted = false) {
    this.props.onLoadingComplete("newScheduleForm");
    if (!deleted) {
      console.log(newSchedule);

      if (newSchedule != null) {
        let newSchedules = this.state.schedules.filter(
          (s) => s.id != newSchedule.id
        );

        newSchedules.push(newSchedule);

        this.setState({
          scheduleFormOpen: false,
          currentSchedule: null,
          schedules: newSchedules,
        });
      } else {
        this.setState({ scheduleFormOpen: false, currentSchedule: null });
      }
    } else {
      console.log(newSchedule);

      let newSchedules = this.state.schedules.filter(
        (s) => s.id != newSchedule.id
      );

      this.setState({
        scheduleFormOpen: false,
        currentSchedule: null,
        schedules: newSchedules,
      });
    }
  }

  handleBillignViewClose() {
    this.setState({ billingViewOpen: false });
  }

  openPOD(schedule) {
    this.setState({ podOpen: true, currentSchedule: schedule });
  }

  handlePODClose() {
    this.setState({ podOpen: false, currentSchedule: null });
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <LargeModal
          visible={this.state.container != null}
          loading={this.state.waitingFor}
          closeCallback={this.clearContainer}
        >
          {this.state.jobContainer ? (
            <Fragment>
              <ModalHeader>
                Ref #{this.state.job.ref_number.toUpperCase()} - Load #
                {this.state.jobContainer.load_number} - Container#{" "}
                {this.state.container.number} -{" "}
                {titlize(this.state.job.job_type)}
              </ModalHeader>
              <ContainerInfoDiv>
                <ContainerInfo>
                  <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                    {this.state.job.customer
                      ? this.state.job.customer.name
                      : "---"}
                  </p>
                  <p>
                    Contact Name:{" "}
                    {this.state.job.contact
                      ? `${this.state.job.contact.first_name} ${this.state.job.contact.last_name}`
                      : "---"}
                  </p>
                  <p>
                    Email:{" "}
                    {this.state.job.contact
                      ? this.state.job.contact.email
                      : "---"}
                  </p>
                  <p>
                    Phone:{" "}
                    {this.state.job.contact
                      ? this.state.job.contact.phone
                      : "---"}
                  </p>
                </ContainerInfo>
                {!this.state.isEdit ? (
                  <>
                    <ContainerInfo>
                      Container #: {this.state.container.number}
                      <Button
                        startIcon={<Edit />}
                        onClick={() => {
                          this.setState({ isEdit: true });
                        }}
                      ></Button>
                    </ContainerInfo>
                    <ContainerInfo>
                      Shipping Line: {titlize(this.state.container.ship_line)}
                    </ContainerInfo>
                    <ContainerInfo>
                      Size: {titlize(this.state.container.container_type)}
                    </ContainerInfo>
                  </>
                ) : (
                  <>
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        value={
                          this.state.jobContainer.container
                            ? this.state.jobContainer.container.number
                            : null
                        }
                        options={this.state.containers
                          .filter((c) => c.number != null)
                          .map((c) => c.number)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Containers"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            onSelect={(e) => {
                              this.updateContainer(e.target.value, null, null);
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="customer-select-label">
                        Container Type
                      </InputLabel>
                      <Select
                        labelId="customer-select"
                        id="customer-select"
                        value={
                          this.state.jobContainer.container
                            ? this.state.jobContainer.container.container_type
                            : null
                        }
                        onChange={(e) => {
                          this.updateContainer(
                            this.state.jobContainer.container.number,
                            null,
                            e.target.value
                          );
                        }}
                      >
                        {getContainerTypeOptions()}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="customer-select-label">
                        Steam Shipline
                      </InputLabel>
                      <Select
                        labelId="customer-select"
                        id="customer-select"
                        value={
                          this.state.jobContainer.container
                            ? this.state.jobContainer.container.ship_line
                            : null
                        }
                        onChange={(e) => {
                          this.updateContainer(
                            this.state.jobContainer.container.number,
                            e.target.value,
                            null
                          );
                        }}
                      >
                        {getSteamShipLineOptions()}
                      </Select>
                    </FormControl>
                    <Button
                      startIcon={<Save />}
                      onClick={() => this.associateContainer()}
                    >
                      Save
                    </Button>
                  </>
                )}
                <ContainerInfo>
                  Location:{" "}
                  <span
                    style={{
                      margin: "0 5%",
                      padding: "2%",
                      borderRadius: "2rem",
                      backgroundColor: getStatusColor(
                        this.state.jobContainer.state
                          ? this.state.jobContainer.state.raw_status
                          : ""
                      ),
                    }}
                  >
                    {titlize(
                      this.state.jobContainer.state
                        ? this.state.jobContainer.state.status
                        : "error"
                    )}
                  </span>
                </ContainerInfo>
                <ContainerInfo>
                  <FormControl sx={{ m: 1, maxWidth: 275 }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Load Requirements
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={
                        this.state.jobContainer &&
                        this.state.jobContainer.dependencies != null &&
                        this.state.jobContainer.dependencies.length > 0
                          ? this.state.jobContainer.dependencies
                          : ["None"]
                      }
                      onChange={(selected) => {
                        this.updateJobContainerStatus(
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          selected.target.value
                        );
                      }}
                      input={<OutlinedInput label="Requirements" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {[
                        "Clip-On Genset",
                        "Underslung Genset",
                        "Reefer - Genset Required",
                        "Reefer",
                        "Alcohol Permit",
                        "Overweight Permit",
                      ].map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={
                              this.state.jobContainer &&
                              this.state.jobContainer.dependencies
                                ? this.state.jobContainer.dependencies.indexOf(
                                    name
                                  ) > -1
                                : false
                            }
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ContainerInfo>
                {imports.includes(this.state.job.job_type) ? (
                  <Fragment>
                    <ContainerInfo>
                      <FormControl sx={{ m: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DatePicker
                              label="ETA"
                              inputFormat="MM/DD/YYYY"
                              value={moment(this.state.jobContainer.eta)}
                              onChange={(value) => {
                                this.updateJobContainerStatus(
                                  value,
                                  null,
                                  null,
                                  null,
                                  null,
                                  null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </FormControl>
                    </ContainerInfo>
                    <ContainerInfo>
                      <FormControl sx={{ m: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DatePicker
                              label="LFD"
                              inputFormat="MM/DD/YYYY"
                              value={moment(
                                this.state.jobContainer.last_free_day
                              )}
                              onChange={(value) => {
                                this.updateJobContainerStatus(
                                  null,
                                  value,
                                  null,
                                  null,
                                  null,
                                  null,
                                  null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </FormControl>
                    </ContainerInfo>
                    <ContainerInfo>
                      <FormControl sx={{ m: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DatePicker
                              label="Free Time Cut-Off"
                              inputFormat="MM/DD/YYYY"
                              value={moment(
                                this.state.jobContainer.free_time_cut_off
                              )}
                              onChange={(value) => {
                                this.updateJobContainerStatus(
                                  null,
                                  null,
                                  value,
                                  null,
                                  null,
                                  null,
                                  null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </FormControl>
                    </ContainerInfo>
                  </Fragment>
                ) : (
                  <Fragment>
                    <ContainerInfo>
                      <FormControl sx={{ m: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DatePicker
                              label="Free Time Cut-Off"
                              inputFormat="MM/DD/YYYY"
                              value={moment(
                                this.state.jobContainer.free_time_cut_off
                              )}
                              onChange={(value) => {
                                this.updateJobContainerStatus(
                                  null,
                                  null,
                                  value,
                                  null,
                                  null,
                                  null,
                                  null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </FormControl>
                    </ContainerInfo>
                    <ContainerInfo>
                      <FormControl sx={{ m: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DatePicker
                              label="Earliest Receive Date"
                              inputFormat="MM/DD/YYYY"
                              value={moment(
                                this.state.jobContainer.earliest_recieve_date
                              )}
                              onChange={(value) => {
                                this.updateJobContainerStatus(
                                  null,
                                  null,
                                  null,
                                  value,
                                  null,
                                  null,
                                  null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </FormControl>
                    </ContainerInfo>
                    <ContainerInfo>
                      <FormControl sx={{ m: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DatePicker
                              label="Cut-Off Date"
                              inputFormat="MM/DD/YYYY"
                              value={moment(
                                this.state.jobContainer.cut_off_date
                              )}
                              onChange={(value) => {
                                this.updateJobContainerStatus(
                                  null,
                                  null,
                                  null,
                                  null,
                                  value,
                                  null,
                                  null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </FormControl>
                    </ContainerInfo>
                  </Fragment>
                )}

                <ContainerInfo>
                  Loaded?: {this.state.jobContainer.is_loaded}
                  <span style={{ margin: "0 5%", padding: "2%" }}>
                    <Checkbox
                      checked={
                        this.state.jobContainer.is_loaded != null
                          ? this.state.jobContainer.is_loaded
                          : false
                      }
                      onChange={(e) => {
                        console.log(e);
                        let newJobContainer = this.state.jobContainer;
                        newJobContainer.is_loaded = e.target.checked;
                        this.setState({ jobContainer: newJobContainer });
                        setJobContainerLoadStatus(newJobContainer);
                      }}
                    />
                  </span>
                </ContainerInfo>
                <ContainerInfo>
                  Status:
                  <span
                    style={{
                      margin: "0 5%",
                      padding: "2%",
                      borderRadius: "2rem",
                      backgroundColor: getStatusColor(
                        this.state.jobContainer.status
                      ),
                    }}
                  >
                    <Select
                      disableUnderline
                      labelId={"status_select_" + this.state.jobContainer.id}
                      id={"status_select_" + this.state.jobContainer.id}
                      value={this.state.jobContainer.status}
                      onChange={(e) => {
                        this.updateJobContainerStatus(
                          null,
                          null,
                          null,
                          null,
                          null,
                          e.target.value,
                          null
                        );
                      }}
                    >
                      {ContainerStatusOptions()}
                    </Select>
                  </span>
                </ContainerInfo>
                {this.state.jobContainer.api_logo != null ? (
                  <ContainerInfo>
                    <img
                      style={{ width: "50%" }}
                      src={`/assets/terminal_logos/${this.state.jobContainer.api_logo}`}
                    />
                  </ContainerInfo>
                ) : (
                  <></>
                )}
              </ContainerInfoDiv>
              <ContainerScheduleDiv>
                <div style={{ width: "90%", margin: "5%" }}>
                  <FormControl
                    style={{ width: "18%", margin: "1%", float: "left" }}
                  >
                    <FormLabel>Booking #</FormLabel>
                    <Input
                      type="text"
                      value={this.state.jobContainer.booking_number}
                      onChange={(e) =>
                        this.setState({
                          jobContainer: {
                            ...this.state.jobContainer,
                            booking_number: e.target.value,
                          },
                        })
                      }
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          this.updateLoadInfo(
                            ev.target.value,
                            null,
                            null,
                            null,
                            null
                          );
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{ width: "18%", margin: "1%", float: "left" }}
                  >
                    <FormLabel>BOL #</FormLabel>
                    <Input
                      type="text"
                      value={this.state.jobContainer.bol}
                      onChange={(e) =>
                        this.setState({
                          jobContainer: {
                            ...this.state.jobContainer,
                            bol: e.target.value,
                          },
                        })
                      }
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          this.updateLoadInfo(
                            null,
                            ev.target.value,
                            null,
                            null,
                            null
                          );
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{ width: "18%", margin: "1%", float: "left" }}
                  >
                    <FormLabel>Seal #</FormLabel>
                    <Input
                      type="text"
                      value={this.state.jobContainer.seal}
                      onChange={(e) =>
                        this.setState({
                          jobContainer: {
                            ...this.state.jobContainer,
                            seal: e.target.value,
                          },
                        })
                      }
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          this.updateLoadInfo(
                            null,
                            null,
                            ev.target.value,
                            null,
                            null
                          );
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{ width: "18%", margin: "1%", float: "left" }}
                  >
                    <FormLabel>Weight (lbs)</FormLabel>
                    <Input
                      type="text"
                      value={this.state.jobContainer.weight}
                      onChange={(e) =>
                        this.setState({
                          jobContainer: {
                            ...this.state.jobContainer,
                            weight: e.target.value,
                          },
                        })
                      }
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          this.updateLoadInfo(
                            null,
                            null,
                            null,
                            ev.target.value,
                            null
                          );
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{ width: "18%", margin: "1%", float: "left" }}
                  >
                    <FormLabel>QTY</FormLabel>
                    <Input
                      type="number"
                      value={this.state.jobContainer.qty}
                      onChange={(e) =>
                        this.setState({
                          jobContainer: {
                            ...this.state.jobContainer,
                            qty: e.target.value,
                          },
                        })
                      }
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          this.updateLoadInfo(
                            null,
                            null,
                            null,
                            null,
                            null,
                            ev.target.value
                          );
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{ width: "18%", margin: "1%", float: "left" }}
                  >
                    <FormLabel>Commodity</FormLabel>
                    <Input
                      type="text"
                      value={this.state.jobContainer.commodity}
                      onChange={(e) =>
                        this.setState({
                          jobContainer: {
                            ...this.state.jobContainer,
                            commodity: e.target.value,
                          },
                        })
                      }
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          this.updateLoadInfo(
                            null,
                            null,
                            null,
                            null,
                            ev.target.value
                          );
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl
                    style={{ width: "18%", margin: "1%", float: "left" }}
                  >
                    <FormLabel>Genset #</FormLabel>
                    <Input
                      type="text"
                      value={this.state.jobContainer.genset_number}
                      onChange={(e) =>
                        this.setState({
                          jobContainer: {
                            ...this.state.jobContainer,
                            genset_number: e.target.value,
                          },
                        })
                      }
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          this.updateLoadInfo(
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            ev.target.value
                          );
                        }
                      }}
                    />
                  </FormControl>
                  <Button
                    variant="outlined"
                    onClick={() => this.updateLoadInfo()}
                  >
                    Save
                  </Button>
                </div>
                <div
                  style={{ width: "90%", margin: "0 5%", fontSize: "1.5rem" }}
                >
                  <p>
                    Requirements:{" "}
                    {this.state.jobContainer &&
                    this.state.jobContainer.dependencies &&
                    this.state.jobContainer.dependencies.length > 0
                      ? this.state.jobContainer.dependencies.join(", ")
                      : "None"}
                  </p>
                </div>
                <Autocomplete
                  style={{ width: "40%", margin: "5%", float: "left" }}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  value={this.state.jobContainer.pickup_location}
                  getOptionLabel={(option) => option.name}
                  options={this.state.locations}
                  renderOption={(props, location) => (
                    <Box component="li" {...props}>
                      {getLocationIcon(location.location_type)}
                      {location.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Return Location"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        startAdornment: (
                          <InputAdornment position="start">
                            {getLocationIcon(
                              this.state.jobContainer.pickup_location
                                .location_type
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  onSelect={(e) => {
                    this.updateLocations(e.target.value, null);
                  }}
                />
                <Autocomplete
                  style={{ width: "40%", margin: "5%", float: "left" }}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  getOptionLabel={(option) => option.name}
                  value={this.state.jobContainer.return_location}
                  options={this.state.locations}
                  renderOption={(props, location) => (
                    <Box component="li" {...props}>
                      {getLocationIcon(location.location_type)}
                      {location.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Return Location"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        startAdornment: (
                          <InputAdornment position="start">
                            {getLocationIcon(
                              this.state.jobContainer.return_location
                                .location_type
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  onSelect={(e) => {
                    if (e.target.value == null) return;
                    this.updateLocations(null, e.target.value);
                  }}
                />
                <Table style={{ margin: "5%" }}>
                  <TR>
                    <TH colSpan={10}>MOVES</TH>
                  </TR>
                  <TR>
                    <TH>Pickup</TH>
                    <TH>Destination</TH>
                    <TH>Appt. Time</TH>
                    <TH>Status</TH>
                    <TH>Driver</TH>
                    <TH>Truck</TH>
                    <TH>Chassis</TH>
                    <TH colSpan={"2"}>
                      <Button
                        onClick={() =>
                          this.openNewScheduleForm(
                            null,
                            this.state.schedules.length > 0
                              ? this.state.schedules[0].chassis_id
                              : null
                          )
                        }
                        startIcon={<AddCircle sx={{ fontSize: 30 }} />}
                      >
                        Add
                      </Button>
                    </TH>
                    <TH>POD</TH>
                  </TR>
                  {this.getSchedules(this.state.schedules)}
                </Table>
                <Table style={{ margin: "5%" }}>
                  <TH colSpan={4}>NOTES</TH>
                  <TR>
                    <TD colSpan={"4"}>Client Notes</TD>
                  </TR>
                  {this.state.job.customer != null &&
                  this.state.job.customer.notes.length > 0
                    ? this.state.job.customer.notes.map((n) => {
                        if (
                          (n.note_type == "customer" &&
                            ClientNotesView.includes(
                              this.props.currentUser.access_level
                            )) ||
                          (n.note_type == "customer_billing" &&
                            ClientBillingNotesView.includes(
                              this.props.currentUser.access_level
                            ))
                        )
                          return (
                            <TR
                              style={{
                                fontSize: "0.75rem",
                                backgroundColor:
                                  n.note_type == "job" ||
                                  n.note_type == "customer"
                                    ? "lightblue"
                                    : "lightgreen",
                              }}
                            >
                              <TD>{titlize(n.note_type)}</TD>
                              <TD>
                                {new Date(n.created_at).toLocaleString(
                                  "en-us",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}
                              </TD>
                              <TD>{n.user_name}</TD>
                              <TD colSpan={7}>
                                <pre>{n.entry}</pre>
                              </TD>
                              <TD>
                                {n.user_id == this.state.currentUser.id ? (
                                  <>
                                    <Button
                                      startIcon={<Edit />}
                                      onClick={() =>
                                        this.openClientNoteForm(n.note_type, n)
                                      }
                                    ></Button>
                                    <Button
                                      startIcon={<Delete />}
                                      onClick={() =>
                                        deleteNote(n, (result) => {
                                          this.handleNoteDeleted(n);
                                        })
                                      }
                                    ></Button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </TD>
                            </TR>
                          );
                      })
                    : null}
                </Table>
                <Table style={{ margin: "5%" }}>
                  {this.state.job.notes != null &&
                  this.state.job.notes.length > 0
                    ? this.state.job.notes.map((n) => {
                        if (
                          (n.note_type == "job" &&
                            JobNotesView.includes(
                              this.props.currentUser.access_level
                            )) ||
                          (n.note_type == "job_billing" &&
                            JobBillingNotesView.includes(
                              this.props.currentUser.access_level
                            ))
                        )
                          return (
                            <TR
                              style={{
                                fontSize: "0.75rem",
                                backgroundColor:
                                  n.note_type == "job" ||
                                  n.note_type == "customer"
                                    ? "lightblue"
                                    : "lightgreen",
                              }}
                            >
                              <TD>{titlize(n.note_type)}</TD>
                              <TD>
                                {new Date(n.created_at).toLocaleString(
                                  "en-us",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}
                              </TD>
                              <TD>{n.user_name}</TD>
                              <TD colSpan={7}>
                                <pre>{n.entry}</pre>
                              </TD>
                              <TD>
                                {n.user_id == this.state.currentUser.id ? (
                                  <>
                                    <Button
                                      startIcon={<Edit />}
                                      onClick={() =>
                                        this.openClientNoteForm(n.note_type, n)
                                      }
                                    ></Button>
                                    <Button
                                      startIcon={<Delete />}
                                      onClick={() =>
                                        deleteNote(n, (result) => {
                                          this.handleNoteDeleted(n);
                                        })
                                      }
                                    ></Button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </TD>
                            </TR>
                          );
                      })
                    : null}
                </Table>
              </ContainerScheduleDiv>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  margin: "1% 20%",
                }}
              >
                {["super_user", "standard_user"].includes(
                  this.state.currentUser.access_level
                ) ? (
                  <Button
                    onClick={() =>
                      getToken(this.state.jobContainer.id, (result) => {
                        navigator.clipboard.writeText(
                          `${window.location.hostname}/load-tracking?token=${result.jwt}`
                        );
                        alert("Tracking Link Copied to Clipboard!");
                      })
                    }
                    startIcon={<CopyAll />}
                  >
                    Copy Tracking URL
                  </Button>
                ) : (
                  <></>
                )}
                {BillingAccess.includes(this.state.currentUser.access_level) ? (
                  <Button
                    startIcon={<AttachMoney />}
                    onClick={() => {
                      this.setState({ billingViewOpen: true });
                    }}
                  >
                    Billing
                  </Button>
                ) : null}
                {ClientNotesAdd.includes(
                  this.state.currentUser.access_level
                ) ? (
                  <Button
                    startIcon={<NoteAddRounded sx={{ fontSize: 30 }} />}
                    onClick={() => this.openClientNoteForm("customer")}
                  >
                    Add Client Note (Global)
                  </Button>
                ) : (
                  ""
                )}
                {ClientBillingNotesAdd.includes(
                  this.state.currentUser.access_level
                ) ? (
                  <Button
                    startIcon={<NoteAddRounded sx={{ fontSize: 30 }} />}
                    onClick={() => this.openClientNoteForm("customer_billing")}
                  >
                    Add Client Billing Note (Global)
                  </Button>
                ) : (
                  ""
                )}
                {JobNotesAdd.includes(this.state.currentUser.access_level) ? (
                  <Button
                    startIcon={<NoteAddRounded sx={{ fontSize: 30 }} />}
                    onClick={() => this.openClientNoteForm("job")}
                  >
                    Add Job Note
                  </Button>
                ) : (
                  ""
                )}
                {JobBillingNotesAdd.includes(
                  this.state.currentUser.access_level
                ) ? (
                  <Button
                    startIcon={<NoteAddRounded sx={{ fontSize: 30 }} />}
                    onClick={() => this.openClientNoteForm("job_billing")}
                  >
                    Add Job Billing Note
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Fragment>
          ) : (
            ""
          )}
        </LargeModal>
        <NewScheduleForm
          schedule={this.state.currentSchedule}
          visible={this.state.scheduleFormOpen}
          jobContainerId={
            this.state.jobContainer != null ? this.state.jobContainer.id : null
          }
          onLoading={this.props.onLoading}
          onLoadingComplete={this.props.onLoadingComplete}
          suggestedChassis={this.state.suggestedChassis}
          onClose={this.handleScheduleFormClose}
        />
        <NewNoteForm
          id={this.state.currentId ? this.state.currentId : -1}
          type={this.state.currentNoteType}
          visible={
            this.state.scheduleNoteFormOpen || this.state.clientNoteFormOpen
          }
          note={this.state.currentNote}
          onClose={this.handleNoteFormClose}
        />
        <Billing
          visible={this.state.billingViewOpen}
          job={this.state.job}
          jobContainer={this.state.jobContainer}
          invoices={this.state.invoices}
          onClose={this.handleBillignViewClose}
          currentUser={this.state.currentUser}
        />
        <LargeModal
          visible={this.state.podOpen}
          closeCallback={this.handlePODClose}
        >
          <ModalHeader>POD</ModalHeader>
          <POD
            visible={true}
            schedule={this.state.currentSchedule}
            load={this.state.jobContainer}
            job={this.state.job}
          />
        </LargeModal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts.alerts,
    currentUser: state.user.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addAlert: (alert) => dispatch(addAlert(alert)),
    removeAlert: (alertId) => dispatch(removeAlert(alertId)),
    setUser: (user) => dispatch(setUser(user)),
    dismissProgressOverlay: (identifier) =>
      dispatch(dismissProgressOverlay(identifier)),
    displayProgressOverlay: (identifier) =>
      dispatch(displayProgressOverlay(identifier)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Container));
