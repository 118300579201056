import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { BillingAccess } from "../../util/constants";
import { Bar, Logo, NavItem } from "./components";

export default (props) => {

  const pathname = window.location.pathname;
  console.log(pathname);

  return (
    <>
    <Bar borderBottom={true}>
      <NavItem currentPath={pathname == "/admin-dashboard"}><Link to={"/admin-dashboard"}>Dashboard</Link></NavItem>
      <NavItem currentPath={pathname == "/containers"}><Link to={"/containers"}>Containers</Link></NavItem>
      <NavItem currentPath={pathname == "/trucks"}><Link to={"/trucks"}>Vehicles</Link></NavItem>
      <NavItem currentPath={pathname == "/yard-logs"}><Link to={"/yard-logs"}>Chassis</Link></NavItem>
      <NavItem currentPath={pathname == "/drivers"}><Link to={"/drivers"}>Drivers</Link></NavItem>
      { props.currentUser ? 
      <Fragment>
        { props.currentUser.access_level == "super_user" || props.currentUser.access_level == "hr_user" ? <NavItem currentPath={pathname == "/compliance"}><Link to={"/compliance"}>Compliance</Link></NavItem> : ""}
        { BillingAccess.includes(props.currentUser.access_level) ? <NavItem currentPath={pathname == "/invoices"}><Link to={"/invoices"}>Invoices</Link></NavItem> : ""}
        { props.currentUser.access_level == "super_user" || props.currentUser.access_level == "standard_user" ? <NavItem><Link to={"/move-overview"}>Overview</Link></NavItem> : ""}
        { props.currentUser.access_level == "super_user" || props.currentUser.access_level == "standard_user" ? <NavItem><Link to={"/overview"}>TV Overview</Link></NavItem> : ""}
        { props.currentUser.access_level == "super_user" ? <NavItem style={{display: props.openTool ? "block" : "none"}} onClick={() => props.openTool('quoting_tool')}>Quote</NavItem> : ""}
        { props.currentUser.access_level == "super_user" ? <NavItem currentPath={pathname == "/admin-panel"}><Link to={"/admin-panel"}>Admin</Link></NavItem> : ""}
      </Fragment>
      : ""}
      
    </Bar>
    </>
  );
}
