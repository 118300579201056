import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { titlize } from "../util/functions";
import { Pill, ScrollView } from "./Components";
import { getLogColor } from "./forms/Options";
import { LargeModal, ModalHeader } from "./Modals";

const Table = styled.table`
color: black;
background: #8080802e;
width: 90%;
margin: 5%;
box-shadow: 8px 5px 5px 0px #aaa;
border-radius: 1rem;
overflow: hidden;
& tr:nth-child(odd) {background: #cccccc;}
`;

const TR = styled.tr`
border-bottom: 0.5px solid black
`;

const TH = styled.th`
font-size: 1rem;
font-weight: bold;
padding: 1%;
`;

const TD = styled.td`
width: ${(props) => props.width};
font-weight: ${(props) => props.fontWeight};
padding: 1%;
`;

export default (props) => {

    const [visible, setVisible] = useState(false);
    const getRows = (logs) => {
        let rows = logs.map((log) => {
            return <TR>
                <TD>{moment(log.created_at).local().format("MM-DD-YYYY hh:mm a")}</TD>
                <TD><Pill color={getLogColor(log.classification)}>{titlize(log.classification)}</Pill></TD>
                <TD>{log.username}</TD>
                <TD>{log.entry}</TD>
            </TR>
        });

        return rows;
    }

    const onClose = () => {
        props.onClose();
    }

    React.useEffect(() => {
        setVisible(props.visible);
    }, [props.jobRef, props.visible])

    return (
        <LargeModal visible={visible} closeCallback={onClose}>
            <ModalHeader>{props.jobRef + " Logs"}</ModalHeader>
            <ScrollView>
            <Table>
                <TR>
                    <TH>
                        Timestamp
                    </TH>
                    <TH>
                        Type
                    </TH>
                    <TH>
                        User
                    </TH>
                    <TH>
                        Entry
                    </TH>
                </TR>
                {getRows(props.logs)}
            </Table>
            </ScrollView>
        </LargeModal>
    )
}