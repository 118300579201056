import React, { Fragment } from "react";
import TruckList from "../components/TruckList";
import NavBar from "../components/nav_bar";
import SecuredView from "./SecuredView";

class TruckDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
      <SecuredView accessLevels={["super_user", "standard_user", "billing_user", "hr_user"]}>
        <TruckList />
      </SecuredView>
    );
  }
}

export default TruckDashboard;