const token = document.querySelector("[name=csrf-token]").content;

export var getAllLocations = (onSuccess, onError) => {
  return fetch(`/api/v1/locations`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var createLocation = (location, onSuccess, onError) => {
  return fetch(`/api/v1/locations`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(location),
    method: "POST"
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (decoded.status_code == 401) {
        window.location.href = decoded.redirect;
        onSuccess([]);
      }
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var updateLocation = (location, onSuccess, onError) => {
  return fetch(`/api/v1/locations`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(location),
    method: "PUT"
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var createGeoFence = (locationId, geoFence, onSuccess, onError) => {
  return fetch(`/api/v1/locations/${locationId}/geo-fence`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(geoFence),
    method: "POST"
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var updateGeoFence = (locationId, geoFence, onSuccess, onError) => {
  return fetch(`/api/v1/locations/${locationId}/geo-fence`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    body: JSON.stringify(geoFence),
    method: "PUT"
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};