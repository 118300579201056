const token = document.querySelector("[name=csrf-token]").content;

export var createConversation = (body, onSuccess, onError) => {
  return fetch(`/api/v1/chat/conversations/create`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var deleteConversation = (sid, onSuccess, onError) => {
  return fetch(`/api/v1/chat/conversations/` + sid, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
    method: "DELETE",
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getContacts = (onSuccess, onError) => {
  return fetch(`/api/v1/chat/contacts`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};

export var getConversations = (onSuccess, onError) => {
  return fetch(`/api/v1/chat/conversations`, {
    credentials: "include",
    headers: { "X-CSRF-TOKEN": token },
  })
    .then((res) => res.json())
    .then((decoded) => {
      console.log(decoded);
      if (onSuccess != null) onSuccess(decoded);
    });
};
