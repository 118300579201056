import React from 'react'
import { getTrackingInfo } from '../api/tracking';
import { ProgressOverlay } from '../components/Components';
import { StatusDot, TrackingBanner, TrackingInfo, TrackingMove, TrackingMoveInfo, TrackingMoveInfoHeading, TrackingMoveInfoHeadingNarrow, TrackingMoveInfoNarrow, TrackingMovesContainer } from '../components/styled/TrackingElements';
import { titlize } from '../util/functions';
import Xarrow from "react-xarrows";
import { getStatusColor } from '../components/forms/Options';
import moment from 'moment';



class TrackingView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(location.search)
        const jwt = queryParams.get("token")

        if (jwt == null) {
            this.showError("401");
            return
        }

        getTrackingInfo(jwt, (result) => {
            if (result.error) {
                this.showError(result.error);
            } else {
                this.setState({currentLoad: result});
            }
        });
    }

    showError(message) {
        alert(message);
    }

    getTrackingMoves (schedules) {
        const allowed_states = ["scheduled", "en_route", "dropped"]
        let rows = [];
        let lastId = null;

        schedules.sort((a, b) => {
            if (b.appointment_time == null && a.appointment_time == null) {
              return 0
            }
            if (b.appointment_time == null) {
              return -1
            }
            if (a.appointment_time == null) {
              return 1
            }
            return (
              moment(a.appointment_time).unix() - moment(b.appointment_time).unix()
            );
          });

        schedules.map((schedule) => {
            if (allowed_states.includes(schedule.status)) {
                let id = `schedule-${schedule.id}`;

                if (lastId == null) {
                    lastId = id;
                } else {
                    rows.push(<Xarrow
                        start={lastId} //can be react ref
                        end={id} //or an id
                        color={getStatusColor(schedule.status)}
                        showHead={false}
                        strokeWidth={10}
                    />);
                    lastId = id;
                }

                if (schedule.status == "dropped") {
                    rows.push(<TrackingMove><TrackingMoveInfoNarrow><StatusDot id={id} color={getStatusColor(schedule.status)}/></TrackingMoveInfoNarrow>
                    <TrackingMoveInfo>{!schedule.is_delivery_location ? schedule.drop_location.name : `${schedule.drop_location.name} - ${schedule.drop_location.city}, ${schedule.drop_location.state}`}</TrackingMoveInfo>
                    <TrackingMoveInfo>{titlize(schedule.status)}</TrackingMoveInfo>
                    <TrackingMoveInfo>{new Date(schedule.appointment_time).toLocaleString()}</TrackingMoveInfo>
                    </TrackingMove>);
                } else {
                    rows.push(<TrackingMove><TrackingMoveInfoNarrow><StatusDot id={id} color={getStatusColor(schedule.status)}/></TrackingMoveInfoNarrow>
                    <TrackingMoveInfo>{!schedule.is_delivery_location ? schedule.drop_location.name : `${schedule.drop_location.name} - ${schedule.drop_location.city}, ${schedule.drop_location.state}`}</TrackingMoveInfo>
                    <TrackingMoveInfo>{titlize(schedule.status)}</TrackingMoveInfo>
                    <TrackingMoveInfo>- - - - - -</TrackingMoveInfo>
                    </TrackingMove>);
                }
            }
        });
        return rows;
    }

    render() {

        return <div>
            <ProgressOverlay visible={this.state.currentLoad == null}/>
            {this.state.currentLoad ? 
            <>
                <TrackingBanner>Ref: {this.state.currentLoad.job.ref_number} Load# {this.state.currentLoad.load_number}</TrackingBanner>
                <TrackingInfo>Container# {this.state.currentLoad.container.number}      Status: {titlize(this.state.currentLoad.status)}</TrackingInfo>
                <TrackingMovesContainer>
                <TrackingMove style={{borderBottom: "1px solid black"}}>
                <TrackingMoveInfoHeadingNarrow>
                </TrackingMoveInfoHeadingNarrow>
                <TrackingMoveInfoHeading>
                Delivery Location
                </TrackingMoveInfoHeading>
                <TrackingMoveInfoHeading>
                Status
                </TrackingMoveInfoHeading>
                <TrackingMoveInfoHeading>
                Delivered At
                </TrackingMoveInfoHeading>
                </TrackingMove>
                {this.getTrackingMoves(this.state.currentLoad.schedules)}
                <TrackingMove>
                    <TrackingMoveInfoNarrow>
                    </TrackingMoveInfoNarrow>
                    <TrackingMoveInfo>
                        <StatusDot style={{width: "7%", paddingBottom: "7%"}} color={getStatusColor("scheduled")}/>
                        Planned
                    </TrackingMoveInfo>
                    <TrackingMoveInfo>
                        <StatusDot style={{width: "7%", paddingBottom: "7%"}} color={getStatusColor("en_route")}/>
                        En Route
                    </TrackingMoveInfo>
                    <TrackingMoveInfo>
                        <StatusDot style={{width: "7%", paddingBottom: "7%"}} color={getStatusColor("dropped")}/>
                        Delivered
                    </TrackingMoveInfo>
                    </TrackingMove>
                </TrackingMovesContainer>
            </>
            : <></>}
            
        </div>

    }
}

export default TrackingView;