import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminDashboard from "../views/AdminDashboard";
import AdminPanel from "../views/AdminPanel";
import Compliance from "../views/Compliance";
import ContainerLocations from "../views/ContainerLocations";
import Drivers from "../views/Drivers";
import Invoices from "../views/Invoices";
import MoveOverview from "../views/MoveOverview";
import MoveOverviewStatic from "../views/MoveOverviewStatic";
import TrackingView from "../views/TrackingView";
import TruckDashboard from "../views/Trucks";
import WelcomePage from "../views/WelcomePage";
import YardLogs from "../views/YardLogs";

export default (
  <Router>
    <Routes>
      <Route path="/" exact element={<WelcomePage />} />
      <Route path="/admin-dashboard/" exact element={<AdminDashboard />} />
      <Route path="/containers" exact element={<ContainerLocations />} />
      <Route path="/trucks" exact element={<TruckDashboard />} />
      <Route path="/yard-logs" exact element={<YardLogs />} />
      <Route path="/drivers" exact element={<Drivers />} />
      <Route path="/compliance" exact element={<Compliance />} />
      <Route path="/invoices" exact element={<Invoices />} />
      <Route path="/move-overview" exact element={<MoveOverviewStatic />} />
      <Route path="/overview" exact element={<MoveOverview />} />
      <Route path="/admin-panel" exact element={<AdminPanel />} />
      <Route path="/load-tracking" exact element={<TrackingView />} />
    </Routes>
  </Router>
);
