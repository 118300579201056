import React from 'react';
import styled from 'styled-components';

export const SearchBarContainer = styled.div`
    display: inline-block;
    width: 90%;
    margin: 1% 5%;
    border: 2px solid black;
    box-shadow: 8px 5px 5px 0px #aaa;
    font-size: 1.5rem;
`
export const SearchBarFull = styled.div`
    float: left;
    width: 98%;
    margin: 1%;
    font-size: 1.5rem;
`
export const SearchBarThird = styled.div`
    float: left;
    width: 31%;
    margin: 1%;
    font-size: 1.5rem;
`
export const StatsContainer = styled.div`
    width: 90%;
    margin: 0 5%;
    display: inline-block;
`
export const StatsItem = styled.div`
    float: left;
    padding: 1%;
    font-size: 1.5rem;
`