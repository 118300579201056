import React from 'react'
import { GoogleMap, Marker, useJsApiLoader,TrafficLayer   } from '@react-google-maps/api';

const containerStyle = {
  width: '90%',
  height: '400px',
  margin: '5%'
};

const center = {
  lat: 40.733778, 
  lng: -74.172640
};

const MapView = (props) => {
  const { isLoaded } = useJsApiLoader({
    libraries: ["places"],
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCLTsEEDF3WurlqNWzoP1zytfiSftn2n84"
  })

  const [map, setMap] = React.useState(null)
  const [location, setLocation] = React.useState()
  const [truckInfo, setTruckInfo] = React.useState(props.truckInfo)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  React.useEffect(() => {
    if (props.center) {
      props.center.zoom = 10;
    }
    console.log("Center: " + props.center);
    setLocation(props.center)
    setTruckInfo(props.truckInfo);
}, [props.location, props.center])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={location ? location : props.center}
        zoom={location ? location.zoom : 5}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        
        <TrafficLayer autoUpdate/>
        { 
        location ? 
        
          <Marker 
          label={{text:`${truckInfo.number}`,color:'#000000', fontSize: "22px", fontWeight: "bold"}}
          icon={{
            labelOrigin: new google.maps.Point(40, 57),
            // anchor: new google.maps.Point(0, 0),
            url: '/assets/truck.svg',
            scaledSize: new google.maps.Size(100, 100)

          }} position={location} visible={true}/> : null 
          }
      </GoogleMap>
  ) : <></>
}

export default MapView;