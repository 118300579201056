import React, { useState } from "react";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import { Stack } from "@mui/system";
import {
  Button,
  CircularProgress,
  Input,
  InputAdornment,
  MenuItem,
  Select,
} from "@material-ui/core";
import { copyTable, titlize, toPDF } from "../util/functions";
import { Add, Download, RemoveCircle, Save } from "@mui/icons-material";
import { updateSettlement } from "../api/drivers";
import { imports } from "../util/constants";

export const Pill = styled.span`
  padding: ${(props) => (props.padding ? props.padding : "5%")};
  border-radius: 2rem;
  background-color: ${(props) => props.color};
`;

export const ScrollView = styled.div`
  overflow: auto;
  max-height: 500px;
`;

export const ProgressOverlay = (props) => {
  return (
    <div
      style={{
        zIndex: 200,
        display: props.visible ? "block" : "none",
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#FFFFFFB0",
      }}
    >
      <Stack
        style={{
          transform: "translate(-50%, -50%)",
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        sx={{ color: "grey.500" }}
        spacing={2}
        direction="row"
      >
        <CircularProgress size={100} />
      </Stack>
    </div>
  );
};

export const invoiceTableInlineStyle = {
  width: "98%",
  margin: "1%",
  border: "1px solid black",
  borderRadius: "10px",
  borderSpacing: "0px",
  borderCollapse: "separate !important",
  overflow: "hidden",
};

export const invoiceHeaderInlineStyle = {
  borderBottom: "1px solid black",
  backgroundColor: "black",
  color: "white",
  textAlign: "center",
};

export const invoiceLineItemsInlineStyle = {
  borderBottom: "1px dotted black",
};

const InvoiceHeader = styled.div`
  display: inline-block;
  width: 98%;
  padding: 0 5%;
`;

const InvoiceInfo = styled.div`
  float: left;
  width: 45%;
  padding: 1%;
`;
const InvoiceBold = styled.span`
  width: 98%;
  display: inline-block;
  font-weight: bold;
  font-size: 1.5rem;
`;

const InvoiceNormal = styled.span`
  width: 98%;
  display: inline-block;
  font-size: 1.25rem;
`;

const PODHeader = styled.div`
  display: inline-block;
  width: 98%;
  padding: 5%;
`;

const PODInfo = styled.div`
  float: left;
  width: 30%;
  padding: 1%;
`;
const PODBold = styled.span`
  width: 98%;
  display: inline-block;
  font-weight: bold;
  font-size: 1.5rem;
`;

const PODNormal = styled.span`
  width: 98%;
  display: inline-block;
  font-size: 1.5rem;
`;

const PODLargeCentered = styled.span`
  width: 98%;
  display: inline-block;
  font-size: 2rem;
  text-align: center;
`;

const PODNormalCentered = styled.span`
  width: 98%;
  text-align: center;
  display: inline-block;
  font-size: 1.5rem;
`;

const PODBar = styled.span`
  width: 98%;
  display: inline-block;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

const PODScheduleTable = styled.table`
    width: 95%;
    marginL 5%;
    border: 1px solid black;
`;
const PODScheduleTD = styled.td`
  border: 1px solid black;
  padding: 1%;
  width: 25%;
`;

export const Invoice = (props) => {
  let deliveryDate = new Date();
  return (
    <div
      id={`complete-invoice-${props.invoice.id}`}
      style={{
        display: props.visible ? "block" : "none",
        padding: "5% 1%",
        width: "900",
      }}
    >
      <InvoiceHeader>
        <InvoiceInfo>
          <InvoiceBold>F&C Trucking and Repair LLC</InvoiceBold>
          <InvoiceNormal>112 Jabez St, Ste 109</InvoiceNormal>
          <InvoiceNormal>Newark NJ, 07105</InvoiceNormal>
          <InvoiceNormal>(833) 419-3278</InvoiceNormal>
        </InvoiceInfo>
        <InvoiceInfo>
          <InvoiceBold>INVOICE</InvoiceBold>
          <InvoiceNormal>
            Invoice No. FCAI{props.jobContainer.load_number}
            {props.invoice.invoice_type == "per_diem" ? "-PD" : ""}
          </InvoiceNormal>
          <InvoiceNormal>
            Invoice Date: {new Date().toLocaleDateString()}
          </InvoiceNormal>
          <InvoiceNormal>
            Invoice Due: {new Date().toLocaleDateString()}
          </InvoiceNormal>
        </InvoiceInfo>
      </InvoiceHeader>
      <InvoiceHeader>
        <InvoiceInfo>
          <InvoiceBold>Bill To:</InvoiceBold>
          <InvoiceNormal>{props.job.customer.name}</InvoiceNormal>
          <InvoiceNormal>{props.job.customer.address_1}</InvoiceNormal>
          <InvoiceNormal>{props.job.customer.address_2}</InvoiceNormal>
          <InvoiceNormal>{`${props.job.customer.city} ${props.job.customer.state}, ${props.job.customer.zip}`}</InvoiceNormal>
        </InvoiceInfo>
      </InvoiceHeader>
      <table style={{ width: "90%", margin: "5%" }}>
        <tr style={{ backgroundColor: "black", color: "white" }}>
          <th>Pickup Location</th>
          <th>Delivered To</th>
          <th>Return Location</th>
        </tr>
        <tr>
          <td>
            <InvoiceNormal>
              {props.jobContainer.pickup_location.name}
            </InvoiceNormal>
            <InvoiceNormal>
              {props.jobContainer.pickup_location.address_1}
            </InvoiceNormal>
            <InvoiceNormal>{`${props.jobContainer.pickup_location.city} ${props.jobContainer.pickup_location.state}, ${props.jobContainer.pickup_location.zip}`}</InvoiceNormal>
          </td>
          <td>
            {props.jobContainer.schedules
              ? props.jobContainer.schedules.map((s) => {
                  if (s.is_delivery_location) {
                    deliveryDate = s.appointment_time;
                    return (
                      <>
                        <InvoiceNormal>{s.drop_location.name}</InvoiceNormal>
                        <InvoiceNormal>
                          {s.drop_location.address_1}
                        </InvoiceNormal>
                        <InvoiceNormal>{`${s.drop_location.city} ${s.drop_location.state}, ${s.drop_location.zip}`}</InvoiceNormal>
                      </>
                    );
                  }
                })
              : ""}
          </td>
          <td>
            <InvoiceNormal>
              {props.jobContainer.return_location.name}
            </InvoiceNormal>
            <InvoiceNormal>
              {props.jobContainer.return_location.address_1}
            </InvoiceNormal>
            <InvoiceNormal>{`${props.jobContainer.return_location.city} ${props.jobContainer.return_location.state}, ${props.jobContainer.return_location.zip}`}</InvoiceNormal>
          </td>
        </tr>
      </table>
      <InvoiceHeader>
        <InvoiceInfo>
          <InvoiceNormal>Reference #: {props.job.ref_number}</InvoiceNormal>
          <InvoiceNormal>
            Delivery Date: {new Date(deliveryDate).toLocaleDateString()}
          </InvoiceNormal>
        </InvoiceInfo>
        <InvoiceInfo>
          <InvoiceNormal>
            Container #:{props.jobContainer.container.number}
          </InvoiceNormal>
          <InvoiceNormal>
            Type:: {titlize(props.jobContainer.container.container_type)}
          </InvoiceNormal>
        </InvoiceInfo>
      </InvoiceHeader>
      <table
        id={`invoice-table-${props.invoice.id}`}
        style={{ ...invoiceTableInlineStyle, width: "90%", margin: "5%" }}
      >
        <tr>
          <th
            colSpan={"5"}
            style={{
              ...invoiceHeaderInlineStyle,
              textAlign: "center",
              fontSize: "2rem",
              borderRadius: "10px 10px 0 0",
            }}
          >{`${titlize(props.invoice.invoice_type)}-Container: ${
            props.jobContainer.container.number
          }`}</th>
        </tr>
        <tr>
          <th style={invoiceHeaderInlineStyle}>Line</th>
          <th style={invoiceHeaderInlineStyle}>Notes</th>
          <th style={invoiceHeaderInlineStyle}>Amount</th>
          <th style={invoiceHeaderInlineStyle}>QTY</th>
          <th style={invoiceHeaderInlineStyle}>Sum</th>
        </tr>
        {props.invoice.line_items.map((li) => {
          return (
            <tr>
              <td style={invoiceLineItemsInlineStyle}>
                {titlize(li.line_item_type)}
              </td>
              <td style={invoiceLineItemsInlineStyle}>{li.notes}</td>
              <td
                style={{ ...invoiceLineItemsInlineStyle, textAlign: "center" }}
              >
                ${li.amount.toFixed(2)}
              </td>
              <td
                style={{ ...invoiceLineItemsInlineStyle, textAlign: "center" }}
              >
                {li.quantity}
              </td>
              <td
                style={{ ...invoiceLineItemsInlineStyle, textAlign: "center" }}
              >
                $
                {li.discount
                  ? `${(
                      li.amount * li.quantity -
                      li.amount * li.quantity * li.discount
                    ).toFixed(2)} (-%${li.discount * 100})`
                  : (li.amount * li.quantity).toFixed(2)}
              </td>
            </tr>
          );
        })}
        <tr style={{ backgroundColor: "lightgrey" }}>
          <td style={{ textAlign: "left" }}>Total</td>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ textAlign: "center" }}>
            ${props.invoice.total.toFixed(2)}
          </td>
        </tr>
      </table>
      <InvoiceHeader>
        <InvoiceNormal>Thank you for your business</InvoiceNormal>
      </InvoiceHeader>
    </div>
  );
};

export const DriverPaySchedule = (props) => {
  let format = "MM/DD/YYYY";
  let total = 0.0;
  let newTotal = 0.0;

  const [settlement, setSettlement] = useState();
  const [isEdit, setIsEdit] = useState(false);

  React.useEffect(() => {
    setSettlement(props.settlement);
  }, [props.settlement]);

  const onLineItemAdded = () => {
    console.log("New Line");
    let settlementTmp = { ...settlement };
    settlementTmp.additional_payments.push({
      id: new Date().getTime(),
      payment_type: "not_set",
      amount: 0.0,
      note: "",
    });
    setSettlement(settlementTmp);
  };

  const onLineItemRemoved = (id) => {
    console.log("Remove Line");
    let additionalPayments = settlement.additional_payments.filter(
      (i) => i.id != id
    );
    let settlementTmp = {
      ...settlement,
      additional_payments: additionalPayments,
    };
    setSettlement(settlementTmp);
  };

  const onLineItemUpdated = (scheduleId, lineItemId, field, value) => {
    console.log(`ID: ${lineItemId} Field: ${field} Val: ${value}`);
    if (field == "fee-amount") {
      if (lineItemId) {
        console.log("updating");
        let found = settlement.schedules
          .map((s) => {
            let fees = s.driver_fees.filter((fee) => fee.id == lineItemId);
            let fee = null;
            console.log(fees);
            if (fees.length > 0) {
              fee = fees[0];
            }

            if (fee) {
              fee.amount = value;
              return s;
            }
          })
          .filter((s) => s != null)[0];
        let removed = settlement.schedules.filter((s) => s.id != found.id);
        removed.push(found);
        let settlementTmp = { ...settlement };
        let newTotal = 0.0;
        removed.map((s) => {
          s.driver_fees.map((f) => {
            newTotal += parseFloat(f.amount);
          });
        });

        settlementTmp.total = newTotal.toFixed(2);
        setSettlement({ ...settlementTmp, schedules: removed });
      } else {
        console.log("creating");
        let driver_fee = {
          schedule_id: scheduleId,
          id: new Date().getTime(),
          amount: value,
        };

        let found = settlement.schedules
          .map((s) => {
            if (s.id == scheduleId) {
              s.driver_fees.push(driver_fee);
              return s;
            }
          })
          .filter((s) => s != null)[0];

        let removed = settlement.schedules.filter((s) => s.id != found.id);
        removed.push(found);

        let settlementTmp = { ...settlement };
        let newTotal = 0.0;
        removed.map((s) => {
          s.driver_fees.map((f) => {
            newTotal += parseFloat(f.amount);
          });
        });

        settlementTmp.total = newTotal.toFixed(2);
        setSettlement({ ...settlementTmp, schedules: removed });
      }
    } else {
      let found = settlement.additional_payments.filter(
        (li) => li.id == lineItemId
      )[0];
      let removed = settlement.additional_payments.filter(
        (li) => li.id != lineItemId
      );
      console.log(found.payment_type);
      if (found.payment_type == "deduction" && value >= 0) {
        value = value * -1;
      }

      found[field] = field == "amount" ? parseFloat(value) : value;
      removed.push(found);
      let newTotal = 0.0;
      let settlementTmp = { ...settlement };

      removed.map((li) => {
        newTotal += li.amount;
      });
      console.log(newTotal);
      settlementTmp.total = newTotal.toFixed(2);
      setSettlement({ ...settlementTmp, additional_payments: removed });
    }
  };

  const save = () => {
    // Remove Fake Ids
    let schedules = settlement.schedules.map((s) => {
      s.driver_fees.map((f) => {
        if (!isNaN(f.id)) {
          f.id = null;
        }
      });

      return s;
    });

    let additional_payments = settlement.additional_payments.map((li) => {
      if (!isNaN(li.id)) {
        li.id = null;
      }
      return li;
    });
    settlement.schedules = schedules;
    settlement.additional_payments = additional_payments;
    updateSettlement(settlement, (result) => {
      setIsEdit(false);
      setSettlement(result);
    });
  };

  return (
    <>
      <div>
        {isEdit ? (
          <>
            <Button
              onClick={() => {
                save();
              }}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <Button
              startIcon={<Download />}
              onClick={() =>
                toPDF(
                  `driver-pay-schedule-${props.driver.id}`,
                  `${props.driver.first_name}_${props.driver.last_name}-SETTLEMENT`
                )
              }
            >
              Download PDF
            </Button>
            <Button
              onClick={() => {
                setIsEdit(true);
              }}
            >
              Edit
            </Button>
          </>
        )}
      </div>
      <div
        id={`driver-pay-schedule-${props.driver.id}`}
        style={{
          display: props.visible ? "block" : "none",
          padding: "5% 1%",
          width: "900",
        }}
      >
        <InvoiceHeader>
          <InvoiceInfo>
            <InvoiceBold>F&C Trucking and Repair LLC</InvoiceBold>
            <InvoiceNormal>112 Jabez St, Ste 109</InvoiceNormal>
            <InvoiceNormal>Newark NJ, 07105</InvoiceNormal>
            <InvoiceNormal>(833) 419-3278</InvoiceNormal>
          </InvoiceInfo>
          <InvoiceInfo>
            <InvoiceBold>
              {props.driver.driver_type != "owner_operator"
                ? `${props.driver.first_name} ${props.driver.last_name}`
                : props.driver.company_name}
            </InvoiceBold>
            <InvoiceNormal>{`${props.date
              .startOf("week")
              .format(format)} - ${props.date
              .endOf("week")
              .format(format)}`}</InvoiceNormal>
            <InvoiceNormal>Driver Settlement</InvoiceNormal>
          </InvoiceInfo>
        </InvoiceHeader>
        <table
          id={`driver-pay-table-${props.driver ? props.driver.id : ""}`}
          style={{ ...invoiceTableInlineStyle, width: "90%", margin: "5%" }}
        >
          {isEdit ? (
            ""
          ) : (
            <tr>
              <th
                colSpan={"5"}
                style={{
                  ...invoiceHeaderInlineStyle,
                  textAlign: "center",
                  fontSize: "2rem",
                  borderRadius: "10px 10px 0 0",
                }}
              >
                Weekly Moves
              </th>
            </tr>
          )}
          <tr>
            <th style={invoiceHeaderInlineStyle}>Load #/Container</th>
            <th style={invoiceHeaderInlineStyle}>Date</th>
            <th style={invoiceHeaderInlineStyle}>Pickup Location</th>
            <th style={invoiceHeaderInlineStyle}>Destination</th>
            <th style={invoiceHeaderInlineStyle}>Fee</th>
          </tr>
          {(settlement != null ? settlement : props).schedules
            .sort(
              (a, b) =>
                new Date(a.appointment_time) - new Date(b.appointment_time)
            )
            .map((s) => {
              total +=
                total + s.driver_fees.length > 0 && s.driver_fees[0] != null
                  ? parseFloat(s.driver_fees[0].amount)
                  : 0.0;
              if (settlement != null) {
                if (
                  !isEdit &&
                  s.driver_fees.length > 0 &&
                  s.driver_fees[0].amount > 0
                ) {
                  return (
                    <tr>
                      <td
                        style={{
                          ...invoiceLineItemsInlineStyle,
                          padding: "1%",
                        }}
                      >
                        #{s.job_container.load_number}/
                        {s.job_container.container.number}
                      </td>
                      <td style={invoiceLineItemsInlineStyle}>
                        {new Date(s.appointment_time).toLocaleDateString()}
                      </td>
                      <td style={invoiceLineItemsInlineStyle}>
                        {s.pickup_location.name}
                      </td>
                      <td style={invoiceLineItemsInlineStyle}>
                        {s.drop_location.name}
                      </td>
                      <td
                        style={{
                          ...invoiceLineItemsInlineStyle,
                          textAlign: "right",
                          padding: "1%",
                        }}
                      >
                        $
                        {s.driver_fees.length > 0
                          ? s.driver_fees[0].amount
                          : 0.0}
                      </td>
                    </tr>
                  );
                } else if (isEdit) {
                  return (
                    <tr>
                      <td
                        style={{
                          ...invoiceLineItemsInlineStyle,
                          padding: "1%",
                        }}
                      >
                        #{s.job_container.load_number}/
                        {s.job_container.container.number}
                      </td>
                      <td style={invoiceLineItemsInlineStyle}>
                        {new Date(s.appointment_time).toLocaleDateString()}
                      </td>
                      <td style={invoiceLineItemsInlineStyle}>
                        {s.pickup_location.name}
                      </td>
                      <td style={invoiceLineItemsInlineStyle}>
                        {s.drop_location.name}
                      </td>
                      <td
                        style={{
                          ...invoiceLineItemsInlineStyle,
                          textAlign: "center",
                        }}
                      >
                        <Input
                          type="number"
                          value={
                            s.driver_fees.length > 0
                              ? s.driver_fees[0].amount
                              : 0.0
                          }
                          onChange={(e) => {
                            onLineItemUpdated(
                              s.id,
                              s.driver_fees.length > 0
                                ? s.driver_fees[0].id
                                : null,
                              "fee-amount",
                              e.target.value
                            );
                          }}
                          startAdornment={<InputAdornment>$</InputAdornment>}
                        />
                      </td>
                    </tr>
                  );
                }
              } else {
                return (
                  <tr>
                    <td
                      style={{ ...invoiceLineItemsInlineStyle, padding: "1%" }}
                    >
                      #{s.job_container.load_number}
                    </td>
                    <td style={invoiceLineItemsInlineStyle}>
                      {new Date(s.appointment_time).toLocaleDateString()}
                    </td>
                    <td style={invoiceLineItemsInlineStyle}>
                      {s.pickup_location.name}
                    </td>
                    <td style={invoiceLineItemsInlineStyle}>
                      {s.drop_location.name}
                    </td>
                    <td
                      style={{
                        ...invoiceLineItemsInlineStyle,
                        textAlign: "right",
                        padding: "1%",
                      }}
                    >
                      $
                      {s.driver_fees.length > 0
                        ? s.driver_fees[0].amount.toFixed(2)
                        : 0.0}
                    </td>
                  </tr>
                );
              }
            })}
          <tr style={{ backgroundColor: "lightgrey" }}>
            <td style={{ textAlign: "left", padding: "1%" }}>Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: "right", padding: "1%" }}>
              ${total.toFixed(2)}
            </td>
          </tr>
        </table>
        {settlement ? (
          <>
            <table
              id={`invoice-table-quote`}
              style={{ ...invoiceTableInlineStyle, width: "90%", margin: "5%" }}
            >
              {isEdit ? (
                <tr>
                  <th
                    style={{
                      ...invoiceHeaderInlineStyle,
                      textAlign: "center",
                      fontSize: "2rem",
                      borderRadius: "10px 0 0 0",
                    }}
                  >
                    Additional Payments
                  </th>
                  <th style={invoiceHeaderInlineStyle}>
                    <Button
                      style={{ color: "white" }}
                      onClick={() => onLineItemAdded()}
                      startIcon={<Add />}
                    >
                      Add Line
                    </Button>
                  </th>
                  <th
                    colSpan={"2"}
                    style={{
                      ...invoiceHeaderInlineStyle,
                      borderRadius: "0 10px 0 0",
                    }}
                  ></th>
                </tr>
              ) : (
                <tr>
                  <th
                    colSpan={"3"}
                    style={{
                      ...invoiceHeaderInlineStyle,
                      textAlign: "center",
                      fontSize: "2rem",
                      borderRadius: "10px 10px 0 0",
                    }}
                  >
                    Additional Payments
                  </th>
                </tr>
              )}

              <tr>
                <th style={invoiceHeaderInlineStyle}>Type</th>
                <th style={invoiceHeaderInlineStyle}>Notes</th>
                <th style={invoiceHeaderInlineStyle}>Amount</th>
                {isEdit ? <th style={invoiceHeaderInlineStyle}></th> : ""}
              </tr>
              {settlement.additional_payments
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                .map((li) => {
                  newTotal += li.amount;
                  if (isEdit) {
                    return (
                      <tr>
                        <td style={invoiceLineItemsInlineStyle}>
                          <Select
                            value={li.payment_type}
                            onChange={(e) => {
                              onLineItemUpdated(
                                null,
                                li.id,
                                "payment_type",
                                e.target.value
                              );
                            }}
                          >
                            <MenuItem value={"bonus"}>Bonus</MenuItem>
                            <MenuItem value={"expense"}>Expense</MenuItem>
                            <MenuItem value={"deduction"}>Deduction</MenuItem>
                          </Select>
                        </td>
                        <td
                          style={{
                            ...invoiceLineItemsInlineStyle,
                            textAlign: "left",
                          }}
                        >
                          <Input
                            type="text"
                            value={li.note}
                            onChange={(e) => {
                              onLineItemUpdated(
                                null,
                                li.id,
                                "note",
                                e.target.value
                              );
                            }}
                          />
                        </td>
                        <td
                          style={{
                            ...invoiceLineItemsInlineStyle,
                            textAlign: "center",
                          }}
                        >
                          <Input
                            value={li.amount}
                            onChange={(e) => {
                              onLineItemUpdated(
                                null,
                                li.id,
                                "amount",
                                e.target.value
                              );
                            }}
                            startAdornment={<InputAdornment>$</InputAdornment>}
                          />
                        </td>
                        <td
                          style={{
                            ...invoiceLineItemsInlineStyle,
                            textAlign: "center",
                          }}
                        >
                          <Button
                            startIcon={<RemoveCircle />}
                            onClick={() => {
                              onLineItemRemoved(li.id);
                            }}
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    );
                  } else {
                    if (li.amount != 0) {
                      return (
                        <tr>
                          <td
                            style={{
                              ...invoiceLineItemsInlineStyle,
                              padding: "1%",
                            }}
                          >
                            {titlize(li.payment_type)}
                          </td>
                          <td
                            style={{
                              ...invoiceLineItemsInlineStyle,
                              textAlign: "left",
                            }}
                          >
                            {li.note}
                          </td>
                          <td
                            style={{
                              ...invoiceLineItemsInlineStyle,
                              textAlign: "center",
                            }}
                          >
                            ${li.amount.toFixed(2)}
                          </td>
                        </tr>
                      );
                    }
                  }
                })}
              <tr style={{ backgroundColor: "lightgrey" }}>
                <td style={{ textAlign: "left", padding: "1%" }}>Total</td>
                <td></td>
                <td style={{ textAlign: "center" }}>${newTotal.toFixed(2)}</td>
                {isEdit ? <td></td> : ""}
              </tr>
            </table>
            <table
              id={`invoice-table-quote`}
              style={{ ...invoiceTableInlineStyle, width: "90%", margin: "5%" }}
            >
              <tr>
                <th
                  colSpan={"2"}
                  style={{
                    ...invoiceHeaderInlineStyle,
                    textAlign: "center",
                    fontSize: "2rem",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  Settlement Breakdown
                </th>
              </tr>
              <tr>
                <td
                  style={{
                    ...invoiceLineItemsInlineStyle,
                    textAlign: "left",
                    padding: "1%",
                  }}
                >
                  Weekly Moves
                </td>
                <td
                  style={{ ...invoiceLineItemsInlineStyle, textAlign: "left" }}
                >
                  ${total.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    ...invoiceLineItemsInlineStyle,
                    textAlign: "left",
                    padding: "1%",
                  }}
                >
                  Additional Payments
                </td>
                <td
                  style={{ ...invoiceLineItemsInlineStyle, textAlign: "left" }}
                >
                  ${newTotal.toFixed(2)}
                </td>
              </tr>
              <tr style={{ backgroundColor: "lightgrey" }}>
                <td
                  style={{
                    ...invoiceLineItemsInlineStyle,
                    textAlign: "left",
                    padding: "1%",
                  }}
                >
                  Total
                </td>
                <td
                  style={{ ...invoiceLineItemsInlineStyle, textAlign: "left" }}
                >
                  ${(total + newTotal).toFixed(2)}
                </td>
              </tr>
            </table>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export const POD = (props) => {
  const [schedule, setSchedule] = useState();
  const [additionalSchedule, setAdditionalSchedule] = useState();
  const [job, setJob] = useState();
  const [load, setLoad] = useState();
  const [container, setContainer] = useState();

  React.useEffect(() => {
    setSchedule(props.schedule);
    setLoad(props.load);
    setJob(props.job);
    setAdditionalSchedule(props.additionalSchedule);
    if (props.load) {
      setContainer(props.load.container);
    }
  }, [props.visible, props.schedule, props.load, props.job]);

  const getLoadedUnLoaded = (job, pickup_location, drop_location) => {
    if (!job || !pickup_location || !drop_location) {
      return "???";
    }
    console.log(job.job_type);
    console.log(pickup_location.location_type);
    console.log(drop_location.location_type);
    if (imports.includes(job.job_type)) {
      return (pickup_location.location_type == "terminal" ||
        pickup_location.location_type == "yard") &&
        (drop_location.location_type == "yard" ||
          drop_location.location_type == "warehouse")
        ? "(Loaded)"
        : "(Empty)";
    } else {
      return (pickup_location.location_type == "terminal" ||
        pickup_location.location_type == "yard") &&
        (drop_location.location_type == "yard" ||
          drop_location.location_type == "warehouse")
        ? "(Empty)"
        : "(Loaded)";
    }
  };

  const getAdditionalSchedule = (additionalSchedule) => {
    return (
      <>
        <tr>
          <PODScheduleTD>
            <PODNormalCentered>{`Pickup ${
              job &&
              additionalSchedule &&
              additionalSchedule.pickup_location &&
              additionalSchedule.drop_location
                ? getLoadedUnLoaded(
                    job,
                    schedule.pickup_location,
                    schedule.drop_location
                  )
                : ""
            } from ${
              schedule ? titlize(schedule.pickup_location.location_type) : "n/a"
            } ${""}`}</PODNormalCentered>
          </PODScheduleTD>
          <PODScheduleTD>
            {additionalSchedule && additionalSchedule.pickup_location ? (
              <>
                <PODNormal>{additionalSchedule.pickup_location.name}</PODNormal>
                <PODNormal>
                  {additionalSchedule.pickup_location.address_1}
                </PODNormal>
                <PODNormal>{`${additionalSchedule.pickup_location.city}, ${additionalSchedule.pickup_location.state} ${additionalSchedule.pickup_location.zip}`}</PODNormal>
              </>
            ) : (
              <></>
            )}
          </PODScheduleTD>
          <PODScheduleTD></PODScheduleTD>
          <PODScheduleTD>
            <PODNormal>Time In: ____:____ AM / PM</PODNormal>
            <PODNormal>Time Out: ____:____ AM / PM</PODNormal>
          </PODScheduleTD>
        </tr>
        <tr>
          <PODScheduleTD>
            <PODNormalCentered>{`${
              additionalSchedule && additionalSchedule.is_delivery_location
                ? "Deliver"
                : "Drop"
            } ${
              job &&
              additionalSchedule &&
              additionalSchedule.pickup_location &&
              schedule.drop_location
                ? getLoadedUnLoaded(
                    job,
                    schedule.pickup_location,
                    schedule.drop_location
                  )
                : ""
            } ${
              schedule ? titlize(schedule.drop_location.location_type) : "n/a"
            }`}</PODNormalCentered>
          </PODScheduleTD>
          <PODScheduleTD>
            {additionalSchedule && additionalSchedule.drop_location ? (
              <>
                <PODNormal>{additionalSchedule.drop_location.name}</PODNormal>
                <PODNormal>
                  {additionalSchedule.drop_location.address_1}
                </PODNormal>
                <PODNormal>{`${additionalSchedule.drop_location.city}, ${additionalSchedule.drop_location.state} ${additionalSchedule.drop_location.zip}`}</PODNormal>
              </>
            ) : (
              <></>
            )}
          </PODScheduleTD>
          <PODScheduleTD>
            <PODNormal>
              {additionalSchedule && additionalSchedule.appointment_time
                ? new Date(additionalSchedule.appointment_time).toLocaleString()
                : ""}
            </PODNormal>
          </PODScheduleTD>
          <PODScheduleTD>
            <PODNormal>Time In: ____:____ AM / PM</PODNormal>
            <PODNormal>Time Out: ____:____ AM / PM</PODNormal>
          </PODScheduleTD>
        </tr>
      </>
    );
  };

  return (
    <>
      {!schedule || !schedule.pickup_location || !schedule.drop_location ? (
        <>!!!Check Schedule!!!</>
      ) : (
        <>
          <div>
            <Button
              onClick={() =>
                toPDF(
                  "pod-pdf",
                  `${job.ref_number}-${load.load_number}-${container.number}-POD`
                )
              }
              startIcon={<Save />}
            >
              Download POD
            </Button>
          </div>
          <div
            id="pod-pdf"
            style={{
              display: props.visible ? "block" : "none",
              padding: "5%",
              width: "900",
            }}
          >
            <PODHeader>
              <PODInfo></PODInfo>
              <PODInfo>
                <PODBold>F&C Trucking and Repair LLC</PODBold>
                <PODNormal>112 Jabez St, Ste 109</PODNormal>
                <PODNormal>Newark NJ, 07105</PODNormal>
                <PODNormal>(833) 419-3278</PODNormal>
              </PODInfo>
              <PODInfo>
                <PODBold>Pickup/Delivery Receipt</PODBold>
              </PODInfo>
            </PODHeader>
            <PODBar>
              <PODBold>{`DRIVER: ${
                schedule && schedule.driver
                  ? `${schedule.driver.first_name} ${schedule.driver.last_name}`
                  : "Not Set"
              } / TRK: ${
                schedule && schedule.truck ? schedule.truck.number : "Not Set"
              } / CONTAINER#: ${container ? container.number : "Not Set"} - ${
                container ? titlize(container.container_type) : "Not Set"
              }`}</PODBold>
            </PODBar>
            <PODHeader>
              <PODInfo>
                <PODNormal>{`Load #: ${
                  load ? load.load_number : "n/a"
                }`}</PODNormal>
                <PODNormal>{`Booking #: ${
                  load && load.booking_number ? load.booking_number : "n/a"
                }`}</PODNormal>
                <PODNormal>{`BOL #: ${
                  load && load.bol ? load.bol : "n/a"
                }`}</PODNormal>
                <PODNormal>{`Ref #: ${
                  job ? job.ref_number : "n/a"
                }`}</PODNormal>
                <PODNormal>{`Pickup LFD: ${
                  load && load.last_free_day
                    ? new Date(load.last_free_day).toLocaleDateString()
                    : "n/a"
                }`}</PODNormal>
              </PODInfo>
              <PODInfo>
                <PODNormal>{`Container #: ${
                  container ? container.number : "n/a"
                }`}</PODNormal>
                <PODNormal>{`Size: ${
                  container ? titlize(container.container_type) : "n/a"
                }`}</PODNormal>
                <PODNormal>{`SSL: ${
                  container ? titlize(container.ship_line) : "n/a"
                }`}</PODNormal>
                <PODNormal>{`Chassis: ${
                  schedule && schedule.chassis ? schedule.chassis.number : "n/a"
                }`}</PODNormal>
                <PODNormal>{`Seal #: ${
                  load && load.seal ? load.seal : "n/a"
                }`}</PODNormal>
              </PODInfo>
              <PODInfo>
                <PODNormal>{`Weight #: ${
                  load && load.weight ? load.weight : "n/a"
                }`}</PODNormal>
                <PODNormal>{`Commodity: ${
                  load && load.commodity ? load.commodity : "n/a"
                }`}</PODNormal>
                <PODNormal>{`Port Of Origin: ${
                  load && load.pickup_location
                    ? load.pickup_location.name
                    : "n/a"
                }`}</PODNormal>
                <PODNormal>{`Return Free Time/Cutoff: ${
                  job
                    ? imports.includes(job.job_type)
                      ? load.free_time_cut_off
                        ? new Date(load.free_time_cut_off).toLocaleDateString()
                        : "n/a"
                      : load.cut_off_date
                      ? new Date(load.cut_off_date).toLocaleDateString()
                      : "n/a"
                    : "n/a"
                }`}</PODNormal>
                <PODNormal>
                  {`Requirements: ${
                    load && load.dependencies && load.dependencies.length > 0
                      ? load.dependencies
                          .map((element) =>
                            element.includes("Underslung Genset") ||
                            element.includes("Clip-On Genset")
                              ? element + " #" + load.genset_number
                              : element
                          )
                          .join(", ")
                      : "n/a"
                  }`}
                </PODNormal>
              </PODInfo>
            </PODHeader>
            <PODScheduleTable>
              <tr>
                <PODScheduleTD>
                  <PODNormalCentered>
                    {job &&
                    schedule &&
                    schedule.pickup_location &&
                    schedule.chassis == null
                      ? `Bobtail from ${schedule.pickup_location.name}`
                      : `Pickup ${
                          job &&
                          schedule &&
                          schedule.pickup_location &&
                          schedule.drop_location
                            ? getLoadedUnLoaded(
                                job,
                                schedule.pickup_location,
                                schedule.drop_location
                              )
                            : ""
                        } from ${
                          schedule
                            ? titlize(schedule.pickup_location.location_type)
                            : "n/a"
                        } ${""}`}
                  </PODNormalCentered>
                </PODScheduleTD>
                <PODScheduleTD>
                  {schedule && schedule.pickup_location ? (
                    <>
                      <PODNormal>{schedule.pickup_location.name}</PODNormal>
                      <PODNormal>
                        {schedule.pickup_location.address_1}
                      </PODNormal>
                      <PODNormal>{`${schedule.pickup_location.city}, ${schedule.pickup_location.state} ${schedule.pickup_location.zip}`}</PODNormal>
                    </>
                  ) : (
                    <></>
                  )}
                </PODScheduleTD>
                <PODScheduleTD></PODScheduleTD>
                <PODScheduleTD>
                  <PODNormal>Time In: ____:____ AM / PM</PODNormal>
                  <PODNormal>Time Out: ____:____ AM / PM</PODNormal>
                </PODScheduleTD>
              </tr>
              <tr>
                <PODScheduleTD>
                  <PODNormalCentered>
                    {job &&
                    schedule &&
                    schedule.drop_location &&
                    schedule.chassis == null
                      ? `Arrive at ${schedule.drop_location.name}`
                      : `${
                          schedule && schedule.is_delivery_location
                            ? "Deliver"
                            : "Drop"
                        } ${
                          job &&
                          schedule &&
                          schedule.pickup_location &&
                          schedule.drop_location
                            ? getLoadedUnLoaded(
                                job,
                                schedule.pickup_location,
                                schedule.drop_location
                              )
                            : ""
                        } ${
                          schedule
                            ? titlize(schedule.drop_location.location_type)
                            : "n/a"
                        }`}
                  </PODNormalCentered>
                </PODScheduleTD>
                <PODScheduleTD>
                  {schedule && schedule.drop_location ? (
                    <>
                      <PODNormal>{schedule.drop_location.name}</PODNormal>
                      <PODNormal>{schedule.drop_location.address_1}</PODNormal>
                      <PODNormal>{`${schedule.drop_location.city}, ${schedule.drop_location.state} ${schedule.drop_location.zip}`}</PODNormal>
                    </>
                  ) : (
                    <></>
                  )}
                </PODScheduleTD>
                <PODScheduleTD>
                  <PODNormal>
                    {schedule && schedule.appointment_time
                      ? new Date(schedule.appointment_time).toLocaleString()
                      : ""}
                  </PODNormal>
                  {schedule ? (
                    schedule.trip_notes.map((note) => {
                      return <PODNormal>{note.entry}</PODNormal>;
                    })
                  ) : (
                    <></>
                  )}
                </PODScheduleTD>
                <PODScheduleTD>
                  <PODNormal>Time In: ____:____ AM / PM</PODNormal>
                  <PODNormal>Time Out: ____:____ AM / PM</PODNormal>
                </PODScheduleTD>
              </tr>
              {additionalSchedule ? (
                getAdditionalSchedule(additionalSchedule)
              ) : (
                <></>
              )}
            </PODScheduleTable>
            <PODHeader>
              <PODLargeCentered>
                DRIVER SIGNATURE: ___________________________ DATE:
                ____/____/____{" "}
              </PODLargeCentered>
              <PODLargeCentered>
                PRINT NAME: ___________________________{" "}
              </PODLargeCentered>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <PODLargeCentered>
                Time In: ____:____ AM / PM Time Out: ____:____ AM / PM SEAL
                INTACT? Yes / No
              </PODLargeCentered>
              <PODLargeCentered>
                CUSTOMER SIGNATURE: ___________________________ DATE:
                ____/____/____{" "}
              </PODLargeCentered>
              <PODLargeCentered>
                PRINT NAME: ___________________________{" "}
              </PODLargeCentered>
            </PODHeader>
          </div>
        </>
      )}
    </>
  );
};
