import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LargeModal, MediumModal, ModalHeader, SmallModal } from "../Modals";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { customerContactCreate } from "../../api/customers";
import Button from "@material-ui/core/Button";
import { Box, InputAdornment, Select, TextField } from "@material-ui/core";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { getAllContainers } from "../../api/containers";
import {
  getContainerTypeOptions,
  getLocationIcon,
  getSteamShipLineOptions,
} from "./Options";
import { getAllLocations } from "../../api/locations";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  addContainer,
  addSchedule,
  createAndAddContainer,
  removeSchedule,
  updateSchedule,
} from "../../api/jobs";
import moment from "moment";
import { getAllTrucks } from "../../api/trucks";
import { getAllDrivers } from "../../api/drivers";
import { getAllChassis } from "../../api/chassis";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NewLocationForm from "./NewLocationForm";
import { DateTimePicker } from "@mui/x-date-pickers";
import Close from "@mui/icons-material/Close";
import { Stop } from "@material-ui/icons";
import { Clear } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: "90%",
  },
  addIcon: {
    margin: "1%",
  },
  button: {
    margin: theme.spacing(2),
    minWidth: "25%",
    height: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

export const NewScheduleForm = (props) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(-1);
  const [newPickupLocationFormOpen, setNewPickupLocationFormOpen] =
    useState(false);
  const [newDropOffLocationFormOpen, setNewDropOffLocationFormOpen] =
    useState(false);
  const [jobContainerId, setJobContainerId] = useState(props.jobContainerId);
  const [driverId, setDriverId] = useState();
  const [truckId, setTruckId] = useState();
  const [chassisId, setChassisId] = useState();
  const [pickupLocationId, setPickupLocationId] = useState();
  const [dropLocationId, setDropLocationId] = useState();
  const [pickupLocation, setPickupLocation] = useState({});
  const [dropLocation, setDropLocation] = useState({});
  const [appointmentTime, setAppointmentTime] = useState();
  const [locations, setLocations] = useState([]);
  const [truck, setTruck] = useState();
  const [trucks, setTrucks] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState();
  const [chassis, setChassis] = useState([]);
  const [chasses, setChasses] = useState();
  const [isDeliveryLocation, setIsDeliveryLocation] = useState(false);

  const setFromSchedule = (schedule, locations, trucks, chasses, drivers) => {
    console.log(schedule);
    setIsEdit(true);
    setId(schedule.id);
    setAppointmentTime(moment(schedule.appointment_time));
    setIsDeliveryLocation(schedule.is_delivery_location);
    setChassisId(schedule.chassis_id);
    setTruckId(schedule.truck_id);
    setDriverId(schedule.driver_id);
    setPickupLocationId(schedule.pickup_location_id);
    setDropLocationId(schedule.drop_location_id);

    var foundTrucks = trucks.filter((t) => t.id == schedule.truck_id);
    if (foundTrucks.length > 0) {
      setTruck(foundTrucks[0]);
    }

    var foundDrivers = drivers.filter((t) => t.id == schedule.driver_id);
    if (foundDrivers.length > 0) {
      setDriver(foundDrivers[0]);
    }

    var foundChassis = chasses.filter((t) => t.id == schedule.chassis_id);
    if (foundChassis.length > 0) {
      setChasses(foundChassis[0]);
    }

    var foundPickupLocations = locations.filter(
      (t) => t.id == schedule.pickup_location_id
    );
    console.log(foundPickupLocations);
    if (foundPickupLocations.length > 0) {
      setPickupLocation(foundPickupLocations[0]);
    }

    var foundDropLocations = locations.filter(
      (t) => t.id == schedule.drop_location_id
    );
    console.log(foundDropLocations);
    if (foundDropLocations.length > 0) {
      setDropLocation(foundDropLocations[0]);
    }
  };

  const setTruckObj = (truckLabel) => {
    if (truckLabel) {
      var t = getTruck(truckLabel);
      setTruckId(t.id);
      setTruck(t);
    } else {
      setTruckId(null);
      setTruck(null);
    }
  };

  const setDriverObj = (driverName) => {
    if (driverName) {
      var d = getDriver(driverName);
      setDriverId(d.id);
      setDriver(d);
    } else {
      setDriverId(null);
      setDriver(null);
    }
  };

  const setChassisObj = (chassisLabel) => {
    if (chassisLabel) {
      var c = getChassis(chassisLabel);
      setChassisId(c.id);
      setChasses(c);
    } else {
      setChassisId(null);
      setChasses(null);
    }
  };

  const setPickupLocObj = (pl) => {
    console.log("Location: " + pl);
    // var pl = getLocation(locationName);
    if (pl != null) {
      setPickupLocationId(pl.id);
      setPickupLocation(pl);
    } else {
      setPickupLocationId(null);
      setPickupLocation({});
    }
  };

  const setDropLocObj = (dl) => {
    // var dl = getLocation(locationName);
    if (dl != null) {
      setDropLocationId(dl.id);
      setDropLocation(dl);
    } else {
      setDropLocationId(null);
      setDropLocation({});
    }
  };

  const getLocation = (locationName) => {
    let found = locations.filter((location) =>
      locationName.includes(location.name)
    );
    if (found.length > 0) return found[0];
  };

  const getTruck = (truckLabel) => {
    let found = trucks.filter(
      (truck) =>
        truckLabel.includes(truck.number) && truckLabel.includes(truck.plate)
    );
    if (found.length > 0) return found[0];
  };

  const getDriver = (driverName) => {
    console.log(driverName);
    let found = drivers.filter((driver) =>
      `${driver.first_name} ${driver.last_name}`.includes(driverName)
    );
    if (found.length > 0) return found[0];
  };

  const getChassis = (chassisLabel) => {
    let found = chassis.filter((chasses) =>
      chassisLabel.includes(chasses.number)
    );
    if (found.length > 0) return found[0];
  };

  React.useEffect(() => {
    if (props.visible) {
      reset();
      var update = async () => {
        let locations = [];
        let drivers = [];
        let trucks = [];
        let chasses = [];

        props.onLoading("newScheduleForm");
        await getAllLocations(async (result) => {
          locations = result;
          await setLocations(result);
        });

        await getAllTrucks(async (result) => {
          trucks = result.filter((t) => t.is_active == true);
          await setTrucks(trucks);
        });

        await getAllDrivers(async (result) => {
          drivers = result.filter((d) => d.is_active == true);
          await setDrivers(drivers);
        });

        await getAllChassis(async (result) => {
          chasses = result.filter((c) => c.is_active == true);
          await setChassis(chasses);
        });

        setJobContainerId(props.jobContainerId);
        props.onLoadingComplete("newScheduleForm");

        if (props.schedule != null) {
          setFromSchedule(props.schedule, locations, trucks, chasses, drivers);
        } else {
          setIsEdit(false);
        }

        if (props.suggestedChassis != null) {
          setChassisId(props.suggestedChassis);
          var foundChassis = chasses.filter(
            (t) => t.id == props.suggestedChassis
          );
          if (foundChassis.length > 0) {
            setChasses(foundChassis[0]);
          }
        }
      };

      update();
    }
  }, [
    props.visible,
    props.jobContainerId,
    props.schedule,
    props.suggestedChassis,
  ]);

  const submit = (isDelete = false) => {
    props.onLoading("newScheduleForm");

    let payload = {
      is_delivery_location: isDeliveryLocation,
      job_container_id: jobContainerId,
      pickup_location_id: pickupLocationId,
      drop_location_id: dropLocationId,
      chassis_id: chassisId,
      driver_id: driverId,
      truck_id: truckId,
      appointment_time: appointmentTime,
      drop_location_name: dropLocation.name,
      pickup_location_name: pickupLocation.name,
    };

    if (isEdit) {
      payload.id = id;
    }

    console.log(payload);

    if (isEdit) {
      if (isDelete) {
        removeSchedule(payload, (result) => {
          props.onClose(payload, true);
        });
      } else {
        updateSchedule(payload, (result) => {
          props.onClose(result);
        });
      }
    } else {
      addSchedule(payload, (result) => {
        props.onClose(result);
      });
    }
  };

  const reset = () => {
    setChasses(null);
    setTruck(null);
    setDropLocation(null);
    setPickupLocation(null);
    setDriver(null);
    setAppointmentTime(moment());
  };

  const getChassisLabel = (chassis) => {
    if (chassis == null) {
      return "";
    }
    if (chassis.max_length > -1) {
      return `${chassis.number} - ${chassis.length}FT Ext -> ${chassis.max_length}FT`;
    } else {
      return `${chassis.number} - ${chassis.length}FT`;
    }
  };

  const onNewPickupFormClose = (newLocation) => {
    setNewPickupLocationFormOpen(false);
    let newLocationList = locations;

    newLocationList.push(newLocation);

    setLocations(newLocationList);

    console.log(newLocation);

    setPickupLocation(newLocation.name);
  };

  const onNewDropOffFormClose = (newLocation) => {
    setNewDropOffLocationFormOpen(false);
    let newLocationList = locations;

    newLocationList.push(newLocation);

    setLocations(newLocationList);

    setDropLocation(newLocation.name);
  };

  return (
    <Fragment>
      <MediumModal
        visible={props.visible}
        closeCallback={props.onClose}
        zIndex={"100"}
      >
        <ModalHeader>Schedule Move</ModalHeader>
        <FormControl className={classes.formControl}></FormControl>
        <FormControl className={classes.formControl}>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            value={pickupLocation}
            getOptionLabel={(l) => `${l.name} - ${l.city} - ${l.state}`}
            options={locations}
            renderOption={(props, location) => (
              <Box component="li" {...props}>
                {getLocationIcon(location.location_type)}
                {location.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pickup Location"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  startAdornment: pickupLocation ? (
                    <InputAdornment position="start">
                      {getLocationIcon(pickupLocation.location_type)}
                    </InputAdornment>
                  ) : null,
                }}
              />
            )}
            onChange={(obj, val, reason) => {
              console.log("Reason: " + reason);
              if (reason == "clear") {
                val = null;
              }
              setPickupLocObj(val);
            }}
          />
          <AddCircleIcon
            className={classes.addIcon}
            sx={{ fontSize: 20 }}
            onClick={() => setNewPickupLocationFormOpen(true)}
          />
          <Autocomplete
            value={dropLocation}
            getOptionLabel={(l) => `${l.name} - ${l.city} - ${l.state}`}
            options={locations}
            renderOption={(props, location) => (
              <Box component="li" {...props}>
                {getLocationIcon(location.location_type)}
                {location.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Delivery Location"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  startAdornment: (
                    <InputAdornment position="start">
                      {dropLocation
                        ? getLocationIcon(dropLocation.location_type)
                        : null}
                    </InputAdornment>
                  ),
                }}
              />
            )}
            onChange={(obj, val, reason) => {
              if (reason == "clear") {
                val = null;
              }
              setDropLocObj(val);
            }}
          />
          <AddCircleIcon
            className={classes.addIcon}
            sx={{ fontSize: 20 }}
            onClick={() => setNewDropOffLocationFormOpen(true)}
          />
          <Autocomplete
            value={truck ? `${truck.number} - ${truck.plate}` : ""}
            options={trucks.map((t) => `${t.number} - ${t.plate}`)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vehicles"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
            onChange={(obj, val, reason) => {
              if (reason == "clear") {
                val = null;
              }
              setTruckObj(val);
            }}
          />
          <Autocomplete
            value={driver ? `${driver.first_name} ${driver.last_name}` : ""}
            options={drivers.map((d) => `${d.first_name} ${d.last_name}`)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Driver"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
            onChange={(obj, val, reason) => {
              if (reason == "clear") {
                val = null;
              }
              setDriverObj(val);
            }}
          />
          <Autocomplete
            value={getChassisLabel(chasses)}
            options={chassis
              .sort((a, b) => a.number.localeCompare(b.number))
              .map((c) => getChassisLabel(c))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Chassis"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
            onChange={(obj, val, reason) => {
              if (reason == "clear") {
                val = null;
              }
              setChassisObj(val);
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DateTimePicker
                label="Appointment Time"
                inputFormat="MM/DD/YYYY hh:mm a"
                value={appointmentTime}
                onChange={setAppointmentTime}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
          <FormControlLabel
            control={
              <Checkbox
                checked={isDeliveryLocation}
                onChange={(e) => {
                  setIsDeliveryLocation(e.target.checked);
                }}
              />
            }
            label="Delivery Location?"
          />
        </FormControl>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => submit()}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
        {isEdit ? (
          <Button
            className={classes.button}
            style={{ color: "red" }}
            variant="contained"
            onClick={() => submit(true)}
          >
            Delete
          </Button>
        ) : null}
      </MediumModal>
      <NewLocationForm
        visible={newPickupLocationFormOpen}
        onClose={onNewPickupFormClose}
      />
      <NewLocationForm
        visible={newDropOffLocationFormOpen}
        onClose={onNewDropOffFormClose}
      />
    </Fragment>
  );
};

export default NewScheduleForm;
