export const getChassissWithLocations = (chasses) => {
  let results = [];

  chasses.map((chassis) => {
    if (chassis.chassis_snapshot && chassis.chassis_snapshot.location) {
      chassis.mapLocation = {
        zoom: 15,
        lat: parseFloat(chassis.chassis_snapshot.location.latitude),
        lng: parseFloat(chassis.chassis_snapshot.location.longitude),
      };
      if (!isNaN(chassis.mapLocation.lat) && !isNaN(chassis.mapLocation.lng)) {
        results.push(chassis);
      }
    }
  });

  return results;
};
