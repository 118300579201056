const token = document.querySelector("[name=csrf-token]").content;

export var createComplianceItem = (item, onSuccess, onError) => {
    return fetch(`/api/v1/compliance`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      method: 'POST',
      body: JSON.stringify(item)
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };

  export var renewComplianceItem = (item, onSuccess, onError) => {
    return fetch(`/api/v1/compliance`, {
      credentials: "include",
      headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
      method: 'PUT',
      body: JSON.stringify(item)
    })
      .then((res) => res.json())
      .then((decoded) => {
        console.log(decoded);
        if (onSuccess != null) onSuccess(decoded);
      });
  };