import React from "react";
import { login } from "../api/user";
import { Navigate } from "react-router-dom";
import LoginForm from "../components/forms/LoginForm";
import { authenticate } from "../store/user";
import { connect } from "react-redux";


class WelcomePage extends React.Component {
  

  constructor(props) {
    super(props);
    this.state = {
      navigation: null,
    };

    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount() {
  }

  onSubmit(email, password) {
    this.props.login({email: email, password: password}, (result) => {
      console.log("Login Callback");
      this.setState({ navigation: "/admin-dashboard" });
    });
  }

  render() {
    if (this.state.navigation != null) {
      return <Navigate to={this.state.navigation} />;
    }
    return (
      <LoginForm onSubmit={this.onSubmit} />
    );
  }
}

function mapStateToProps(state){
  return{
    currentUser: state.user
  }
}

function mapDispatchToProps(dispatch){
  return {
    login: (loginParams, callback) => dispatch(authenticate(loginParams, callback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage)
