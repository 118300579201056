import React from "react";
import styled from "styled-components";

export const MainChatContainer = styled.div`
  margin: 5%;
  width: 90%;
  float: left;
`;

export const ChatListContainer = styled.div`
  width: 30%;
  float: left;
  min-height: 500px;
  border: solid grey 1px;
  border-radius: 5px;
`;

export const ChatListItem = styled.div`
  background-color: ${(props) => (props.selected ? "grey" : "white")};
  margin: 5% 0;
  border-bottom: solid grey 1px;
  padding: 1%;
  border-bottom: solid grey 1px;
`;

export const ChatMessageContainer = styled.div`
  width: 70%;
  float: left;
  min-height: 500px;
  border: solid grey 1px;
  border-radius: 5px;
  max-height: 500px;
  overflow: scroll;
`;

export const ChatMessage = styled.p`
  text-align: ${(props) => (props.isDispatcher ? "right" : "left")};
  width: 100%;
  float: left;
  padding: 5%;
`;

export const ChatBody = styled.p`
  text-align: ${(props) => (props.isDispatcher ? "right" : "left")};
  width: 100%;
  float: left;
`;

export const ChatInfo = styled.p`
  text-align: ${(props) => (props.isDispatcher ? "right" : "left")};
  color: grey;
  width: 100%;
  float: left;
  font-size: 0.7rem;
`;
