import moment from "moment";
import { max } from "moment/moment";
import React, { Fragment } from "react";
import styled from "styled-components";
import { getAllChassis } from "../api/chassis";
import { getSchedulesBetween } from "../api/jobs";
import { getAllLocations } from "../api/locations";
import { getAllTrucks, getAllTrucksOnMotive } from "../api/trucks";
import { getStatusColor } from "../components/forms/Options";
import MultiMapView from "../components/MultiMapView";
import { getChassissWithLocations } from "../helpers/chassis";
import { getTrucksWithLocations } from "../helpers/trucks";
import { titlize } from "../util/functions";
import SecuredView from "./SecuredView";

const ScheduleItem = styled.div`
  visibility: visible;
  transition: visibility 0s, opacity 1s linear;
  width: 100%;
  display: inline-table;
  border-bottom: 1px solid black;
`;

const CalendarContainer = styled.div`
  float: left;
  width: 80%;
`;

const DayContainer = styled.div`
  width: 33%;
  float: left;
  display: block;
  padding-bottom: 33%;
  border: 1px solid black;
  position: relative;
`;

const SideTitle = styled.div`
  float: left;
  height: 100%;
  color: black;
  font-size: 3rem;
  font-weight: bold;
  text-orientation: upright;
  writing-mode: vertical-lr;
  text-align: center;
  padding: 3%;
`;
const listSize = 6;
let maxListSize = 6;

class MoveOverview extends React.Component {
  constructor(props) {
    super(props);
    let dates = [];
    let startDate = moment().startOf("day");
    let endDate = moment().startOf("day").add(6, "days").endOf("day");

    dates.push(startDate);
    [1, 2, 3, 4, 5].forEach((num) => {
      dates.push(moment().startOf("day").add(num, "days"));
    });
    dates.push(endDate);
    this.state = {
      dates: dates,
      schedules: [],
      chassis: [],
      trucks: [],
      locations: [],
      motiveTrucks: [],
      startIndex: 0,
      currentView: "deliveries",
      numCycles: 0,
    };
    this.handleMessage = this.handleMessage.bind(this);
    this.incrementDisplayIndex = this.incrementDisplayIndex.bind(this);
  }

  handleMessage(message) {
    console.log(message);
    this.reload();
  }

  async reload() {
    let dates = [];
    let startDate = moment().startOf("day");
    let endDate = moment().startOf("day").add(6, "days").endOf("day");

    dates.push(startDate);
    [1, 2, 3, 4, 5].forEach((num) => {
      dates.push(moment().startOf("day").add(num, "days"));
    });

    dates.push(endDate);

    await getSchedulesBetween(
      { start: dates[0].valueOf() / 1000, end: dates[6].valueOf() / 1000 },
      (results) => {
        this.setState({
          schedules: results,
          dates: dates,
        });
      }
    );

    await getAllTrucks((result) => {
      this.setState({ trucks: result });
    }, null, ["location"]);

    await getAllChassis((result) => {
      this.setState({ chassis: result });
    }, null, ["location"]);

    await getAllLocations((result) => {
      this.setState({ locations: result.filter((location) => location.geo_fence != null) });
    });
  }

  componentDidMount() {
    getSchedulesBetween(
      {
        start: this.state.dates[0].valueOf() / 1000,
        end: this.state.dates[6].valueOf() / 1000,
      },
      (results) => {
        this.setState({
          schedules: results,
        });
      }
    );

    getAllTrucks((result) => {
      this.setState({ trucks: result });
    }, null, ["location"]);

    getAllChassis((result) => {
      this.setState({ chassis: result });
    }, null, ["location"]);

    getAllLocations((result) => {
      this.setState({ locations: result.filter((location) => location.geo_fence != null) });
    });

    window.setInterval(this.incrementDisplayIndex, 10000);
  }

  incrementDisplayIndex() {
    // Set interval for checking
    let index = this.state.startIndex + listSize + 1;
    let view = this.state.currentView;
    console.log(
      "Current Index: " +
        this.state.startIndex +
        " Current View: " +
        this.state.currentView +
        " Max Size: " +
        maxListSize
    );
    if (index > maxListSize) {
      index = 0;
      if (view == "deliveries") {
        view = "local moves";
      } else {
        view = "deliveries";
      }
      console.log(
        "Setting Start Index: " +
          index +
          " Current View: " +
          this.state.currentView
      );
      this.setState({ startIndex: index, currentView: view, numCycles: 0 });
    } else {
      console.log("Setting Start Index: " + index);
      this.setState({ startIndex: index, numCycles: this.state.numCycles + 1 });
    }
  }

  getSeheduleRows(schedules, indexStart) {
    let index = 1;

    let sorted = schedules
      .sort(function (a, b) {
        return new Date(a.appointment_time) - new Date(b.appointment_time);
      })
      .filter((s) => s != null);
    if (indexStart > sorted.length) {
      indexStart = 0;
    }
    return sorted.map((schedule) => {
      console.log(index);
      let display =
        (index - indexStart <= listSize && index >= indexStart) ||
        sorted.length <= listSize;
      let row = (
        <ScheduleItem
          style={{
            opacity: display ? "100" : "0",
            position: display ? "static" : "absolute",
          }}
        >
          <tr
            style={{
              backgroundColor: getStatusColor(schedule.status),
              fontWeight: "bold",
            }}
          >
            <td rowSpan={3} style={{ fontWeight: "bold" }}>
              {index}
            </td>
            <td colSpan={2}>{`#${schedule.job_container.load_number} ${
              schedule.pickup_location.name
            } -> ${
              schedule.is_delivery_location
                ? `${schedule.drop_location.city},${schedule.drop_location.state}`
                : schedule.drop_location.name
            }`}</td>
          </tr>
          <tr style={{ backgroundColor: getStatusColor(schedule.status) }}>
            <td>
              {new Date(schedule.appointment_time).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
              })}
            </td>
            <td>{titlize(schedule.status)}</td>
          </tr>
          <tr style={{ backgroundColor: getStatusColor(schedule.status) }}>
            <td>
              {schedule.driver
                ? `${schedule.driver.first_name} ${schedule.driver.last_name}`
                : "No Driver"}
            </td>
            <td>{`Trk: ${schedule.truck ? schedule.truck.number : "--"} Ch:${
              schedule.chassis ? schedule.chassis.number : "--"
            }`}</td>
          </tr>
        </ScheduleItem>
      );
      index++;
      return row;
    });
  }

  getDayContainers(dates, schedules, startIndex, currentView) {
    let index = 0;
    maxListSize = 0;
    let containers = dates.map((date) => {
      if (index >= 5) {
        return null;
      }

      let matchedSchedules = schedules
        .map((schedule) => {
          let appt_time = moment(schedule.appointment_time);
          if (
            appt_time.isAfter(date.startOf("day")) &&
            appt_time.isBefore(date.endOf("day"))
          ) {
            return schedule;
          }
        })
        .filter((s) => s != null);

      let filtered = matchedSchedules.filter((s) => {
        if (currentView == "deliveries") {
          return s.is_delivery_location;
        } else {
          return !s.is_delivery_location;
        }
      });

      if (filtered.length > maxListSize) {
        maxListSize = filtered.length;
      }

      console.log(date.weekday());
      if (date.weekday() == 0 || date.weekday() == 6) {
        if (filtered.length == 0) {
          return null;
        }
      }

      index++;

      return (
        <DayContainer>
          <div style={{ position: "absolute" }}>
            <span style={{ fontSize: "2rem", fontWeight: "bold" }}>{`${new Date(
              date.valueOf()
            ).toLocaleString("en-US", {
              weekday: "long",
              month: "short",
              day: "numeric",
            })} - ${this.state.numCycles + 1} / ${Math.ceil(
              filtered.length / listSize
            )}`}</span>
            <div
              width={"100%"}
              style={{ width: "100%", display: "inline-block" }}
            >
              <table width={"100%"} style={{ borderCollapse: "collapse" }}>
                <tbody>{this.getSeheduleRows(filtered, startIndex)}</tbody>
              </table>
            </div>
          </div>
        </DayContainer>
      );
    });

    console.log("Max List Size: " + maxListSize);

    return containers;
  }

  render() {
    return (
      <SecuredView accessLevels={["super_user", "standard_user"]} fullScreen={true}>
        <SideTitle>{titlize(this.state.currentView)}</SideTitle>
        <CalendarContainer>
          {this.getDayContainers(
            this.state.dates,
            this.state.schedules,
            this.state.startIndex,
            this.state.currentView
          )}
          <DayContainer>
            <div
              style={{ position: "absolute", width: "100%", height: "100%" }}
            >
              <MultiMapView
                truckInfoList={getTrucksWithLocations(
                  this.state.trucks
                )}
                chassisList={getChassissWithLocations(this.state.chassis)}
                geoFenceLocations={this.state.locations}
              />
            </div>
          </DayContainer>
        </CalendarContainer>
        <SideTitle>{titlize(this.state.currentView)}</SideTitle>
      </SecuredView>
    );
  }
}

export default MoveOverview;
