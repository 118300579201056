import moment from 'moment';
import React, { useState } from 'react';
import { getChassisReport } from '../../api/chassis';
import { LargeModal, ModalHeader } from '../Modals';
import styled from 'styled-components';
import { TD, TH, TR } from '../styled/Tables';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack, TextField } from '@mui/material';
import { Button } from '@material-ui/core';
import { Download } from '@mui/icons-material';
import { toPDF } from '../../util/functions';

export default (props) => {

    const ChassisReportHeader = styled.div`
    display: inline-block;
    width: 98%;
    padding: 5%;
    `

    const ChassisReportInfo = styled.div`
        float: left;
        width: 30%;
        padding: 1%;
    `
    const ChassisReportBold = styled.span`
        width: 98%;
        display: inline-block;
        font-weight: bold;
        font-size: 1.5rem;
    `

    const ChassisReportNormal = styled.span`
        width: 98%;
        display: inline-block;
        font-size: 1.5rem;
    `

    const ChassisReportLargeCentered = styled.span`
        width: 98%;
        display: inline-block;
        font-size: 2rem;
        text-align: center;
    `

    const ChassisReportNormalCentered = styled.span`
        width: 98%;
        text-align: center;
        display: inline-block;
        font-size: 1.5rem;
    `

    const ChassisReportBar = styled.span`
        width: 98%;
        display: inline-block;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
    `

    const ChassisReportScheduleTable = styled.table`
        width: 95%;
        marginL 5%;
        border: 1px solid black;
    `
    const ChassisReportScheduleTD = styled.td`
        border: 1px solid black;
        padding: 1%;
        width: 25%;
    `

    const [startDate, setStartDate] = useState(moment());
    const [loads, setLoads] = useState([]);

    React.useEffect(() => {
        if (props.chassis) {
            getChassisReport(props.chassis.id, startDate, (result) => {
                setLoads(result);
            });
        }
    }, [props.visible, props.chassis])

    const reload = (date) => {
        getChassisReport(props.chassis.id, date, (result) => {
            setLoads(result);
        });
    }

    const getRows = (loads) => {
        return loads.sort((a,b) => a.load_number - b.load_number).map((load) => {
            return <TR>
                <TD>#{load.load_number}</TD>
                <TD>{new Date(load.loaded_on).toDateString()} {load.alt_start}</TD>
                <TD>{new Date(load.unloaded_on).toDateString()} {load.alt_end}</TD>
                <TD>{load.num_days} day(s)</TD>
            </TR>
        });
    }

    return <LargeModal visible={props.visible} closeCallback={props.onClose}>
        <ModalHeader><span style={{float: 'left'}}>
            Chassis Report: {props.chassis ? props.chassis.number : ""}
            </span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DatePicker
                    views={['year', 'month']}
                    label="View Month"
                    inputFormat="MM/YYYY"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e);
                        reload(e);
                      }}
                    renderInput={(params) => <TextField {...params} sx={{
                        svg: { color: '#fff' },
                        input: { color: '#fff' },
                        marginLeft: "5%",
                        float: 'left',
                        width: "30%",
                        color: 'white',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'white',
                            },
                            '&:hover fieldset': {
                              borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'white',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white',
                            '&.Mui-focused': {
                                color: 'white',
                            },
                        }
                      }} />}
                    />
                </Stack>
            </LocalizationProvider>
            <Button style={{color: "white", float: "left"}} variant="outlined" startIcon={<Download />} onClick={() => toPDF(`chassis-report`, `${props.chassis.number}-chassis-report-${startDate.format('M-YYYY')}`)}>Download Report</Button>
        </ModalHeader>
        <div id="chassis-report" style={{width: "100%", padding: "5%"}}>
            <ChassisReportHeader>
                <ChassisReportInfo>
                    <ChassisReportBold>
                        Chassis Usage Report
                    </ChassisReportBold>
                    <ChassisReportBold>
                        {`Chassis: ${props.chassis ? props.chassis.number : "---"}`}
                    </ChassisReportBold>
                    <ChassisReportBold>
                        {`Plate: ${props.chassis ? props.chassis.plate : "---"}`}
                    </ChassisReportBold>
                    <ChassisReportBold>
                        {`VIN: ${props.chassis ? props.chassis.vin : "---"}`}
                    </ChassisReportBold>
                </ChassisReportInfo>
                <ChassisReportInfo>
                    
                </ChassisReportInfo>
                <ChassisReportInfo>
                    <ChassisReportBold>
                        {startDate.format('MMMM, YYYY')}
                    </ChassisReportBold>
                </ChassisReportInfo>
            </ChassisReportHeader>
        <ChassisReportScheduleTable style={{ width: "100%"}}>
            <TR style={{color: 'white', background: 'black'}}>
                <TH>
                    Load #
                </TH>
                <TH>
                    Loaded From
                </TH>
                <TH>
                    Loaded To
                </TH>
                <TH>
                    Days of Use
                </TH>
            </TR>
            {getRows(loads)}
        </ChassisReportScheduleTable>
        </div>
    </LargeModal>
}